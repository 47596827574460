import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducers';
import ActionType from './constants/ActionType';
import { fetchDetails, setDetails } from './actions/AdminUserAction';
import { composeWithDevTools } from 'redux-devtools-extension';

const exampleInitialState = {
  userId: '',
  userEmail: '',
  companyId: '',
  locationIds: {},
  logo: '',
  permissions: {},
  settings: {},
  role: '',
  profile: null,
  companyStatus: false,
  input: {
    invoice: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    year: '',
    make: '',
    model: '',
    color: '',
    serviceType: '',
    problemType: '',
    willAnyoneBeWithTheVehicle: '',
    doYouHaveKeysForVehicle: '',
    regularGasOrDiesel: '',
    neutral: '',
    doAllWheelsTurn: '',
    doesFrontWheelsTurn: '',
    doesBackWheelsTurn: '',
    pickUpLocation: '',
    pickUpNotes: '',
    startAddress: '',
    endAddress: '',
    email: '',
    amount: '',
    basePrice: '',
    notes: '',
    sendTo: 'phone',
    zipForCalc: '',
    system: '',

    // signup_response:{status:''},
    error: '',
    success: '',
    distanceDetails: {},

    open: false,
    userAccount: '',

    touched: {
      firstName: false,
      lastName: false,
      phoneNumber: false,
      year: false,
      make: false,
      model: false,
      color: false,
      serviceType: false,
      problemType: false,
      willAnyoneBeWithTheVehicle: false,
      doYouHaveKeysForVehicle: false,
      regularGasOrDiesel: false,
      neutral: false,
      doAllWheelsTurn: false,
      doesFrontWheelsTurn: false,
      doesBackWheelsTurn: false,
      pickUpLocation: false,
      email: false,
      amount: false,
    },
  },
};

const customMiddleWare = (store) => (next) => async (action) => {
  if ([ActionType.USER_START, ActionType.INVOICE_START].includes(action.type)) {
    let userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      const val = await setDetails(userData);
      store.dispatch(val);
    } else {
      console.log('Some else Logic Here');
    }
  }
  return next(action);
};

export function initializeStore(_initialState = exampleInitialState) {
  return createStore(reducer, composeWithDevTools(applyMiddleware(customMiddleWare, thunkMiddleware)));
}
