import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { config } from '../../constants';
// import classes from './Discovery.css';
import './Discovery.css';
import { Button, Grid, TextField } from '@material-ui/core';
import Joi from 'joi-browser';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';

export default function Discovery() {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setisLoading] = useState(false);

  const schema = Joi.object().keys({
    fname: Joi.string().min(3).required().label('First Name'),
    lname: Joi.string().min(3).required().label('Last Name'),
    compname: Joi.string().min(3).required().label('Company Name'),
    email: Joi.string().min(3).required().email().label('Email'),
    info: Joi.string().min(3).required().label('How did you hear about us'),
    details: Joi.string().min(3).required().label('Details'),
  });
  const body = {
    email: inputs.email,
    firstName: inputs.fname,
    lastName: inputs.lname,
    howYouKnow: inputs.info,
    companyName: inputs.compname,
    details: inputs.details,
  };
  const validateForm = (event) => {
    event.preventDefault();

    const result = Joi.validate(inputs, schema, { abortEarly: false });

    const { error } = result;
    if (error == null) {
      setErrors({});
      setisLoading(true);
      axios
        .post(config.BASEURL + 'addDiscovery', body)
        .then((res) => {
          if (res.status == 200) {
            Swal.fire({
              title: '<h5 style="padding:20px">Thank You For contacting Us!</h5> <p> We will contact you shortly. </p>',
              showConfirmButton: true,
              confirmButtonText: 'OK',
              icon: 'success',
            }).then((result) => {
              if (result.isConfirmed) {
                setInputs({});
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setisLoading(false));
    }
    if (!error) {
      return null;
    } else {
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
      }

      setErrors(errorData);
      return errorData;
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <>
      <div className="container">
        <header className={'headerPanel'}>
          <div className="logo">
            <img src={config.APPURL + '/assets/images/home-logo.png'} />
          </div>
          <div className={'topmenu'}>
            <ul>
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>
                <Link to={'/Login'}>Login</Link>
              </li>
              <li>
                <Link to={'/discovery'}>Discover</Link>
              </li>
            </ul>
          </div>
        </header>
      </div>

      <div className={'bannerWrapper'}>
        <div className={'videobox'}>
          <video id="vid" loop autoPlay muted controls>
            <source src={config.APPURL + '/assets/video/1025610638-hd.mp4'} type="video/mp4" />
          </video>
        </div>
        <div className={'container'}>
          <div className={'bannerContent'}>
            <h1 className={'bannerHeading'}>
              CUSTOMIZE <br />
              YOUR <br />
              CASH FLOW
              <br />
            </h1>
          </div>
        </div>

        <div className={'yt-video'}>
          <iframe
            src="https://player.vimeo.com/video/736289942?h=9fa9e35647"
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className={'container center contact-form-container'}>
          <Grid lg={8} md={8} sm={12} className="contact-form">
            <LoadingOverlay active={isLoading} spinner text="Please wait ..." className="m-0">
              <h2 className="contact-form-header">CONTACT US</h2>
              <p className="contact-form-subheader">
                Call us at 877-972-0700 or fill out the form and we'll get back to you shortly
              </p>
              <div className="form"></div>
              <form>
                <div className="form-input-container">
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="First Name"
                        name="fname"
                        value={inputs.fname || ''}
                        onChange={handleChange}
                      />
                      {errors.fname && <div className="alert-danger"> {errors.fname} </div>}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Last Name"
                        name="lname"
                        value={inputs.lname || ''}
                        onChange={handleChange}
                      />
                      {errors.lname && <div className="alert alert-danger"> {errors.lname} </div>}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Company Name"
                        name="compname"
                        value={inputs.compname || ''}
                        onChange={handleChange}
                      />
                      {errors.compname && <div className="alert alert-danger"> {errors.compname} </div>}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Email"
                        name="email"
                        value={inputs.email || ''}
                        onChange={handleChange}
                      />
                      {errors.email && <div className="alert alert-danger"> {errors.email} </div>}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="How did you hear about Titanium Flex?"
                        name="info"
                        minRows={3}
                        multiline
                        value={inputs.info || ''}
                        onChange={handleChange}
                      />
                      {errors.info && <div className="alert alert-danger"> {errors.info} </div>}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                      <TextField
                        fullWidth
                        minRows={3}
                        multiline
                        id="standard-basic"
                        label="Details"
                        name="details"
                        value={inputs.details || ''}
                        onChange={handleChange}
                      />
                      {errors.details && <div className="alert alert-danger"> {errors.details} </div>}
                    </Grid>
                  </Grid>
                </div>
                <Grid item sm={12} className="center">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#19a2d2', color: 'white' }}
                    onClick={validateForm}
                  >
                    SEND MESSAGE
                  </Button>
                </Grid>
              </form>
            </LoadingOverlay>
          </Grid>
        </div>
      </div>
      <div className={'footerWrapper'}>
        <a href="#">Copyright by Titanium Payments 2023</a>
      </div>
    </>
  );
}
