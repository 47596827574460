import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { Alert, Autocomplete } from '@mui/material';

const useStyles = makeStyles({
  root: {
    height: 200,
    overflowX: 'hidden',
    textAlign: 'center',
  },
  table: {
    minWidth: 200,
  },
  terminal: {
    paddingTop: 50,
    textAlign: 'center',
  },
  selectTerminal: {
    alignItems: 'center',
    borderColor: '#7f7f7f',
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
  },
});

const PaxTerminals = (props) => {
  const classes = useStyles();
  const { paxTerminalsList, open, handleClose, selectPaxTerminal, handleSubmit, paxSelectError } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      {paxSelectError ? <Alert severity="error">Please select a terminal</Alert> : ''}
      <DialogTitle>Pax Terminals</DialogTitle>
      <DialogContent className={classes.root}>
        <DialogContentText>Choose a terminal.</DialogContentText>
        <Divider />
        <Grid container spacing={3} className={classes.terminal}>
          <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
              disablePortal
              id="paxTerminal"
              options={paxTerminalsList}
              sx={{ width: 400 }}
              getOptionLabel={(option) => `${option.paxTerminalSerial}  ${option.paxterminalName}`}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.paxTerminalSerial} ({option.paxterminalName})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a terminal"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onChange={(e, v) => selectPaxTerminal(e, v)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button name="PAX_Terminal" onClick={(e) => handleSubmit(e, false)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PaxTerminals.propTypes = {
  paxTerminalsList: PropTypes.any,
  open: PropTypes.any,
  paxSelectError: PropTypes.any,
  handleClose: PropTypes.func,
  handleEnterKeyPress: PropTypes.func,
  mapToFormatPaxDevice: PropTypes.func,
  handleSubmit: PropTypes.func,
  selectPaxTerminal: PropTypes.func,
};

export default PaxTerminals;
