import React from 'react';
import PropTypes from 'prop-types';
import { Textfield } from '../Textfield';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
      '& fieldset': {
        border: 'none',
      },
    },
  },
});
function TextfieldV2(props) {
  const classes = useStyles();
  return <Textfield {...props} classes={{ root: classes.root }} />;
}
TextfieldV2.propTypes = {
  id: PropTypes.any,
  idx: PropTypes.number,
  name: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  variant: PropTypes.string,
  rowsMax: PropTypes.string,
  row: PropTypes.string,
  rows: PropTypes.string,
  InputProps: PropTypes.any,
  labels: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  width: PropTypes.bool,
  focus: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  style: PropTypes.any,
};
TextfieldV2.defaultProps = {
  className: '',
  disabled: false,
  type: 'text',
  value: '',
  error: false,
};
export default TextfieldV2;
