import { ActionType } from './../constants';

const initialState = {
  signin: [],
  loading: false,
  error: null,
  msg: null,
};

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.COMPANY_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionType.COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companyInfo: action.payload.companyData,
      };

    case ActionType.COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        companyInfo: action.payload.error,
      };

    default:
      return state;
  }
};

export { CompanyReducer };
