import React, { useMemo, memo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import classes from './TextFlex.module.scss';
import PropTypes from 'prop-types';

const TextFlex = ({ handleChangeCheckbox, textFlexPermissions, customFields, customFieldsEnabled }) => {
  const customField = useMemo(() => {
    return customFields[0];
  }, [customFields]);

  const { textFlex, customId, customerName, phoneNumber, amount } = textFlexPermissions;

  return (
    <Accordion
      style={{
        border: 0,
        boxShadow: 'none',
        marginLeft: 10,
        margin: 0,
        padding: 0,
      }}
      className={classes.accordionMui}
    >
      <AccordionSummary expandIcon={<ExpandMore />} style={{ padding: 0, margin: 0, maxHeight: 40, border: 0 }}>
        <FormControlLabel
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          aria-label="Acknowledge"
          style={{ marginLeft: 0 }}
          control={
            <Grid style={{ marginLeft: 10 }} item xs={12} sm={6}>
              <Checkbox
                id="textFlex"
                onChange={(e) => {
                  handleChangeCheckbox(e);
                }}
                value={textFlex}
                checked={textFlex}
                color="primary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />
            </Grid>
          }
          label="Text Flex"
        />
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0, margin: 0, boxShadow: 'none', border: 0, marginLeft: 10 }}>
        <Grid>
          <Typography color="textSecondary">Fields:</Typography>
          {customField && customField.value && (
            <Grid style={{ marginLeft: 10 }} item xs={12} sm={12}>
              <label>
                <Checkbox
                  disabled={!textFlex || !customFieldsEnabled || !customField?.enabled || customField?.autoGenerate}
                  value={customId}
                  checked={customId}
                  id="customId"
                  color="primary"
                  onChange={(e) => {
                    handleChangeCheckbox(e);
                  }}
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                  }}
                />
                <span>{customField?.value}</span>
              </label>
            </Grid>
          )}
          <Grid style={{ marginLeft: 10 }} item xs={12} sm={12}>
            <label>
              <Checkbox
                disabled={!textFlex}
                value={customerName}
                checked={customerName}
                color="primary"
                id="customerName"
                onChange={(e) => {
                  handleChangeCheckbox(e);
                }}
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />
              <span>Customer Name</span>
            </label>
          </Grid>
          <Grid style={{ marginLeft: 10 }} item xs={12} sm={12}>
            <label>
              <Checkbox
                checked={phoneNumber}
                value={phoneNumber}
                disabled={!textFlex}
                id="phoneNumber"
                color="primary"
                onChange={(e) => {
                  handleChangeCheckbox(e);
                }}
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />
              <span>Phone Number</span>
            </label>
          </Grid>
          <Grid style={{ marginLeft: 10 }} item xs={12} sm={12}>
            <label>
              <Checkbox
                onChange={(e) => {
                  handleChangeCheckbox(e);
                }}
                value={amount}
                id="amount"
                disabled={!textFlex}
                checked={amount}
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
                color="primary"
              />
              <span>Amount</span>
            </label>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(TextFlex);

TextFlex.propTypes = {
  handleChangeCheckbox: PropTypes.func,
  textFlexPermissions: PropTypes.object,
  customFieldsEnabled: PropTypes.bool,
  customFields: PropTypes.array,
};
