import React from 'react';
import PropTypes from 'prop-types';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const formatSingleDigit = (val) => (val >= 0 && val <= 9 ? `0${val}` : val);

export const CustomTimer = ({ expiresIn }) => (
  <CountdownCircleTimer
    onComplete={() => window.location.reload()}
    colors={[['#FBFA4E']]}
    isPlaying
    size={60}
    strokeWidth={6}
    durationSeconds={5 * 60}
    initialRemainingTime={expiresIn}
    renderTime={(rt) => (
      <span style={{ fontsize: '0.65rem', fontWeight: 'bold', padding: '1rem' }}>
        {`${parseInt(rt / 60)}:${formatSingleDigit(parseInt(rt % 60))}`}
      </span>
    )}
  />
);

CustomTimer.propTypes = {
  expiresIn: PropTypes.number,
};
