const isNumber = (val) => {
  if (/^[0-9]*$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};

const isTwoDecimalPlace = (val) => {
  if (/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};
const isTwoDecimalPlaceSvc = (val) => {
  if (/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};
const checkEmail = (val) => {
  if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(val)) {
    return true;
  } else {
    return false;
  }
};
const checkSecretKey = (val) => {
  /* eslint-disable */
  if (/^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/.test(val)) {
    return true;
  } else {
    return false;
  }
};

const isPhone = (val) => {
  if (/^[\+]?[(]?[\d]{3}[)]?[-\s\.]?[\d]{3}[-\s\.]?[\d]{4}$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};
const isZip = (val) => {
  if (/^\d{5}$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};
const isPayerName = (val) => {
  if (/[a-zA-Z]*[^#&<>\"~;$^%{}?]$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};
const isEmpty = (val) => {
  if (/^\s*$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};
const isYearRange = (val) => {
  if (/^(19[5-9]\d|20[0-4]\d|2050)$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};
const isDescription = (val) => {
  if (/^.{0,100}$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};
const checkName = (val) => {
  if (/^.{0,76}$/g.test(val)) {
    return true;
  } else {
    return false;
  }
};

const isPassword = (val) => {
  if (/^.{0,25}$/g.test(val)) return true;
  return false;
};
const isAddress = (val) => {
  if (/^.{1,50}$/g.test(val)) return true;
  return false;
};
const makePhoneNumberForDB = (val) => {
  if (isPhone(val) && (val.includes('-') || val.includes('.') || val.includes(' ')))
    return val.replace(/[()._-\s]/g, '');
  return val;
};
const isNotNullOrUndefined = (value) => {
  return value !== null && value !== undefined && value !== 'null' && value !== 'undefined';
};
const isAddressInputValid = (id, value) => {
  const isValidDigit = /^\d{0,5}$/;
  const containsInvalidCharacters = /[^ \w-.,'#&']/g;

  if (id === 'zip' && !isValidDigit.test(value)) {
    return false;
  }

  if (containsInvalidCharacters.test(value)) {
    return false;
  }
  return true;
};

export {
  checkName,
  isDescription,
  isPhone,
  isZip,
  checkEmail,
  checkSecretKey,
  isNumber,
  isPayerName,
  isTwoDecimalPlace,
  isTwoDecimalPlaceSvc,
  isYearRange,
  isPassword,
  isAddress,
  isEmpty,
  makePhoneNumberForDB,
  isNotNullOrUndefined,
  isAddressInputValid,
};
