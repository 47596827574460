import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import LoadingOverlay from 'react-loading-overlay';
import { Textfield } from './../../components/Textfield';
import { editProduct, productDetailsId } from '../../actions/ProductAction';
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import validator from '../../helpers/checkValidations';
import { useTheme } from '@material-ui/core/styles';
import classesCus from './Products.module.scss';
import { DialogBox } from './../../components/DialogBox/DialogBox';
import TextfieldLable from './TextfieldLable';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import { initialState, useStyles } from './ProductsInitialState';
import PropTypes from 'prop-types';
import { changeHandler, changeCustomerFieldsHandler, handleRemove, setUser, handleLabels } from './CommonFunctions';
class EditProducts extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.getProductDetails();
    this.setState({ isLoading: true });
    const user = setUser();
    this.setState(user);
  }

  handleChange(event, _t) {
    const { id, value, misMatchError, error, validationMarkup } = changeHandler(event);
    if (!validationMarkup) {
      return false;
    }
    this.setState({
      [id]: value,
      error,
      misMatchError,
      isSubmit: false,
      successMessages: false,
    });
  }

  handleChangeCustom(event, _t, i) {
    const { values, error } = changeCustomerFieldsHandler(this.state, event, i);
    console.log(values, 'valies');
    console.log(event.target.value);
    this.setState({ customFields: values, error });
  }

  handleRemove(i) {
    const values = handleRemove(this.state, i);
    this.setState({ customFields: values });
  }

  handleLabels = (data) => {
    const values = handleLabels(data, this.state);
    this.setState({ customFields: values });
  };

  handleSubmit(_e, _t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });

    if (state.notes === '') {
      delete state.notes;
    }
    //check cutom fields values
    const values = [...this.state.customFields];
    values.forEach((key, _val) => {
      return key['value'] == null || (typeof key['value'] === 'string' && key['value'].trim() === '')
        ? ((error[key.label + 'Error'] = true), (errorFlag = true))
        : (error[key.label + 'Error'] = false);
    });
    Object.keys(this.state).forEach((key) => {
      if (
        validator(key, this.state[key]) ||
        this.state[key] === '' ||
        (typeof this.state[key] === 'string' && this.state[key].trim() === '')
      ) {
        error[key + 'Error'] = true;
        errorFlag = true;
      } else {
        error[key + 'Error'] = false;
      }
    });

    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      console.log(error);
      return false;
    } else {
      if (window.confirm('Are you sure you want to update the product ?')) {
        this.setState({ isLoading: true });
        this.props.editProduct(state).then((result) => {
          if (result !== undefined && result.type === 'PRODUCT_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true }, () => {
              setTimeout(function () {
                // redirect
              }, 2000);
            });
          }
          if (result !== undefined && result.type === 'PRODUCT_ERROR') {
            this.setState({
              misMatchError: this.props.info.productInfo.data.message,
              isLoading: false,
              successMessages,
            });
          }
        });
      }
    }
  }

  getProductDetails() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });

    this.props.productDetailsId(params).then((res) => {
      let dataProduct = res.payload.productData.data;
      if (dataProduct.success) {
        this.setState({ ...dataProduct.response, isLoading: false });
      }
    });
  }

  render() {
    const { classes, t } = this.props;
    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
              {this.state.successMessages ? (
                <SuccessMessage successes={[this.props.info.productInfo.data.message]} />
              ) : (
                ''
              )}
              <h3>Edit Product</h3>
              <form className={classes.form} noValidate ref={this.myRef}>
                <DialogBox
                  open={this.state.isOpen}
                  handleClose={() => this.setState({ isOpen: false })}
                  message={
                    <TextfieldLable
                      handleClose={() => this.setState({ isOpen: false })}
                      handleLabels={this.handleLabels}
                    />
                  }
                />
                <Grid container item xs={12} spacing={1}>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.nameError}
                      id="name"
                      type="text"
                      labels={t('Name')}
                      value={this.state.name}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>

                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.amountError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      id="amount"
                      type="text"
                      labels={t('Amount')}
                      value={this.state.amount}
                      width={true}
                      focus={true}
                      placeholder="0.00"
                      onChange={(e) => this.handleChange(e, t)}
                      onFocus={({ target }) => {
                        this.setState({
                          amount: String(target.value),
                        });
                      }}
                      onBlur={({ target }) => {
                        this.setState({
                          amount: Number(target.value).toFixed(2),
                          error: { ...this.state.error, amountError: false },
                        });
                      }}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.descriptionError}
                      id="description"
                      type="text"
                      labels={t('Description')}
                      value={this.state.description}
                      width={true}
                      multiline={true}
                      rowsMax={2}
                      rows={2}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      error={this.state.error.notesError}
                      id="notes"
                      type="text"
                      labels={t('Notes')}
                      value={this.state.notes}
                      width={true}
                      multiline={true}
                      rowsMax={2}
                      rows={2}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>
                  <Grid container item sm={12} xs={12} justify="flex-end">
                    <Buttons
                      className={classesCus.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t('Add More')}
                      onClick={() => this.setState({ isOpen: true })}
                    />
                  </Grid>
                  {this.state.customFields.map((field, idx) => {
                    return (
                      <Grid item sm={6} xs={6} key={idx}>
                        <div key={`${field}-${idx}`}>
                          <CloseIcon onClick={() => this.handleRemove(idx)} />
                          <Textfield
                            required={true}
                            error={this.state.error[field.label + 'Error']}
                            id={field.label}
                            type="text"
                            labels={field.label}
                            value={field.value || ''}
                            width={true}
                            onChange={(e) => this.handleChangeCustom(e, t, idx)}
                            focus={true}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} sm={12} className="mt-2">
                  <Buttons
                    id="signUp"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.handleSubmit(e, t)}
                    text={t('Save')}
                  />
                  <Buttons
                    className={classesCus.ml_2}
                    variant="contained"
                    color="secondary"
                    text={t('Back')}
                    onClick={this.props.history.goBack}
                  />
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return <EditProducts theme={theme} classes={classes} {...props} />;
};

function mapStateToProps(state) {
  return {
    info: state.ProductReducer,
  };
}

export default connect(mapStateToProps, { productDetailsId, editProduct })(
  withTranslation()(withRouter(ApplyingStyles))
);

EditProducts.propTypes = {
  editProduct: PropTypes.func,
  info: PropTypes.any,
  classes: PropTypes.any,
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  productDetailsId: PropTypes.any,
};
