import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classes from './Payment.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { UserDetailById } from '../../actions/AdminUserAction';
import { getInvoiceNumber, InvoiceDetailByIdForPay, DownloadInvoice } from '../../actions/PaymentAction';
import { countryDataList } from '../../actions/CompanyAction';
// import { customerList } from '../../actions/CustomerAction'
import { payByLink } from '../../actions/RequestFormAction';
import { getCsrfToken } from '../../actions/PaymentAction';
import { Buttons } from './../../components/Buttons';
import { Textfield } from './../../components/Textfield';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import config from './../../constants/config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaskedInput from 'react-maskedinput';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import validator from '../../helpers/checkValidations';
import LoadingOverlay from 'react-loading-overlay';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
// import DateFnsUtils from '@date-io/date-fns';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AsyncSelect from 'react-select/async';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
class PaymentNy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFindBill: false,
      cardPanelShow: true,
      americanExpress: false,
      isLoading: false,
      isOpen: false,
      amount: '',
      payerName: '',
      total: 0,
      achDiscount: 0,
      svcFee: 0,
      tax: 0,
      taxType: '%',
      svcType: '%',
      invoice: '',
      notes: '',
      createdBy: '',
      companyId: '',
      zip: '',
      discountType: 1,
      discount: 0,
      isSubmit: false,
      addNewCard: false,
      isClose: false,
      dueDate: new Date(),
      invoiceDate: new Date(),
      misMatchError: [],
      CoustmerData: [],
      CardData: [],
      InvoicePendingData: [],
      successMessages: false,
      discountErr: false,
      updateCard: false,
      tabValue: 0,
      indexValue: 0,
      paytraceCredentials: {},
      userDetails: JSON.parse(localStorage.getItem('user')),
      customerId: { _id: '', firstName: '', lastName: '' },
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      ach: {
        isOpen: false,
        accountName: '',
        accountNumber: '',
        routingNumber: '',
      },
      errorAch: {
        accountNameError: false,
        accountNumberError: false,
        routingNumberError: false,
      },
      card: {
        isOpen: false,
        cardHolderName: '',
        cardNumber: '_______________',
        cardExpiryDate: '__/____',
        cardCsc: '___',
        customerToken: false,
        customerTokenUpdate: false,
      },
      error: {
        amountError: false,
        payerNameError: false,
      },
      errorCard: {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false,
      },
    };
    this.paymentRef = React.createRef();
    this.cardRef = React.createRef();
    this.msgRef = React.createRef();
  }

  componentDidMount() {
    this.getInvoiceData();
    this.getCsrfTokens();
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    });
  }

  getInvoiceData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.InvoiceDetailByIdForPay(params).then((res) => {
      if (res.payload.paymentData.data.success) {
        this.setState({ ...res.payload.paymentData.data.response, isLoading: false });
      }
      if (res.payload.paymentData.data.statusCode === 401) {
        this.setState({ invalidMsg: res.payload.paymentData.data.message, isLoading: false });
      }
      if (this.state.locationId) {
        const paytraceCredentials = {
          paytraceKey: this.state.locationId.paytraceKey,
          userName: this.state.locationId.paytraceUserName,
          password: this.state.locationId.paytracePassword,
        };
        this.setState({ paytraceCredentials: paytraceCredentials }, () => {
          window.paytrace.setKeyAjax(this.props.PAYTRACE_AWS_PATH + this.state.paytraceCredentials.paytraceKey);
        });
      }
    });
  }

  handleChange(event, t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;

    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
    if (!this.props.UserDetail.settings.InvoiceTax && id === 'amount') {
      this.setState({ total: parseFloat(value) });
    } else if (id === 'amount' && this.props.UserDetail.settings.InvoiceTax && value !== '0') {
      if (this.props.UserDetail.settings.InvoiceSalesTaxFeeType === '$') {
        this.setState(
          {
            tax: this.props.UserDetail.settings.InvoiceSalesTaxFee,
            taxType: this.props.UserDetail.settings.InvoiceSalesTaxFeeType,
          },
          () => {
            this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
          }
        );
      } else {
        this.setState(
          {
            tax: (this.props.UserDetail.settings.InvoiceSalesTaxFee / 100) * value,
            taxType: this.props.UserDetail.settings.InvoiceSalesTaxFeeType,
          },
          () => {
            this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
          }
        );
      }

      if (this.props.UserDetail.settings.InvoiceDiscount) {
        if (this.props.UserDetail.settings.InvoiceSVCFeeType === '$') {
          this.setState({ svcType: this.props.UserDetail.settings.InvoiceSVCFeeType }, () => {
            this.setState({
              total: parseFloat(this.state.amount) + this.state.tax + this.props.UserDetail.settings.InvoiceSVCFee,
            });
          });
        } else {
          this.setState({ svcFee: (this.props.UserDetail.settings.InvoiceSVCFee / 100) * value }, () => {
            this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee });
          });
        }
      }
    }
    if (id === 'payerName' && value !== '' && typeof value === 'string') {
      this.setState({ payerName: value.charAt(0).toUpperCase() + value.slice(1) });
    }
  }

  fetchSettingsData() {
    this.props.UserDetailById({ id: this.state.userDetails._id }).then((res) => {
      if (res.type === 'USER_SUCCESS' && res.payload.userData.status === 200) {
        this.setState({ ...res.payload.userData.data.settings, isLoading: false });
      }
    });
  }

  setClientValueInvoice(val) {
    if (val !== null) {
      this.setState({ invoice: val.value });
      const data = this.state.InvoicePendingData.filter((item) => item.invoice === val.value);
      if (data[0].amount) {
        this.setState({ amount: data[0].amount }, () => {
          this.setState({ customerId: { ...data[0].customerId } }, () => {
            let val = { value: this.state.customerId._id };
            this.setState({ customerId: { firstName: data[0].customerId.firstName } });
            this.setClientValue(val);
          });
          if (!this.props.UserDetail.settings.InvoiceTax) {
            this.setState({ total: parseFloat(this.state.amount) });
          } else if (this.props.UserDetail.settings.InvoiceTax && this.state.amount !== '0') {
            if (this.props.UserDetail.settings.InvoiceSalesTaxFeeType === '$') {
              this.setState(
                {
                  tax: this.props.UserDetail.settings.InvoiceSalesTaxFee,
                  taxType: this.props.UserDetail.settings.InvoiceSalesTaxFeeType,
                },
                () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
                }
              );
            } else {
              this.setState(
                {
                  tax: (this.props.UserDetail.settings.InvoiceSalesTaxFee / 100) * this.state.amount,
                  taxType: this.props.UserDetail.settings.InvoiceSalesTaxFeeType,
                },
                () => {
                  this.setState({ total: parseFloat(this.state.amount) + this.state.tax });
                }
              );
            }

            if (this.props.UserDetail.settings.InvoiceDiscount) {
              if (this.props.UserDetail.settings.InvoiceSVCFeeType === '$') {
                this.setState({ svcType: this.props.UserDetail.settings.InvoiceSVCFeeType }, () => {
                  this.setState({
                    total:
                      parseFloat(this.state.amount) + this.state.tax + this.props.UserDetail.settings.InvoiceSVCFee,
                  });
                });
              } else {
                this.setState(
                  { svcFee: (this.props.UserDetail.settings.InvoiceSVCFee / 100) * this.state.amount },
                  () => {
                    this.setState({ total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee });
                  }
                );
              }
            }
          }
        });
      }
    } else {
      this.setState({ invoice: '' }, () => {});
    }
  }

  setClientValue(val) {
    document.getElementById('cardNumber').value = '___________';
    if (val !== null) {
      let misMatchError = [];
      const errCard = {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false,
      };
      const cardDataInital = {
        isOpen: false,
        cardCsc: '___',
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false,
      };

      //For set mask number
      const data = this.state.CoustmerData.filter((item) => item._id === val.value);
      this.setState({
        ach: { ...this.state.ach, isOpen: false },
        card: { ...cardDataInital },
        errorCard: { ...errCard },
        misMatchError,
        error: { ...this.state.error },
        customerId: {
          ...this.state.customerId,
          _id: val.value,
          firstName: data[0].firstName,
          lastName: data[0].lastName,
        },
        cardPanelShow: true,
        addNewCard: false,
      });

      if (data[0].customer_id) {
        this.setState({
          mask_number: data[0].masked_card_number,
          cardPanelShow: true,
          name: data[0].firstName + ' ' + data[0].lastName,
        });
      } else {
        this.setState({ mask_number: '', name: '', updateCard: false });
      }
    } else {
      let misMatchError = [];
      const errCard = {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false,
      };
      document.getElementById('cardNumber').value = '___________';
      const cardDataInital = {
        cardCsc: '___',
        isOpen: false,
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false,
      };
      this.setState(
        {
          ach: { ...this.state.ach, isOpen: false },
          card: { ...cardDataInital },
          misMatchError,
          errorCard: { ...errCard },
          customerId: null,
          cardPanelShow: false,
          addNewCard: false,
        },
        () => {}
      );
    }
  }
  handleChangeCard(event, t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;

    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState(
      {
        card: { ...this.state.card, [id]: value },
        errorCard: { ...this.state.errorCard, [id]: false },
        error,
        misMatchError,
        isSubmit: false,
      },
      () => {
        let firstNumber = this.state.card.cardNumber;
        this.setState({ cardType: this.getCardType(firstNumber.charAt(0)) });
        if (firstNumber[0] === '3') {
          this.setState({ americanExpress: true });
        } else {
          this.setState({ americanExpress: false });
        }
      }
    );
  }
  handleChangeAch(event, t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState(
      {
        ach: { ...this.state.ach, [id]: value },
        errorCard: { ...this.state.errorCard, [id]: false },
        errorAch: { ...this.state.errorAch, [id + 'Error']: false },
        error,
        misMatchError,
        isSubmit: false,
      },
      () => {}
    );
  }
  handleSubmit(e, t) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    // let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });
    if (this.state.customerId === null) {
      return false;
    }
    if (this.state.notes === '') {
      delete this.state.notes;
    }
    if (this.state.timestamp_PAID === '') {
      delete this.state.timestamp_PAID;
    }
    if (this.state.InvoiceSalesTaxFee === '' || this.state.InvoiceSalesTaxFee === 0) {
      delete this.state.InvoiceSalesTaxFee;
    }

    if (this.checkCardValues(this.state)) {
      if (this.state.tabValue === 1) {
        delete this.state.payerName;
        delete this.state.zip;
        delete this.state.createdBy;
      }
      delete this.state.endAddress;
      delete this.state.problemType;
      if (
        this.state.DiscountFeeName === '' ||
        typeof this.state.DiscountFeeName === 'undefined' ||
        this.state.DiscountFeeName === 0
      ) {
        delete this.state.DiscountFeeName;
      }
      if (
        this.state.InvoiceSVCFee === '' ||
        typeof this.state.InvoiceSVCFee === 'undefined' ||
        this.state.InvoiceSVCFee === 0
      ) {
        delete this.state.InvoiceSVCFee;
      }
      Object.keys(this.state).map((key, value) => {
        if (validator(key, this.state[key])) {
          return validator(key, this.state[key])
            ? ((error[key + 'Error'] = true), (errorFlag = true))
            : (error[key + 'Error'] = false);
        } else {
          return this.state[key] === '' || (typeof this.state[key] === 'string' && this.state[key].trim() === '')
            ? ((error[key + 'Error'] = true), (errorFlag = true))
            : '';
        }
      });

      if (errorFlag) {
        console.log('error', error);
        this.setState({ misMatchError, logoError, error, successMessages });
        return false;
      } else {
        window.paytrace.submitEncrypted('#DemoForm');
        this.setState(
          {
            card: { ...this.state.card, cardNumber: '________________', cardCsc: '___' },
            isLoading: true,
            encrypted_number: localStorage.getItem('0'),
            encrypted_csc: localStorage.getItem('1'),
          },
          () => {
            localStorage.removeItem('0');
            localStorage.removeItem('1');

            this.props.payByLink(this.state, this.state.csrf, this.state.paytraceCredentials).then((result) => {
              window.scrollTo(0, 0);

              if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
                window.scrollTo(0, 0);
                let emptyData = { payerName: '', zip: '' };

                this.setState({ isLoading: false, successMessages: true, misMatchError, ...emptyData }, () => {
                  this.setState({ ach: { ...this.state.ach, accountName: '', accountNumber: '', routingNumber: '' } });
                  setTimeout(function () {
                    // window.location.href = config.APPURL + "/Transactions"
                  }, 2500);
                });
              }
              if (result !== undefined && result.type === 'INVOICE_ERROR') {
                if (result.payload.error.data) {
                  let message = result.payload.error.data.message;
                  misMatchError.push(message);
                  if (result.payload.error.data.response.length > 0) {
                    let responseErrors = JSON.parse(result.payload.error.data.response);
                    if (responseErrors.response_code === 1) {
                      for (let key in responseErrors.errors) {
                        let i = 0;
                        misMatchError.push(responseErrors.errors[key][i] + '\n');
                      }
                      // this.setState({ isLoading: false, misMatchError, successMessages });
                    }
                    if (responseErrors.response_code === 1001) {
                      misMatchError.push(responseErrors.status_message);
                    }
                  }
                  this.setState({ isLoading: false, misMatchError, successMessages });
                }

                this.setState({ isLoading: false, misMatchError, successMessages });
              }
            });
          }
        );
      }
    }
  }
  checkCardValues(state) {
    let errorAch = {};
    let errorFlag = false;
    let successMessages = false;
    if (this.state.tabValue === 0) {
      this.setState({ ach: { ...this.state.ach, isOpen: false } });
      if (state.card.cardNumber.indexOf('_') > -1 || state.card.cardNumber === '') {
        this.setState({ errorCard: { ...this.state.errorCard, cardNumber: true } });
        return false;
      } else if (state.card.cardExpiryDate.indexOf('_') > -1 || state.card.cardExpiryDate === '') {
        this.setState({ errorCard: { ...this.state.errorCard, cardExpiryDate: true } });
        return false;
      } else if (state.card.cardCsc.indexOf('_') > -1 || state.card.cardCsc === '') {
        this.setState({ errorCard: { ...this.state.errorCard, cardCsc: true } });
        return false;
      } else {
        return true;
      }
    } else {
      this.setState({ ach: { ...this.state.ach, isOpen: true }, payerName: this.state.ach.accountName }, () => {
        delete this.state.zip;
      });
      Object.keys(state.ach).map((key, value) => {
        if (validator(key, state.ach[key])) {
          return validator(key, state.ach[key])
            ? ((errorAch[key + 'Error'] = true), (errorFlag = true))
            : (errorAch[key + 'Error'] = false);
        } else {
          return state.ach[key] === '' || (typeof state.ach[key] === 'string' && state.ach[key].trim() === '')
            ? ((errorAch[key + 'Error'] = true), (errorFlag = true))
            : '';
        }
      });
      if (errorFlag) {
        this.setState({ errorAch, successMessages });
        return false;
      } else {
        return true;
      }
    }
  }
  handleDateChange = (date, key) => {
    this.setState({ [key]: date });
  };
  backToMaskCard(e, t) {
    //For set mask number
    const errCard = {
      cardNumber: false,
      cardHolderName: false,
      cardExpiryDate: false,
      cardCsc: false,
    };
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____',
    };
    this.setState({ card: { ...cardDataInital }, errorCard: { ...errCard }, cardPanelShow: true, updateCard: false });
    const data = this.state.CoustmerData.filter((item) => item._id === this.state.customerId._id);
    if (data[0].customer_id) {
      this.setState({
        mask_number: data[0].masked_card_number,
        cardPanelShow: true,
        name: data[0].firstName + ' ' + data[0].lastName,
      });
    }
  }

  handleChangeCheckbox(event) {
    this.setState({ card: { ...this.state.card, [event.target.id]: event.target.checked } });
  }
  achPaymentPopUp(event) {
    let misMatchError = [];
    if (this.state.ach.isOpen) {
      this.setState(
        {
          misMatchError,
          card: { ...this.state.card, isOpen: false },
          ach: { ...this.state.ach, isOpen: false },
          errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false },
        },
        () => {}
      );
    } else {
      this.setState({
        misMatchError,
        card: { ...this.state.card, isOpen: true },
        ach: { ...this.state.ach, isOpen: true },
        errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false },
      });
    }
  }
  cardPaymentPopUp(event) {
    let misMatchError = [];
    if (this.state.card.isOpen) {
      this.setState(
        {
          misMatchError,
          card: { ...this.state.card, isOpen: false },
          ach: { ...this.state.ach, isOpen: false },
          errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false },
        },
        () => {}
      );
    } else {
      this.setState({
        misMatchError,
        card: { ...this.state.card, isOpen: true },
        ach: { ...this.state.ach, isOpen: true },
        errorCard: { ...this.state.errorCard, cardNumber: false, cardExpiryDate: false, cardCsc: false },
      });
    }
  }
  getCardType(val) {
    let number = Number(val);
    switch (number) {
      case 4:
        return 'Visa';
      case 5:
        return 'MasterCard';
      case 3:
        return 'American Express';
      case 6:
        return 'Discover Card';
      default:
        return 'ACH';
    }
  }
  handleSubmitAddNewCard(e, t) {
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____',
    };
    this.setState({ card: { ...cardDataInital }, mask_number: '', name: '', updateCard: true, addNewCard: true });
  }

  openAdjustSettings() {
    this.setState({ isOpen: true });
  }

  handleClose = () => {
    this.setState({ isOpen: false, isLoading: true });
    this.fetchSettingsData();
  };
  a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }
  handleChangeTab(e, val) {
    this.setState({ tabValue: val }, () => {
      if (val === 0) {
        this.setState({ achDiscount: 0 }, () => {
          this.setState({ total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) });
        });
      } else {
        localStorage.setItem('achDiscount', this.state.svcFee);
        this.setState({ achDiscount: parseFloat(localStorage.getItem('achDiscount')) }, () => {
          this.setState(
            { total: parseFloat(this.state.amount) + this.state.tax + parseFloat(this.state.svcFee) },
            () => {
              this.setState({ total: parseFloat(this.state.total) - parseFloat(this.state.achDiscount) });
            }
          );
        });
      }
    });
  }
  handlePdfDownload(e) {
    const data = {
      amount: this.state.amount,
      total: this.state.total,
      svcFee: this.state.svcFee,
      tax: this.state.tax,
      taxType: this.state.taxType,
      svcType: this.state.svcType,
      invoice: this.state.invoice,
      dueDate: this.state.dueDate,
      companyId: this.state.company,
    };
    this.props.DownloadInvoice(data).then((res) => {
      let invoice = this.state.invoice;
      this.setState({ isLoading: true });
      setTimeout(() => {
        this.setState({ isLoading: false });
        window.open(this.props.PAYTRACE_AWS_PATH + invoice + 'invoice.pdf');
        // window.open(config.PDFURL + invoice + "invoice.pdf")
      }, 3000);
    });
  }
  handleSubmitFindBill() {
    if (this.state.isFindBill) {
      this.setState({ isFindBill: false });
    } else {
      this.setState({ isFindBill: true });
    }
  }

  render() {
    console.log('>>dddd');

    const { t } = this.props;
    const { linkSendDate } = this.state;
    let lastUpdate = new Date(linkSendDate);
    let currentDateTime = new Date();
    let diffTime = currentDateTime - lastUpdate;
    let TimeOutdiff = Math.round(diffTime / 1000 / 60);

    return (
      <Container component="main" maxWidth={false} className="terminal-container">
        {this.state.misMatchError.map((e, id) => (
          <ErrorMessage errors={[e]} key={id} />
        ))}
        {this.state.successMessages ? <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} /> : ''}
        <div className="min-wrap pay-page">
          <LoadingOverlay
            className={classes.tpTableFullW}
            active={this.state.isLoading}
            spinner
            text="Fetching your response ..."
          >
            <Grid container spacing={3}>
              <Grid item md={8}>
                <input type="hidden" name="_csrf" value={this.state.csrf['csrf-token']} />
                <input type="hidden" name="_csrfSecret" value={this.state.csrf['csrf-secret']} />
                <Card>
                  {this.state.isFindBill ? (
                    <CardContent>
                      <div className="terminal-inner">
                        <Grid container className="terminal-title-grid" spacing={3} alignItems="center">
                          <Grid item sm={3}>
                            <img className="terminal-logo" alt="logo" src={config.APPURL + '/assets/images/logo.png'} />
                          </Grid>
                          <Grid item sm={9}>
                            <div className="borderd">nypropertytaxpay.com</div>
                          </Grid>
                        </Grid>
                        <form className={classes.form} id="DemoForm">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                              <Grid container>
                                <Grid item md={12}>
                                  {/* <div className="borderd" style={{textAlign:'center'}}>
                                <b>Find Your Bill</b> 
                          </div> */}
                                </Grid>

                                <Grid item md={12} className="mb-3">
                                  <label>Payment Amount</label>
                                  <div className="pay-amount">
                                    $
                                    {this.state.total
                                      ? this.state.total.toFixed(2)
                                      : Number(this.state.amount).toFixed(2)}
                                  </div>
                                </Grid>
                                <AppBar position="static" color="default">
                                  <Tabs
                                    value={this.state.tabValue}
                                    onChange={(e, val) => {
                                      this.handleChangeTab(e, val);
                                    }}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="action tabs example"
                                  >
                                    <Tab label="Credit Card" {...this.a11yProps(0)} />
                                    {this.state.companyId && this.state.companyId.ACH ? (
                                      <Tab label="Bank transfer" {...this.a11yProps(1)} />
                                    ) : (
                                      ''
                                    )}
                                  </Tabs>
                                </AppBar>
                                <TabPanel value={this.state.tabValue} index={0}>
                                  <Grid container spacing={3}>
                                    <Grid item md={12}>
                                      <Textfield
                                        error={this.state.error.payerNameError}
                                        id="payerName"
                                        type="text"
                                        variant="outlined"
                                        labels={t('Name')}
                                        value={this.state.payerName}
                                        width={true}
                                        onChange={(e) => this.handleChange(e, t)}
                                      />
                                    </Grid>
                                    <Grid item md={12}>
                                      {this.state.errorCard.cardNumber ? (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            this.state.errorCard.cardNumber ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardNumberInvalid')}
                                        </label>
                                      ) : (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            this.state.errorCard.cardNumber ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardNumber')}
                                        </label>
                                      )}
                                      <MaskedInput
                                        type="text"
                                        value={this.state.card.cardNumber}
                                        className={classNames([classes.cardNum, 'pt-encrypt'])}
                                        mask={this.state.americanExpress ? '111111111111111' : '1111111111111111'}
                                        labels={t('cardNumber')}
                                        id="cardNumber"
                                        name="encrypted_number"
                                        size="20"
                                        onChange={(e) => this.handleChangeCard(e, t)}
                                      />
                                    </Grid>
                                    <Grid item md={4}>
                                      {this.state.errorCard.cardExpiryDate ? (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            this.state.errorCard.cardExpiryDate ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardExpiryDateInvalid')}
                                        </label>
                                      ) : (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            this.state.errorCard.cardExpiryDate ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardExpiryDate')}
                                        </label>
                                      )}
                                      <MaskedInput
                                        mask="11/1111"
                                        className={classes.expDate}
                                        value={this.state.card.cardExpiryDate}
                                        name="cardExpiryDate"
                                        id="cardExpiryDate"
                                        placeholder="mm/yyyy"
                                        onChange={(e) => this.handleChangeCard(e, t)}
                                      />
                                    </Grid>
                                    <Grid item md={2}>
                                      {this.state.errorCard.cardCsc ? (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            this.state.errorCard.cardCsc ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardCscInvalid')}
                                        </label>
                                      ) : (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            this.state.errorCard.cardCsc ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardCsc')}
                                        </label>
                                      )}
                                      <MaskedInput
                                        type="text"
                                        className={classNames([classes.cardCvv, 'pt-encrypt'])}
                                        value={this.state.card.cardCsc}
                                        mask={this.state.americanExpress ? '1111' : '111'}
                                        id="cardCsc"
                                        name="encrypted_csc"
                                        onChange={(e) => this.handleChangeCard(e, t)}
                                      />
                                    </Grid>
                                    <Grid item md={6}>
                                      <label>{t('Zip')}</label>
                                      <Textfield
                                        error={this.state.error.zipError}
                                        id="zip"
                                        type="text"
                                        variant="outlined"
                                        // labels={t("Zip")}
                                        value={this.state.zip}
                                        width={true}
                                        onChange={(e) => this.handleChange(e, t)}
                                      />
                                    </Grid>
                                    <Grid item md={12}>
                                      <p className="font-sm">
                                        <Link className={classes.back} component={RouterLink} to={'/'}>
                                          Sign in
                                        </Link>{' '}
                                        or{' '}
                                        <Link className={classes.back} component={RouterLink} to={'/Login'}>
                                          Create an account
                                        </Link>{' '}
                                        to save the payment method to pay faster next time.
                                      </p>
                                    </Grid>
                                  </Grid>
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={1}>
                                  <Textfield
                                    required={true}
                                    error={this.state.errorAch.accountNameError}
                                    id="accountName"
                                    type="text"
                                    labels={t('AccountName')}
                                    value={this.state.ach.accountName}
                                    width={true}
                                    onChange={(e) => {
                                      this.handleChangeAch(e, t);
                                    }}
                                    variant="standard"
                                  />
                                  <Textfield
                                    error={this.state.errorAch.accountNumberError}
                                    id="accountNumber"
                                    type="text"
                                    labels={t('AccountNumber')}
                                    value={this.state.ach.accountNumber}
                                    width={true}
                                    onChange={(e) => this.handleChangeAch(e, t)}
                                    variant="standard"
                                  />
                                  <Textfield
                                    required={true}
                                    error={this.state.errorAch.routingNumberError}
                                    id="routingNumber"
                                    type="text"
                                    labels={t('RoutingNumber')}
                                    value={this.state.ach.routingNumber}
                                    width={true}
                                    onChange={(e) => {
                                      this.handleChangeAch(e, t);
                                    }}
                                    variant="standard"
                                  />
                                </TabPanel>
                              </Grid>
                              <Grid item md={12}>
                                {this.state.isPaid || this.state.invoice === '' || this.state.successMessages ? (
                                  ''
                                ) : (
                                  <Buttons
                                    startIcon={<LockOutlinedIcon />}
                                    id="signUp"
                                    type="button"
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    className={classes.payBtn}
                                    onClick={(e) => this.handleSubmit(e, t)}
                                    text={t('Pay')}
                                  />
                                )}
                                <Buttons
                                  // startIcon={<LockOutlinedIcon />}
                                  id="signUp"
                                  type="button"
                                  fullWidth={true}
                                  variant="contained"
                                  color="primary"
                                  // className={classes.payBtn}
                                  onClick={(e) => this.handleSubmitFindBill(e, t)}
                                  text={t('Back')}
                                />
                              </Grid>
                              <Grid item md={12}>
                                <p className="font-sm">
                                  By Selecting Pay, I accept the{' '}
                                  <Link
                                    className={classes.back}
                                    component={RouterLink}
                                    to={'/TermCondition/' + this.state.companyId.id}
                                  >
                                    Terms of Service
                                  </Link>{' '}
                                  and have read and acknowledge the{' '}
                                  <Link
                                    className={classes.back}
                                    component={RouterLink}
                                    to={'/TermCondition/' + this.state.companyId.id}
                                  >
                                    Privacy Statement
                                  </Link>{' '}
                                  I also allow {this.state.companyId.dbaName} to charge $
                                  {this.state.total
                                    ? Number(this.state.total).toFixed(2)
                                    : Number(this.state.amount).toFixed(2)}{' '}
                                  to my card on {new Date(this.state.dueDate).toDateString()}.
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      </div>
                    </CardContent>
                  ) : (
                    <CardContent>
                      <div className="terminal-inner">
                        <Grid container className="terminal-title-grid" spacing={3} alignItems="center">
                          <Grid item sm={3}>
                            <img className="terminal-logo" alt="logo" src={config.APPURL + '/assets/images/logo.png'} />
                          </Grid>
                          <Grid item sm={9}>
                            <div className="borderd">nypropertytaxpay.com</div>
                          </Grid>
                        </Grid>
                        <form className={classes.form} id="DemoForm">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                              <Grid container>
                                <Grid item md={12}>
                                  <div className="borderd" style={{ textAlign: 'center', marginBottom: '2rem' }}>
                                    <b>Find Your Bill</b>
                                  </div>
                                </Grid>
                                <Grid item md={12} style={{ marginBottom: '1rem' }}>
                                  <AsyncSelect
                                    placeholder={t('SelectDistrict')}
                                    isClearable={true}
                                    // cacheOptions
                                    // loadOptions={(val)=>this.promiseOptions(val)}
                                    // onChange={val => this.setClientValue(val)}
                                  />
                                </Grid>
                                <Grid item md={12} style={{ marginBottom: '1rem' }}>
                                  <AsyncSelect
                                    placeholder={t('IdNumber')}
                                    isClearable={true}
                                    // cacheOptions
                                    // loadOptions={(val)=>this.promiseOptions(val)}
                                    // onChange={val => this.setClientValue(val)}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item md={12}>
                                <Buttons
                                  // startIcon={<LockOutlinedIcon />}
                                  id="signUp"
                                  type="button"
                                  fullWidth={true}
                                  variant="contained"
                                  color="primary"
                                  className={classes.payBtn}
                                  onClick={(e) => this.handleSubmitFindBill(e, t)}
                                  text={t('FindMyBill')}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      </div>
                    </CardContent>
                  )}
                </Card>
              </Grid>

              {this.state.isFindBill ? (
                <Grid item md={4}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Buttons
                            id="vInvoice"
                            type="button"
                            fullWidth={true}
                            className={classes.viewInvoice}
                            variant="outlined"
                            onClick={(e) => this.handlePdfDownload(e, t)}
                            text={t('ViewInvoice')}
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.invoiceDetail}>
                          {/* <TableContainer component={Paper}> */}
                          <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableBody>
                              <TableRow>
                                <TableCell align="left">Invoice</TableCell>
                                <TableCell align="right">{this.state.invoice}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">Due date</TableCell>
                                <TableCell align="right">{new Date(this.state.dueDate).toDateString()}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">SubTotal</TableCell>
                                <TableCell align="right">${Number(this.state.amount).toFixed(2)}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">
                                  {this.state.DiscountFeeName ? this.state.DiscountFeeName : 'Svc Fee'}
                                </TableCell>
                                <TableCell align="right">${this.state.svcFee.toFixed(2)}</TableCell>
                              </TableRow>
                              {this.state.achDiscount > 0 ? (
                                <TableRow>
                                  <TableCell align="left">ACH Discount</TableCell>
                                  <TableCell align="right">-${this.state.achDiscount.toFixed(2)}</TableCell>
                                </TableRow>
                              ) : (
                                ''
                              )}
                              <TableRow>
                                <TableCell align="left">Tax</TableCell>
                                <TableCell align="right">${this.state.tax.toFixed(2)}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">
                                  <b>Total</b>
                                </TableCell>
                                <TableCell align="right">
                                  <b>${this.state.total ? this.state.total.toFixed(2) : this.state.amount}</b>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          {/* </TableContainer> */}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ) : (
                ''
              )}
            </Grid>

            {/* </form> */}
          </LoadingOverlay>
        </div>
      </Container>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.InvoiceReducer,
    UserDetail: state.AdminUserReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
  };
}

export default connect(mapStateToProps, {
  getCsrfToken,
  DownloadInvoice,
  UserDetailById,
  InvoiceDetailByIdForPay,
  payByLink,
  getInvoiceNumber,
  countryDataList,
})(withTranslation()(PaymentNy));
