import React from 'react';
import PropTypes from 'prop-types';
import { Label } from './../Label';
import TextField from '@material-ui/core/TextField';

function Textfield(props) {
  function handleChange(event) {
    props.onChange(event, event.target.value);
  }

  return (
    <>
      {props.label ? <Label htmlFor={props.id}>{props.label}</Label> : null}
      <TextField
        helperText={props.helperText}
        required={props.required}
        error={props.error}
        id={props.id}
        idx={props.idx}
        name={props.name}
        label={props.labels}
        {...(props.classes ? { classes: props.classes } : {})}
        className={props.className}
        value={props.value}
        type={props.type}
        fullWidth={props.width}
        autoFocus={props.focus}
        disabled={props.disabled}
        inputProps={props.InputProps}
        multiline={props.multiline}
        defaultValue={props.defaultValue}
        rowsMax={props.rowsMax}
        row={props.row}
        rows={props.rows}
        placeholder={props.placeholder}
        variant={props.variant}
        autoComplete="off"
        margin="normal"
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onChange={handleChange}
        onKeyDown={props.onKeyDown}
        style={props.style}
      />
    </>
  );
}

Textfield.propTypes = {
  id: PropTypes.any,
  idx: PropTypes.number,
  name: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  variant: PropTypes.string,
  rowsMax: PropTypes.string,
  row: PropTypes.string,
  rows: PropTypes.string,
  InputProps: PropTypes.any,
  labels: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  width: PropTypes.bool,
  focus: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  style: PropTypes.any,
  classes: PropTypes.any,
};

Textfield.defaultProps = {
  className: '',
  disabled: false,
  autoFocus: false,
  type: 'text',
  value: '',
  error: false,
};

export default Textfield;
