import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classes from './Locations.module.scss';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { locationDetailById, locationEdit } from '../../actions/LocationAction';
import { makePhoneNumberForDB } from '../../helpers/util';
import { countryDataList } from '../../actions/CompanyAction';
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import validator from '../../helpers/checkValidations';
import { validateHtmlMarkUp } from '../../helpers/commonFunction';
const mapToFormatCountry = (values) => {
  return values.map((Obj) => ({ value: Obj.Country, label: Obj.Country }));
};
const mapToFormatStates = (state) => {
  return Object.keys(state).map((name) => ({ value: name, label: name }));
};

const mapToFormatCity = (state) => {
  return state.map((name) => ({ value: name, label: name }));
};

const getValidationStyle = (cond) => {
  if (cond) {
    return { width: '100%', border: '2px solid red' };
  } else {
    return { width: '100%' };
  }
};

class EditLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideShow: true,
      isSubmit: false,
      isLoading: true,
      locationName: '',
      email: '',
      phone: '',
      contactName: '',
      address1: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      stateList: {},
      cityList: [],
      Countrydata: [],
      zip: '',
      company: '',
      gatewayMerchantId: '',
      terminalId: '',
      terminalNumber: '',
      batchItem: '',
      notes: '',
      timeZone: new Date(),
      misMatchError: [],
      successMessages: false,
      user: JSON.parse(localStorage.getItem('user')),
      error: {
        nameError: false,
        locationNameError: false,
        phoneError: false,
        emailError: false,
        contactNameError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
      },
    };
  }
  getcountryDataList() {
    this.setState({ isLoading: true });
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false });
    });
  }

  getLocationData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.locationDetailById(params.id).then((res) => {
      this.setState({ ...res.payload.locationData.data.response, isLoading: false });
    });
  }
  componentDidMount() {
    this.getLocationData();
    this.getcountryDataList();
  }
  handleClickMenu(_e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    window.location.reload();
  }
  handleDateChange = (date) => {
    this.setState({ timeZone: date });
  };
  handleChange(event, _t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    const isHtmlMarkupValid = validateHtmlMarkUp(value);
    if (isHtmlMarkupValid) {
      return false;
    }
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError });
  }
  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;

    Object.keys(state).forEach((key, _value) => {
      if (validator(key, state[key])) {
        return validator(key, state[key])
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      } else {
        return state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : '';
      }
    });

    if (!state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      misMatchError[0] = t('EmailNotValid');
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      delete this.state._id;
      this.setState({ isLoading: true });
      this.props.locationEdit({ ...this.state, phone: makePhoneNumberForDB(this.state.phone) }).then((result) => {
        if (result !== undefined && result.type === 'LOCATION_SUCCESS') {
          console.log(this.props.info);
          this.setState({ isLoading: false, successMessages: true });
        }
        if (result !== undefined && result.type === 'LOCATION_ERROR') {
          this.setState({
            successMessages,
            isLoading: false,
            misMatchError: this.props.info.locationInfo.data.message,
          });
        }
      });
    }
  }

  setCountryState(val) {
    if (val !== null)
      this.setState({
        stateList: this.state.Countrydata.filter((obj) => obj.Country === val.label)[0].State,
        country: val.label,
      });
  }
  setStatesSet(val) {
    if (val !== null)
      this.setState({
        cityList: this.state.stateList[val.label],
        state: val.label,
      });
  }
  setCitySet(val) {
    if (val !== null) this.setState({ city: val.label });
  }

  render() {
    const { t } = this.props;
    const { Countrydata } = this.state;

    return (
      <div className="rightPanel">
        <Topheader />
        <Container component="main" maxWidth="xl">
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          {this.state.successMessages ? <SuccessMessage successes={[this.props.info.locationInfo.data.message]} /> : ''}

          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.locationNameError}
                  id="locationName"
                  type="text"
                  labels={t('LocationName')}
                  value={this.state.locationName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.contactNameError}
                  id="contactName"
                  type="text"
                  labels={t('ContactName')}
                  value={this.state.contactName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t('Email')}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  helperText="phone format xxx-xxx-xxxx"
                  error={this.state.error.phoneError}
                  id="phone"
                  type="number"
                  labels={t('Phone')}
                  value={this.state.phone}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.address1Error}
                  id="address1"
                  type="text"
                  labels={t('Address1')}
                  value={this.state.address1}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  id="address2"
                  type="text"
                  labels={t('Address2')}
                  value={this.state.address2}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>

              <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.country && this.state.isSubmit)}>
                  <TypableSelect
                    placeholder={'Select country name'}
                    suggestions={mapToFormatCountry(Countrydata)}
                    onChange={(val) => this.setCountryState(val)}
                    isSelected={this.state.country}
                    isClearable={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.state && this.state.isSubmit)}>
                  <TypableSelect
                    placeholder={'Select state name'}
                    suggestions={mapToFormatStates(this.state.stateList)}
                    onChange={(val) => this.setStatesSet(val)}
                    isSelected={this.state.state}
                    isClearable={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.city && this.state.isSubmit)}>
                  <TypableSelect
                    placeholder={'Select city name'}
                    suggestions={mapToFormatCity(this.state.cityList)}
                    onChange={(val) => this.setCitySet(val)}
                    isSelected={this.state.city}
                    isClearable={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.zipError}
                  id="zip"
                  type="text"
                  labels={t('Zip')}
                  value={this.state.zip}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.merchantIdError}
                  id="merchantId"
                  type="text"
                  labels={t('MerchantId')}
                  value={this.state.company}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  disabled={true}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  disabled={true}
                  required={true}
                  error={this.state.error.gatewayMerchantIdError}
                  id="gatewayMerchantId"
                  type="text"
                  labels={t('GatewayMerchantId')}
                  value={this.state.gatewayMerchantId}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  disabled={true}
                  required={true}
                  error={this.state.error.terminalIdError}
                  id="terminalId"
                  type="text"
                  labels={t('TerminalId')}
                  value={this.state.terminalId}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  disabled={true}
                  required={true}
                  error={this.state.error.terminalNumberError}
                  id="terminalNumber"
                  type="text"
                  labels={t('TerminalNumber')}
                  value={this.state.terminalNumber}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  disabled={true}
                  required={true}
                  error={this.state.error.batchItemError}
                  id="batchItem"
                  type="text"
                  labels={t('BatchItem')}
                  value={this.state.batchItem}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  id="notes"
                  type="text"
                  labels={t('Notes')}
                  value={this.state.notes}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}></Grid>
              <Grid item xs={12} sm={6}>
                {this.state.user.role === 1 ? (
                  <Buttons
                    id="signUp"
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => this.handleSubmit(e, t)}
                    text={t('Save')}
                  />
                ) : (
                  ''
                )}
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t('Back')}
                  onClick={this.props.history.goBack}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    );
  }
}

EditLocations.propTypes = {
  t: PropTypes.func,
  countryDataList: PropTypes.func,
  locationAdd: PropTypes.func,
  info: PropTypes.any,
  history: PropTypes.any,
  locationEdit: PropTypes.func,
  locationDetailById: PropTypes.func,
  match: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.LocationReducer,
  };
}
export default connect(mapStateToProps, { locationDetailById, countryDataList, locationEdit })(
  withTranslation()(EditLocations)
);
