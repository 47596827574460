import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogTitle, Grid } from '@material-ui/core';
import classes from './Terminal.module.scss';

const CashPaymentModal = ({ open, handleClose, handleProceedCash, activeElement, CashPaymentModalText }) => {
  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      keepMounted
      onClick={() => handleClose()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {`Are you sure you want to run a CASH Sale, you entered ${CashPaymentModalText} information?`}
      </DialogTitle>
      <DialogActions>
        <div className={classes.cashModal}>
          <Grid item xs={12} s={12} m={12}>
            <Button
              className={classes.cashModal}
              style={{ backgroundColor: '#3f51b5', color: '#ffffff' }}
              variant="contained"
              onClick={(e) => handleProceedCash(e, activeElement)}
            >
              Yes, Proceed
            </Button>
            <Button
              className={classes.cashModal}
              variant="contained"
              style={{ backgroundColor: '#d5d5d5' }}
              onClick={() => handleClose()}
            >
              No, Go Back
            </Button>
          </Grid>
        </div>
      </DialogActions>
    </Dialog>
  );
};

CashPaymentModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleProceedCash: PropTypes.func,
  activeElement: PropTypes.any,
  CashPaymentModalText: PropTypes.string,
};

export default CashPaymentModal;
