import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { csvFilesImport } from '../../actions/RequestFormAction';
import validator from '../../helpers/checkValidations';
import PropTypes from 'prop-types';

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideShow: true,
      ACH: true,
      isLoading: true,

      isSubmit: false,
      zip: '',
      timeZone: new Date(),
      misMatchError: [],
      logoError: [],
      successMessages: false,
      redirectCompany: false,
      image: null,
      selectedFile: null,
      imageUrl: null,
      preview: false,
      user: JSON.parse(localStorage.getItem('user')),
      error: {
        nameError: false,
        dbaNameError: false,
        emailError: false,
        contactNameError: false,
        websiteError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
      },
    };
  }

  componentDidMount() {
    // this.getcountryDataList();
    this.setState({ createdBy: this.state.user._id });
  }

  handleChange(event, _t) {
    let error = {};
    let misMatchError = [];
    let logoError = [];
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError, logoError, isSubmit: false });
  }
  handleSubmit(_e, _t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });

    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      const data = new FormData();
      for (const key in this.state) {
        data.append(key, this.state[key]);
      }
      //  console.log(">>>>",data)
      //  return false
      this.setState({ isLoading: true });
      window.scrollTo(0, 0, 0);
      this.props.csvFilesImport(data).then((_result) => {
        console.log('>>>', data);
        // if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
        //   this.setState({ successMessages: true ,isLoading: false}, () => {
        //       setTimeout(() => {
        //         this.setState({ successMessages: false })
        //         this.setState({ redirectCompany: true })
        //       }, 2000)

        //   });
        // }
        // if (result !== undefined && result.type === 'INVOICE_ERROR') {
        //   this.setState({ misMatchError: this.props.info.invoiceInfo.data.message, successMessages });
        // }
      });
    }
  }

  setCountryState(val) {
    if (val !== null) {
      this.setState({
        stateList: this.state.Countrydata.filter((obj) => obj.Country === val.label)[0].State,
        country: val.label,
      });
    } else {
      this.setState({ stateList: [], cityList: [], state: '', city: '' });
    }
  }
  setStatesSet(val) {
    if (val !== null) {
      this.setState({ cityList: val.label ? this.state.stateList[val.label] : [], state: val.label });
    } else {
      this.setState({ cityList: [], state: '', city: '' });
    }
  }
  setCitySet(val) {
    if (val !== null) {
      this.setState({ city: val.label });
    }
  }

  //upload image
  onChangeHandler = (event, t) => {
    let logoError = [];
    let selectedFile = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : '';
    let image = event.target.files[0];
    let allowedExtensions = /(\.dat)$/i;
    console.log('>>', image);
    if (image && !allowedExtensions.exec(image.name)) {
      logoError.push(t('CompanyLogoFileTypeNotCorrect'));
      this.setState({ logoError, image: null, preview: false });
    }
    if (image) {
      // let imgName = image.name.replace(/\s+/g, '-');
      // if (image.size > size) {
      //   logoError.push(t("CompanyLogoFileSizeNotCorrect"));
      //   this.setState({ logoError, image: null })
      // }
    }

    this.setState({ selectedFile: selectedFile, image: image, preview: true, logoError });
    // if (!error) {
    //   Resizer.imageFileResizer(
    //     event.target.files[0],
    //     150,
    //     150,
    //     'JPEG',
    //     100,
    //     0,
    //     uri => {
    //       let file = new File([uri], imgNameWithOutExt)
    //       this.setState({ selectedFile: selectedFile, image: file, preview: true, logoError })
    //     },
    //     'blob'
    //   );
    // }
  };
  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked }, () => {
      // console.log('>>>',this.state.ACH)
    });
  }
  render() {
    const { t } = this.props;
    const { redirectCompany } = this.state;
    return (
      <div className="rightPanel">
        {redirectCompany ? <Redirect to="/Company" /> : ''}
        <Topheader />

        <Container component="main">
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          {this.state.successMessages ? <SuccessMessage successes={[this.props.info.companyInfo.data.message]} /> : ''}
          {/* <LoadingOverlay
              className={classes.tpTableFullW}
              active={this.state.isLoading}
              spinner
              text='Fetching your response ...'> */}
          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <label>Upload the .csv format file.</label>
              </Grid>

              <Grid item xs={6} sm={3}>
                <label>
                  Invoices
                  <input type="file" name="file" onChange={(e) => this.onChangeHandler(e, t)} />
                </label>
                {this.state.logoError.length > 0 ? <ErrorMessage errors={[this.state.logoError]} /> : ''}
              </Grid>
              <Grid item xs={6} sm={3}>
                {/* <div className={(this.state.preview) ? classes.show : classes.hide}>

                  {((this.state.selectedFile) ? <img alt="preview" className={classes.preview} src={this.state.selectedFile} /> :
                    '')}
                </div> */}
              </Grid>
              <Grid item xs={6} sm={3}>
                {this.state.imageUrl ? <img alt="profile" src={this.state.imageUrl} className={classes.preview} /> : ''}
              </Grid>

              <Grid item xs={12}>
                <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t('Save')}
                />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t('Back')}
                  onClick={this.props.history.goBack}
                />
              </Grid>
            </Grid>
          </form>
          {/* </LoadingOverlay> */}
        </Container>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.InvoiceReducer,
  };
}

Demo.propTypes = {
  t: PropTypes.func,
  csvFilesImport: PropTypes.func,
  info: PropTypes.object,
  history: PropTypes.object,
};

export default connect(mapStateToProps, { csvFilesImport })(withTranslation()(Demo));
