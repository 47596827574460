const cleanTsepForm = () => {
  let datePicker = document.querySelectorAll('#tsep-datepicker');
  let cardField = document.querySelectorAll('#tsep-cardNum');
  let cvvField = document.querySelectorAll('#tsep-cvv2');

  // Check if there are more than one element
  if (datePicker && cardField && cvvField) {
    if (datePicker.length > 1) {
      datePicker.forEach((element) => {
        if (!element.hasAttribute('name')) {
          element.remove(); // Use remove() to remove the element directly
        }
      });
    }
    if (cardField.length > 1) {
      cardField.forEach((element) => {
        if (!element.hasAttribute('name')) {
          element.remove(); // Use remove() to remove the element directly
        }
      });
    }

    if (cvvField.length > 1) {
      cvvField.forEach((element) => {
        if (!element.hasAttribute('name')) {
          element.remove(); // Use remove() to remove the element directly
        }
      });
    }
  }
};

export default cleanTsepForm;
