const timezonesList = {
  'Etc/GMT+12': '(UTC-12:00) International Date Line West',
  'Etc/GMT+11': '(UTC-11:00) Coordinated Universal Time-11',
  'Etc/GMT+10': '(UTC-10:00) Hawaii',
  'America/Anchorage': '(UTC-09:00) Alaska',
  'America/Santa_Isabel': '(UTC-08:00) Baja California',
  'America/Los_Angeles': '(UTC-08:00) Pacific Standard Time (US & Canada)',
  'America/Creston': '(UTC-07:00) Arizona',
  'America/Chihuahua': '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  'America/Boise': '(UTC-07:00) Mountain Time (US & Canada)',
  'America/Belize': '(UTC-06:00) Central America',
  'America/Chicago': '(UTC-06:00) Central Time (US & Canada)',
  'America/Bahia_Banderas': '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
  'America/Regina': '(UTC-06:00) Saskatchewan',
  'America/Bogota': '(UTC-05:00) Bogota, Lima, Quito',
  'America/Detroit': '(UTC-04:00) Eastern Daylight Time (US & Canada)',
  'America/Indiana/Marengo': '(UTC-05:00) Indiana (East)',
  'America/Caracas': '(UTC-04:30) Caracas',
  'America/Asuncion': '(UTC-04:00) Asuncion',
  'America/Glace_Bay': '(UTC-04:00) Atlantic Time (Canada)',
  'America/Campo_Grande': '(UTC-04:00) Cuiaba',
  'America/Anguilla': '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  'America/Santiago': '(UTC-04:00) Santiago',
  'America/St_Johns': '(UTC-03:30) Newfoundland',
  'America/Sao_Paulo': '(UTC-03:00) Brasilia',
  'America/Argentina/La_Rioja': '(UTC-03:00) Buenos Aires',
  'America/Araguaina': '(UTC-03:00) Cayenne, Fortaleza',
  'America/Godthab': '(UTC-03:00) Greenland',
  'America/Montevideo': '(UTC-03:00) Montevideo',
  'America/Bahia': '(UTC-03:00) Salvador',
  'America/Noronha': '(UTC-02:00) Coordinated Universal Time-02',
  'America/Scoresbysund': '(UTC-01:00) Azores',
  'Atlantic/Cape_Verde': '(UTC-01:00) Cape Verde Is.',
  'Africa/Casablanca': '(UTC) Casablanca',
  'America/Danmarkshavn': '(UTC) Coordinated Universal Time',
  'Europe/Isle_of_Man': '(UTC+01:00) Edinburgh, London',
  'Atlantic/Canary': '(UTC) Dublin, Lisbon',
  'Africa/Abidjan': '(UTC) Monrovia, Reykjavik',
  'Arctic/Longyearbyen': '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Europe/Belgrade': '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Africa/Ceuta': '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  'Europe/Sarajevo': '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  'Africa/Algiers': '(UTC+01:00) West Central Africa',
  'Africa/Windhoek': '(UTC+01:00) Windhoek',
  'Asia/Nicosia': '(UTC+02:00) E. Europe',
  'Asia/Beirut': '(UTC+02:00) Beirut',
  'Africa/Cairo': '(UTC+02:00) Cairo',
  'Asia/Damascus': '(UTC+02:00) Damascus',
  'Africa/Blantyre': '(UTC+02:00) Harare, Pretoria',
  'Europe/Helsinki': '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Istanbul': '(UTC+03:00) Istanbul',
  'Asia/Jerusalem': '(UTC+02:00) Jerusalem',
  'Africa/Tripoli': '(UTC+02:00) Tripoli',
  'Asia/Amman': '(UTC+03:00) Amman',
  'Asia/Baghdad': '(UTC+03:00) Baghdad',
  'Europe/Kaliningrad': '(UTC+02:00) Kaliningrad',
  'Asia/Aden': '(UTC+03:00) Kuwait, Riyadh',
  'Africa/Addis_Ababa': '(UTC+03:00) Nairobi',
  'Europe/Kirov': '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
  'Europe/Astrakhan': '(UTC+04:00) Samara, Ulyanovsk, Saratov',
  'Asia/Tehran': '(UTC+03:30) Tehran',
  'Asia/Dubai': '(UTC+04:00) Abu Dhabi, Muscat',
  'Asia/Baku': '(UTC+04:00) Baku',
  'Indian/Mahe': '(UTC+04:00) Port Louis',
  'Asia/Tbilisi': '(UTC+04:00) Tbilisi',
  'Asia/Yerevan': '(UTC+04:00) Yerevan',
  'Asia/Kabul': '(UTC+04:30) Kabul',
  'Antarctica/Mawson': '(UTC+05:00) Ashgabat, Tashkent',
  'Asia/Yekaterinburg': '(UTC+05:00) Yekaterinburg',
  'Asia/Karachi': '(UTC+05:00) Islamabad, Karachi',
  'Asia/Kolkata': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Colombo': '(UTC+05:30) Sri Jayawardenepura',
  'Asia/Kathmandu': '(UTC+05:45) Kathmandu',
  'Antarctica/Vostok': '(UTC+06:00) Nur-Sultan (Astana)',
  'Asia/Dhaka': '(UTC+06:00) Dhaka',
  'Asia/Rangoon': '(UTC+06:30) Yangon (Rangoon)',
  'Antarctica/Davis': '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  'Asia/Novokuznetsk': '(UTC+07:00) Novosibirsk',
  'Asia/Hong_Kong': '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  'Asia/Krasnoyarsk': '(UTC+08:00) Krasnoyarsk',
  'Asia/Brunei': '(UTC+08:00) Kuala Lumpur, Singapore',
  'Antarctica/Casey': '(UTC+08:00) Perth',
  'Asia/Taipei': '(UTC+08:00) Taipei',
  'Asia/Choibalsan': '(UTC+08:00) Ulaanbaatar',
  'Asia/Irkutsk': '(UTC+08:00) Irkutsk',
  'Asia/Dili': '(UTC+09:00) Osaka, Sapporo, Tokyo',
  'Asia/Pyongyang': '(UTC+09:00) Seoul',
  'Australia/Adelaide': '(UTC+09:30) Adelaide',
  'Australia/Darwin': '(UTC+09:30) Darwin',
  'Australia/Brisbane': '(UTC+10:00) Brisbane',
  'Australia/Melbourne': '(UTC+10:00) Canberra, Melbourne, Sydney',
  'Antarctica/DumontDUrville': '(UTC+10:00) Guam, Port Moresby',
  'Australia/Currie': '(UTC+10:00) Hobart',
  'Asia/Chita': '(UTC+09:00) Yakutsk',
  'Antarctica/Macquarie': '(UTC+11:00) Solomon Is., New Caledonia',
  'Asia/Sakhalin': '(UTC+11:00) Vladivostok',
  'Antarctica/McMurdo': '(UTC+12:00) Auckland, Wellington',
  'Etc/GMT-12': '(UTC+12:00) Coordinated Universal Time+12',
  'Pacific/Fiji': '(UTC+12:00) Fiji',
  'Asia/Anadyr': '(UTC+12:00) Magadan',
  'Asia/Kamchatka': '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
  'Etc/GMT-13': "(UTC+13:00) Nuku'alofa",
  'Pacific/Apia': '(UTC+13:00) Samoa',
};

export default timezonesList;
