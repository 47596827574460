import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import classes from './ForgotPassword.module.scss';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { DataApi } from './../../helpers/PostDataApi';
import { config, message } from '../../constants';
import { connect } from 'react-redux';
import { Empty } from '../../actions/ForgotPasswordAction';
import { withRouter } from 'react-router';
import { checkPasswordStrength } from '../../helpers/commonFunction';
import validator from '../../helpers/checkValidations';
import Alert from '@mui/material/Alert';
class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
      TimeOutdiff: props.TimeOutdiff,
      userName: props.userName,
      password: '',
      confirmPassword: '',
      misMatchError: [],
      successMessages: false,
      successDisplay: false,
      passwordStrengthCheck: false,
      paswwordStrengthStatus: undefined,
      passwordSeverity: undefined,
      btnDisabled: true,
      error: {
        emailError: false,
        passwordError: false,
        confirmPasswordError: false,
      },
    };
  }

  handleChange(event, t) {
    let { id, value } = event.target;
    let { password, confirmPassword } = this.state;
    let errorOnChange = {};
    this.setState({ [id]: value });
    let misMatchError = [];
    let successMessages = false;

    if (id === 'password') {
      const passwordLength = validator(id, value);
      if (passwordLength) {
        errorOnChange[id + 'Error'] = true;
        this.setState({
          errorOnChange,
          passwordSeverity: 'error',
          paswwordStrengthStatus: 'Limit exceeded 25 characters',
        });
        return;
      } else {
        errorOnChange[id + 'Error'] = false;
        this.setState({ errorOnChange, passwordSeverity: undefined });
      }
      const { passwordStrength, strengthStatus, passwordStrengthSeverity } = checkPasswordStrength(value);
      this.setState({
        passwordStrengthCheck: passwordStrength,
        paswwordStrengthStatus: strengthStatus,
        passwordSeverity: passwordStrengthSeverity,
      });
    }
    if (!this.state.passwordStrengthCheck) {
      this.setState({ misMatchError, successMessages, btnDisabled: true });
    } else if (
      (id === 'confirmPassword' && password !== event.target.value) ||
      (id === 'password' && confirmPassword !== value)
    ) {
      misMatchError[0] = t('MisMatchPassword');
      this.setState({ misMatchError, successMessages, btnDisabled: true });
    } else {
      this.setState({ misMatchError, successMessages, btnDisabled: false });
    }
  }

  handleSubmit(_e, _t) {
    let misMatchError = [];
    let successMessages = false;
    let state = Object.assign({}, this.state);

    let error = {};
    let errorFlag = false;

    Object.keys(state).map((key, _value) =>
      state[key] === '' ? ((error[key + 'Error'] = true), (errorFlag = true)) : ''
    );

    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      DataApi(state, config.BASEURL + 'resetPassword')
        .then((response) => {
          let { status, data } = response;
          if (status === 200 && data.statusCode === 200) {
            this.setState({ successMessages: true, successDisplay: data.message });
          } else {
            misMatchError[0] = data.message;
            this.setState({ successMessages, misMatchError, error });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Container component="main" maxWidth="sm">
          <div>
            {this.state.TimeOutdiff > 5 ? (
              <span>link has been expired in 5 minutes </span>
            ) : (
              <form className={classes.form} noValidate>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="userName"
                  type="text"
                  labels={t('Username')}
                  disabled={true}
                  value={this.state.userName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={false}
                />
                <div>
                  <Textfield
                    required={true}
                    error={this.state.error.passwordError}
                    id="password"
                    type="password"
                    labels={t('Password')}
                    value={this.state.password}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={false}
                  />
                  {this.state.passwordSeverity !== undefined && (
                    <>
                      <div>
                        <Alert severity={this.state.passwordSeverity}>{this.state.paswwordStrengthStatus}</Alert>
                      </div>
                    </>
                  )}
                </div>
                <Textfield
                  required={true}
                  error={this.state.error.confirmPasswordError}
                  id="confirmPassword"
                  type="password"
                  labels={t('ConfirmPassword')}
                  value={this.state.confirmPassword}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={false}
                />

                <Buttons
                  id="resetPassword"
                  type="button"
                  width={true}
                  variant="contained"
                  color="primary"
                  disabled={this.state.btnDisabled}
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t('ResetPassword')}
                />
                <div className="passwordPolicy">
                  <h4>{message.PASSWORD_POLICY_MESSAGE}</h4>
                </div>
              </form>
            )}
          </div>
        </Container>
        {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
        {this.state.successMessages ? <SuccessMessage successes={[this.state.successDisplay]} /> : ''}
      </div>
    );
  }
}

ResetPassword.propTypes = {
  t: PropTypes.func,
  email: PropTypes.string,
  userName: PropTypes.string,
  TimeOutdiff: PropTypes.any,
};
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { Empty })(withTranslation()(withRouter(ResetPassword)));
