const rates = `State,Surcharge On,Fee amount,Fee type
Alabama,TRUE,3,%
Alaska,TRUE,3,%
Arizona,TRUE,3,%
Arkansas,TRUE,3,%
California,TRUE,3,%
Colorado,TRUE,2,%
Connecticut,FALSE,,
Delaware,TRUE,3,%
Florida,TRUE,3,%
Georgia,TRUE,3,%
Hawaii,TRUE,3,%
Idaho,TRUE,3,%
Illinois,TRUE,3,%
Indiana,TRUE,3,%
Iowa,TRUE,3,%
Kansas,TRUE,3,%
Kentucky,TRUE,3,%
Louisiana,TRUE,3,%
Maine,TRUE,3,%
Maryland,TRUE,3,%
Massachusetts,FALSE,,
Michigan,TRUE,3,%
Minnesota,TRUE,3,%
Mississippi,TRUE,3,%
Missouri,TRUE,3,%
Montana,TRUE,3,%
Nebraska,TRUE,3,%
Nevada,TRUE,3,%
New Hampshire,TRUE,3,%
New Jersey,TRUE,3,%
New Mexico,TRUE,3,%
New York,FALSE,,
North Carolina,TRUE,3,%
North Dakota,TRUE,3,%
Ohio,TRUE,3,%
Oklahoma,TRUE,3,%
Oregon,TRUE,3,%
Pennsylvania,TRUE,3,%
Rhode Island,TRUE,3,%
South Carolina,TRUE,3,%
South Dakota,TRUE,3,%
Tennessee,TRUE,3,%
Texas,TRUE,3,%
Utah,TRUE,3,%
Vermont,TRUE,3,%
Virginia,TRUE,3,%
Washington,TRUE,3,%
West Virginia,TRUE,3,%
Wisconsin,TRUE,3,%
Wyoming,TRUE,3,%`;

export default rates;
