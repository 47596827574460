import React from 'react';
import PropTypes from 'prop-types';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import InputAdornment from '@material-ui/core/InputAdornment';

CurrencyDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.any,
  disabled: PropTypes.bool,
};

export default function CurrencyDisplay({ label, value, id, required = false, error, disabled = false }) {
  // console.log(label, checked)
  return (
    // <Grid item xs={12} className="discount-label-field">
    //   {topLabel ? <label>{label}</label> : <></>}
    //   <FormControlLabel control={<IOSSwitch checked={checked} onChange={onChange} value={checked} />} />
    //   {!topLabel ? <label className="mr-2">{label}</label> : <></>}
    // </Grid>
    <CurrencyTextField
      disabled={disabled}
      modifyValueOnWheel={false}
      label={label}
      variant="outlined"
      value={value}
      currencySymbol="$"
      //minimumValue="0"
      outputFormat="string"
      decimalCharacter="."
      digitGroupSeparator=","
      id={id}
      textAlign="left"
      style={{ width: '100%' }}
      required={required}
      error={error}
      width={true}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
}
