const characterlimit = 150;
const notesCharacterLimit = 500;
const notesInvalidCharacters = `+#%^&*=[]{};:|<>`;
const blankspace = `/^s+$/`;
const stringEmptySpaces = /(?:^\s+|\s+$)/g;

export const rules = {
  firstName: {
    rule: {
      stringEmptySpaces,
      characterlimit,
      blankspace,
    },
    messages: {
      stringEmptySpaces: `Invalid First or Last Name.`,
      characterlimit: `You can’t use more than ${characterlimit} characters.`,
      blankspace: `Empty spaces not allowed.`,
    },
  },
  lastName: {
    rule: {
      stringEmptySpaces,
      characterlimit,
      blankspace,
    },
    messages: {
      stringEmptySpaces: `Invalid First or Last Name.`,
      characterlimit: `You can’t use more than ${characterlimit} characters.`,
      blankspace: `Empty spaces not allowed.`,
    },
  },
  payerName: {
    rule: {
      characterlimit: characterlimit * 2,
    },
    messages: {
      characterlimit: `You can’t use more than ${characterlimit * 2} characters.`,
    },
  },
  notes: {
    rule: {
      notesCharacterLimit,
      notesInvalidCharacters,
    },
    messages: {
      characterLimit: `You can’t use more than ${notesCharacterLimit} characters.`,
      invalidCharacters: `Special characters are not allowed i.e. (${notesInvalidCharacters}).`,
    },
  },
  customer_id: {
    rule: {
      blankspace,
    },
    messages: {
      blankspace: `Empty spaces not allowed.`,
    },
  },
};
