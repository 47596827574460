import config from '../constants/config';
import { DataGetApi } from '../helpers/PostDataApi';
import moment from 'moment';
import getTsepDeviceIdsAndUrls from '../constants/keys';

const getTsepManifestToken = async (location, route) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;
    const apiUrl = route === 'paylink' ? 'getTsepManifestTokenOneTime' : 'getTsepManifestToken';
    const response = await DataGetApi(
      `${config.BASEURL}${apiUrl}/${location._id}`,
      location,
      token,
      location?.multipassCredentials
    );
    return response;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const checkTsepTimestamp = (date) => {
  const yourDateTime = moment(date);
  const timeDifference = moment().diff(yourDateTime);
  const isMoreThan24Hours = timeDifference > 24 * 60 * 60 * 1000;
  return isMoreThan24Hours;
};

const injectTsepScriptDOM = (manifestKey, multipassTSEPId) => {
  const script = document.createElement('script');
  script.src = `${config.TSEP_MANIFEST_VALIDATION_URL}${multipassTSEPId}?${manifestKey}`;
  script.async = true;
  script.id = 'manifest-script';
  document.head.appendChild(script);
};

const updateTsepDetailsLocally = (manifestKey, location) => {
  location.multipassCredentials.tsepManifest = manifestKey ?? null;
  location.multipassCredentials.tsepTimestamp = manifestKey ? new Date().toLocaleString() : null;
  localStorage.setItem('locationPaydetails', JSON.stringify(location));
};

function isMatchingDeviceIdAndUrl(deviceId, domainURL, location) {
  const tsepDeviceIds = getTsepDeviceIdsAndUrls(location);
  const mappedDomains = [
    {
      deviceId: tsepDeviceIds.TSEP_DEVICE_ID_LOCAL,
      url: 'localhost',
    },
    {
      deviceId: tsepDeviceIds.TSEP_DEVICE_ID_DEV,
      url: 'dev.myflexstaging.com',
    },
    {
      deviceId: tsepDeviceIds.TSEP_DEVICE_ID_TEST,
      url: 'test.myflexstaging.com',
    },
    {
      deviceId: tsepDeviceIds.TSEP_DEVICE_ID_STAGING,
      url: 'staging.myflexstaging.com',
    },
    {
      deviceId: tsepDeviceIds.TSEP_DEVICE_ID_PROD,
      url: 'new.mytitaniumflex.com',
    },
  ];
  for (const domainMap of mappedDomains) {
    if (domainMap.deviceId === deviceId && domainMap.url === domainURL) {
      return true;
    }
  }
  return false;
}

const loadTsepManifestScript = async (locationDetails = null, route = null) => {
  try {
    const location = locationDetails || JSON.parse(localStorage.getItem('locationPaydetails'));
    const domainURL = window.location.hostname;
    const multipassCredentials = location?.multipassCredentials;

    if (!multipassCredentials) {
      console.error('Error loading TSEP manifest script:');
      return false;
    }
    const { tsepManifestToken, tsepTimestamp, multipassTSEPId, transactionKey } = multipassCredentials;
    // Before proceeding we need to check if valid tsepId is being used as per domain for the location
    const validDomain = isMatchingDeviceIdAndUrl(multipassTSEPId, domainURL, location);
    if (validDomain === false || transactionKey === null) {
      return false;
    }
    if (tsepManifestToken && tsepTimestamp && !checkTsepTimestamp(tsepTimestamp)) {
      injectTsepScriptDOM(tsepManifestToken, multipassTSEPId);
      return true;
    } else {
      const response = await getTsepManifestToken(location, route);
      if (response.data.statusCode === '200' || response.data.statusCode === 200) {
        const manifestKey = response.data.response.tsepManifest;
        const { multipassTSEPId } = multipassCredentials;
        injectTsepScriptDOM(manifestKey, multipassTSEPId);
        updateTsepDetailsLocally(manifestKey, location);
        return true;
      } else {
        updateTsepDetailsLocally(null);
        console.error('Error loading TSEP manifest script:');
        return false;
      }
    }
  } catch (error) {
    console.error('Error loading TSEP manifest script:', error);
    throw error;
  }
};

export { loadTsepManifestScript };
