import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

export const DialogBox = ({ open, handleClose, message }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {/* <DialogTitle id="alert-dialog-title">{"Add Client"}</DialogTitle> */}
    <DialogContent>
      {/* <DialogContentText id="alert-dialog-description"> */}
      {message}
      {/* {'Payment link has been sent to the entered phone / email. Your customer can pay for the Tow using that.'} */}
      {/* </DialogContentText> */}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" autoFocus>
        {' '}
        Close{' '}
      </Button>
    </DialogActions>
  </Dialog>
);
DialogBox.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.any,
};
export default DialogBox;
