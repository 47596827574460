import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import MaskedInput from 'react-maskedinput';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from '../Payment.module.scss';
import { checkCreditOrDebit } from '../../../helpers/PostDataApi';
import config from '../../../constants/config';
import { loadTsepManifestScript } from '../../../helpers/tsepUtils';
import autoFillMultipassFields from '../../../helpers/functions/autoFillCardFields';
import Alert from '@mui/material/Alert';
import '../tsepFields.css';
import PropTypes from 'prop-types';

const CardForm = (props) => {
  const [tsepFieldError, setTsepFieldError] = useState('');
  const [cardType, setCardType] = useState(null);
  const [creditOrDebit, setCreditOrDebit] = useState(null);
  const [tsepAuth, setTsepAuth] = useState(null);
  const [location, setLocation] = useState(null);
  const [waitingForTsepToken, disableWaitingForTsepToken] = useState(true);
  useEffect(() => {
    document.addEventListener('tsepEventUpdate', handleTsepEventUpdated);
    const { location: locationDetails } = props;
    if (locationDetails.gateway == 'multipass' && locationDetails.multipassCredentials) {
      setLocation(locationDetails);
    }

    if (location && location?.gateway == 'multipass' && !tsepAuth) {
      tsepAuthToken();
    }
  }, [location]);
  useEffect(() => {
    if (props.location && props.location.gateway == 'multipass' && props.location.multipassCredentials) {
      setLocation(props.location);
    }
  }, [props]);
  const { t } = useTranslation();

  const renderLabel = (text, isError) => (
    <label className={classNames(['MuiTypography-gutterBottom', isError ? 'errorCard' : ''])}>{text}</label>
  );

  const tsepAuthToken = async () => {
    try {
      const tsepAuthentication = await loadTsepManifestScript(props.location, 'paylink');
      setTsepAuth(tsepAuthentication);
      disableWaitingForTsepToken(false);
    } catch (err) {
      console.error('Error Authenticating with Tsep:', err);
    }
  };

  const renderErrorMessage = (errorMessage) => (
    <label className={classNames(['MuiTypography-gutterBottom', 'errorCard'])}>{errorMessage}</label>
  );
  const renderCardForm = () => {
    if (props.gateway !== 'multipass') {
      return (
        <Grid container spacing={3}>
          <Grid item md={12}>
            {props.error.cardNumberError
              ? renderErrorMessage(t('cardNumberInvalid'))
              : renderLabel(t('cardNumber'), props.error.cardNumberError)}
            <MaskedInput
              type="text"
              value={props.card.cardNumber}
              className={classNames([classes.cardNum, 'pt-encrypt'])}
              mask={props.americanExpress ? '111111111111111' : '1111111111111111'}
              label="Card Number"
              id="cardNumber"
              name="encryptedNumber"
              style={{ minWidth: '100%', paddingLeft: '18px' }}
              size="20"
              onChange={props.handleChangeCard}
            />
          </Grid>
          <Grid item md={4}>
            {props.error.cardExpiryDateError
              ? renderErrorMessage(t('cardExpiryDateInvalid'))
              : renderLabel(t('cardExpiryDate'), props.error.cardExpiryDateError)}
            <MaskedInput
              mask="11/1111"
              className={classes.expDate}
              value={props.card.cardExpiryDate}
              name="cardExpiryDate"
              id="cardExpiryDate"
              placeholder="mm/yyyy"
              ref={props.expiryDateRef}
              onChange={props.handleChangeCard}
            />
          </Grid>
          <Grid item md={2}>
            {props.error.cardCscError
              ? renderErrorMessage(t('cardCscInvalid'))
              : renderLabel(t('cardCsc'), props.error.cardCscError)}
            <MaskedInput
              type="text"
              className={classNames([classes.cardCvv, 'pt-encrypt'])}
              value={props.card.cardCsc}
              mask={props.americanExpress ? '1111' : '111'}
              id="cardCsc"
              name="encryptedCsc"
              ref={props.cscCardRef}
              onChange={props.handleChangeCard}
            />
          </Grid>
        </Grid>
      );
    } else {
      return TsepForm();
    }
  };
  const TsepForm = () => {
    if (props.location.gateway === 'multipass') {
      autoFillMultipassFields();
    }
    if (tsepAuth) {
      return (
        <form method="POST" id="payment-form">
          {tsepFieldError && renderErrorMessage(tsepFieldError)}
          <div id="tsep-cardContainer" class="cardTsepContainer">
            <div id="tsep-cardNumDiv" data-auto-formatting="Y" data-validate-cc="Y" data-detect-card-type="Y"></div>
          </div>
          <div id="tsep-CardData" class="cardDataTsep">
            <div id="tsep-datepickerDiv" data-validate-expiry-date="Y"></div>
            <div id="tsep-cvv2Div" data-validate-cvv2="Y"></div>
          </div>
          <div
            id="tsep-cardHolderNameDiv"
            class="cardHolderTsep"
            data-validate-name="Y"
            style={{ display: 'none' }}
          ></div>
          <div id="tsep-zipCodeDiv" data-validate-zipcode="Y" style={{ display: 'none' }}></div>
        </form>
      );
    } else if (waitingForTsepToken) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    } else {
      return TsepCardError();
    }
  };
  const TsepCardError = () => {
    return (
      <div>
        <Alert variant="outlined" severity="error">
          Payment form could not be loaded. There seems to a problem with gateway configuration. Please contact
          developer support.
        </Alert>
      </div>
    );
  };
  const setTsepCardPaymentType = () => {
    const cardNumberElement = document.getElementById('tsep-cardNum');
    if (cardNumberElement) {
      const cardNumberValue = cardNumberElement?.value.replace(/\s/g, '');
      checkCardPaymentType(cardNumberValue);
    }
  };
  const checkCardPaymentType = (cardNumber) => {
    checkCreditOrDebit(config.BIN_CHECKER_URL, config.BIN_API_KEY, cardNumber.substring(0, 6))
      .then((data) => {
        props.setPaymentType(data.data.type ? data.data.type : null);
        props.setCreditOrDebit(data.data.type ? data.data.type : null);
        setCardType(data.data.card ? data.data.card : null);
        setCreditOrDebit(data.data.type ? data.data.type : null);
      })
      .catch(() => {
        if (creditOrDebit !== null) {
          setCreditOrDebit(null);
        }
      });
  };
  const handleTsepEventUpdated = (event) => {
    const tsepEventDetail = event.detail;
    if (tsepEventDetail.eventType === 'FieldValidationErrorEvent') {
      setTsepFieldError(tsepEventDetail.event.message);
    } else if (tsepEventDetail.eventType === 'FieldValidationSuccessEvent') {
      setTsepFieldError(null);
    } else if (tsepEventDetail.eventType === 'TokenEvent' && tsepEventDetail.event.status === 'PASS') {
      setTsepFieldError(null);
      localStorage.setItem('0', tsepEventDetail.event.tsepToken);
      localStorage.setItem('1', tsepEventDetail.event.cvv2);
      props.setCard({
        isOpen: true,
        cardNumber: tsepEventDetail.event.tsepToken,
        cardExpiryDate: tsepEventDetail.event.expirationDate,
        encryptedNumber: tsepEventDetail.event.tsepToken,
        cardType: cardType ?? tsepEventDetail.event.cardType,
        customerToken: false,
        cardCsc: tsepEventDetail.event.cvv2,
      });
      props.disableMultipassSubmit(false);
      // Handle other cases if needed
    } else if (tsepEventDetail.eventType === 'TokenEvent' && tsepEventDetail.event.status === 'FAILURE') {
      setTsepFieldError(tsepEventDetail.event.message);
    } else if (tsepEventDetail.eventType === 'FocusOutEvent' && tsepEventDetail.event.fieldName === 'tsep-cardNum') {
      setTsepCardPaymentType();
    } else if (
      tsepEventDetail.eventType === 'ErrorEvent' &&
      tsepEventDetail.event.status === 'FAIL' &&
      tsepEventDetail.event.responseCode === 'TSEPERR911'
    ) {
      this.props.setTsepAuth(false);
    }
  };
  return renderCardForm();
};
CardForm.propTypes = {
  location: PropTypes.any,
  disableMultipassSubmit: PropTypes.func,
  setCard: PropTypes.func,
  gateway: PropTypes.any,
};
export default CardForm;
