import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import classes from './Login.module.scss';
import { connect } from 'react-redux';
import { login } from './../../actions/LoginAction';
import { getEnvironmentVariablesList } from './../../actions/EnvironmentVariablesAction';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import validator from '../../helpers/checkValidations';
import getStartPage from '../../helpers/getStartPage';
import TextfieldV2 from '../../components/TextfieldV2/TextfieldV2';
import { config } from '../../constants';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      responseError: false,
      loginErrorMsg: '',
    };
  }
  handleChange(event) {
    let error = [];
    const { id, value } = event.target;
    const validationCheck = validator(id, value);
    validationCheck ? (error[id] = true) : (error[id] = false);
    this.setState({ [id]: value, responseError: false, error: error });
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('token'));
    if (user) {
      const userData = JSON.parse(localStorage.getItem('user'));
      if (userData?.role === config.CUSTOMER_USER_ROLE) {
        window.location.href = getStartPage(userData?.permissions, true);
      } else {
        window.location.href = getStartPage(userData?.permissions);
      }
    }
  }
  onLoginSubmit = (e) => {
    e.preventDefault();
    this.setState({ responseError: false });
    let error = {};
    let errorFlag = false;

    if (this.state.userName === '') {
      errorFlag = true;
      error.userName = true;
    }

    if (this.state.password === '') {
      errorFlag = true;
      error.password = true;
    }

    if (errorFlag) {
      this.setState({ error });
      return false;
    } else {
      this.props.dispatch(login(this.state, this.props)).then((response) => {
        // fetch environment variables from api
        if (response !== undefined && response.type === 'LOGIN_ERROR') {
          this.setState({ loginErrorMsg: response.payload.error.data.message, responseError: true });
          return false;
        }
        this.props.dispatch(getEnvironmentVariablesList()).then((varsResponse) => {
          if (varsResponse !== undefined && varsResponse.type === 'ENV_VAR_ERROR') {
            this.setState({ responseError: true });
          }
        });
      });
    }
  };

  render() {
    const { t } = this.props;
    const { imageStyle } = styles;
    return (
      <div className={classes.login}>
        <Container component="main" maxWidth="sm">
          <div className={classes.container}>
            <Link component={RouterLink} to={'/'}>
              <img src="/assets/images/logo-new.png" style={imageStyle} height={'50rem'} alt="logo" />
            </Link>
            <div className={classes.paper}>
              <h3 className={classes.heading}>Login</h3>
              <form className={classes.form} noValidate onSubmit={this.onLoginSubmit}>
                <TextfieldV2
                  required={true}
                  error={this.state.error ? this.state.error.userName : null}
                  id="userName"
                  type="text"
                  placeholder={'Username or email'}
                  value={this.state.userName}
                  width={true}
                  onChange={(e) => this.handleChange(e)}
                  focus={true}
                  variant="outlined"
                  className={classes.formTextfield}
                />
                <TextfieldV2
                  required={true}
                  error={this.state.error ? this.state.error.password : null}
                  id="password"
                  type="password"
                  placeholder={t('Password')}
                  value={this.state.password}
                  width={true}
                  onChange={(e) => this.handleChange(e)}
                  focus={false}
                  variant="outlined"
                  className={classes.formTextfield}
                />
                <Grid item xs>
                  <Link className={classes.forget} component={RouterLink} to={'/Forgot'}>
                    Forgot Username/Password?
                  </Link>
                </Grid>

                <Grid item xs={12}>
                  <Button id="signIn" type="submit" variant="contained" color="primary" className={classes.submit}>
                    {t('Login')}
                  </Button>
                </Grid>
              </form>
            </div>
            {this.state.responseError ? (
              <ErrorMessage errors={[this.props.info.loginInfo.data.message]} className="error" />
            ) : (
              ''
            )}
            <div className={classes.footer}>
              Customer support <span className={classes.phone}>877-972-0700</span> │{' '}
              <a
                className={classes.mail}
                href="mailto:techsupport@titaniumpayments.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                techsupport@titaniumpayments.com
              </a>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  t: PropTypes.func,
  info: PropTypes.object,
  dispatch: PropTypes.func,
};
const styles = {
  imageStyle: { maxWidth: '65%', marginLeft: '40%', marginTop: '100px' },
};
function mapStateToProps(state) {
  return {
    info: state.LoginReducer,
  };
}

export default connect(mapStateToProps)(withTranslation()(Login));
