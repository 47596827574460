export const lookUp = {
  6: 'Daily',
  5: 'Weekly',
  7: '1st and 15th',
  4: 'Bi-Weekly',
  3: 'Monthly',
  9: 'Bi-Monthly',
  2: 'Quarterly',
  1: 'Annually',
  8: 'Semi-Annually',
  A: 'Trimesterly',
};
