import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import _ from 'lodash';
import { Textfield } from './../../components/Textfield';
import { InvoiceDetailById } from '../../actions/PaymentAction';
import PrintIcon from '@material-ui/icons/Print';
import { addInvoice } from '../../actions/RequestFormAction';
import PropTypes from 'prop-types';
import { customerList } from '../../actions/CustomerAction';
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import LoadingOverlay from 'react-loading-overlay';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { convertDateToTimeZoneFormat, formatCurrency, userRole } from '../../helpers/commonFunction';
import PrintReceipt from '../../helpers/PrintReceipt';
import PrintInvoice from '../../helpers/PrintInvoice';
import { settingDataByCompanyId } from '../../actions/AdminUserAction';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    maxWidth: '100%',
    overflow: 'auto',
  },
});

class EditInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideShow: true,
      isLoading: false,
      isOpen: false,
      transactions: '',
      amount: '',
      invoice: '',
      isSubmit: false,
      isClose: false,
      dueDate: new Date(),
      invoiceDate: new Date(),
      misMatchError: [],
      CoustmerData: [],
      CardData: [],
      creditOrDebit: 'Credit',
      successMessages: false,
      tabValue: 0,
      indexValue: 0,
      isCustomerUser: false,
      transitionDuration: {
        enter: this.props.theme.transitions.duration.enteringScreen,
        exit: this.props.theme.transitions.duration.leavingScreen,
      },
      error: {
        firstNameError: false,
        emailError: false,
        amountError: false,
        phoneError: false,
      },
    };
    this.paymentRef = React.createRef();
  }

  componentDidMount() {
    this.getInvoiceData();
    this.setLocalTimeZone();
    if (window.paytrace) {
      let userData = JSON.parse(localStorage.getItem('user'));
      if (userData?.role === 5) {
        this.setState({ isCustomerUser: true });
      }
      this.setState({ createdBy: userData._id, companyId: userData.company._id }, () => {
        this.settingByCompanyId();
      });
    }
  }

  settingByCompanyId() {
    this.setState({ isLoading: true });
    let locationId = '';
    if (localStorage.getItem('locationArrIds') !== null) {
      locationId =
        JSON.parse(localStorage.getItem('locationArrIds')).length > 1
          ? null
          : JSON.parse(localStorage.getItem('locationArrIds'))[0];
    }
    let params = { id: this.state.companyId, locationId: locationId };
    this.props.settingDataByCompanyId(params).then((res) => {
      if (res.payload.error) {
        //svc disabled
        this.setState(
          {
            feeSettings: {
              InvoiceDiscount: false,
              InvoiceTax: false,
              DiscountFeeName: '',
            },
          },
          () => {}
        );
      } else {
        this.setState(
          {
            feeSettings: {
              ...(res.payload.userData.data ? res.payload.userData.data.response : {}),
            },
          },
          () => {
            this.setState({ isLoading: false, DiscountFeeName: this.state.feeSettings?.DiscountFeeName ?? 'Svc Fee' });
          }
        );
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { adminUserDetail } = props;
    if (
      adminUserDetail &&
      adminUserDetail.companyId &&
      adminUserDetail?.companyId.customFields &&
      adminUserDetail.companyId.customFields.length > 0 &&
      adminUserDetail?.companyId.customFieldsEnabled
    ) {
      const enabledCustomField = adminUserDetail.companyId.customFields.find((customField) => customField.enabled);
      if (enabledCustomField && state.invoiceLable != enabledCustomField.value) {
        return { invoiceLable: enabledCustomField.value };
      }
    }
    return null;
  }

  setLocalTimeZone() {
    const { defaultLocation } = this.props.adminUserDetail;
    const { locationIds } = this.props.adminUserDetail;

    if (Object.keys(locationIds).length) {
      locationIds.forEach((location) => {
        if (location._id === defaultLocation) {
          this.setState({ localTimeZone: location.localTimeZone });
        }
      });
    }
  }

  getInvoiceData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.InvoiceDetailById(params).then((res) => {
      this.setState({ ...res.payload.paymentData.data.response, isLoading: false }, () => {});
    });
  }

  handleChangeTab(e, val) {
    this.setState({ tabValue: val });
  }

  handleDateChange = (date, key) => {
    this.setState({ [key]: date });
  };

  calculateSubTotal = () => {
    if (this.state.baseAmount) {
      return formatCurrency(this.state.baseAmount);
    } else if (this.state.InvoiceDiscount && !this.state.isSelfPay) {
      return formatCurrency(this.state.total);
    } else {
      return formatCurrency(this.state.amount);
    }
  };

  isSurchargeApplied = () => {
    return (
      this.state.transactions.surcharge &&
      this.state.transactions.surcharge !== '0' &&
      this.state.creditOrDebit === 'CREDIT' &&
      !this.state.CASH
    );
  };

  surchargeFieldName = () => {
    return this.state.InvoiceSurchargeFeeName ? this.state.InvoiceSurchargeFeeName : 'Surcharge';
  };

  surchargeAmount = () => {
    return formatCurrency(this.state.transactions.surcharge || 0);
  };

  invoiceOrJobId = () => {
    return this.state.invoice || this.state.jobId;
  };

  isCardNumberAvailable = () => {
    if (this.state.transactions?.MASKCARDNUMBER) {
      return this.state.transactions?.CARDTYPE && this.state.transactions?.creditOrDebit;
    } else {
      return false;
    }
  };

  isCardTypeAvailable = () => {
    if (this.state.transactions?.CARDTYPE) {
      return this.state.transactions?.CARDTYPE && this.state.transactions?.creditOrDebit;
    } else {
      return false;
    }
  };

  isDualFeeEnabled = () => {
    return this.state.feeSettings?.DualFeeEnabled;
  };

  isProductsAvailable = () => {
    return this.state.Products && this.state.Products.length > 0;
  };

  customInvoiceName = () => {
    if (this.state.locationId?.customFields?.length > 0) {
      const customField = this.state.locationId?.customFields[0];
      return customField.enabled ? customField.value : 'Invoice Number';
    } else {
      return 'Invoice Number';
    }
  };

  handlePrint = () => {
    this.setState(
      {
        ...this.state,
        name:
          this.state.customerId?.firstName?.charAt(0).toUpperCase() +
          this.state.customerId?.firstName?.slice(1) +
          ' ' +
          this.state?.customerId?.lastName?.charAt(0).toUpperCase() +
          this.state?.customerId?.lastName?.slice(1),
      },
      () => {
        if (this.state.isCustomerUser) {
          const printData = {
            ...this.state,
            companyLogoUrl: this.state.paytraceURL + (this.state?.locationId?.image || 'titanium-logo.png'),
            subTotal: this.calculateSubTotal(),
            isSurchargeApplied: this.isSurchargeApplied(),
            surchargeFieldName: this.surchargeFieldName(),
            invoiceOrJobId: this.invoiceOrJobId(),
            isCardNumberAvailable: this.isCardNumberAvailable(),
            maskedCardNumber: this.state.transactions?.MASKCARDNUMBER,
            cardType: this.state.transactions?.CARDTYPE,
            isCardTypeAvailable: this.isCardTypeAvailable(),
            surchargeAmount: this.surchargeAmount(),
            isDualFeeEnabled: this.isDualFeeEnabled(),
            isProductsAvailable: this.isProductsAvailable(),
            customInvoiceName: this.customInvoiceName(),
          };
          PrintReceipt(printData);
        } else {
          PrintInvoice(this.state);
        }

        return;
      }
    );
  };

  render() {
    const { classes, t } = this.props;
    const { transactions } = this.state;
    if (transactions !== '') {
      var refundNotes = Object.prototype.hasOwnProperty.call(transactions, 'REFUNDED') ? transactions.REFUNDED : [];
    }
    const renderInvoiceStatus = (status = 'Pending') => {
      if (this.state?.submitedToPax) {
        return (
          <>
            Invoice sent to PAX terminal. <br />
            Status: {status}
          </>
        );
      } else {
        return <>{status}</>;
      }
    };
    const getPaymentTypeValue = () => {
      let paymentType = 'N/A';

      if (this.state.CASH) {
        paymentType = 'Cash';
      }
      if (
        this.state?.creditOrDebit !== null &&
        !this.state?.submitedToPax &&
        typeof this.state.creditOrDebit === 'string'
      ) {
        paymentType = _.capitalize(this.state?.creditOrDebit);
      }
      if (this.state?.submitedToPax && _.lowerCase(this.state.STATUS) === 'pending') {
        return paymentType;
      }
      return paymentType;
    };

    const getFirstNameFieldValue = () => {
      let firstName = '';
      if (this.state?.customerId) {
        firstName = this.state.customerId.firstName;
      }
      if (this.state?.submitedToPax && this.state?.oneTimeCustomerDetails) {
        firstName = this.state.oneTimeCustomerDetails.firstName;
      }
      return firstName;
    };
    const getLastNameFieldValue = () => {
      let lastName = '';
      if (this.state?.customerId) {
        lastName = this.state.customerId.lastName;
      }
      if (this.state?.submitedToPax && this.state?.oneTimeCustomerDetails) {
        lastName = this.state.oneTimeCustomerDetails.lastName;
      }
      return lastName;
    };
    const getEmailFieldValue = () => {
      let email = '';
      if (this.state?.customerId) {
        email = this.state.customerId.email;
      }
      if (this.state?.submitedToPax && this.state?.oneTimeCustomerDetails) {
        email = this.state.oneTimeCustomerDetails.email;
      }
      return email;
    };
    return (
      <div className="rightPanel">
        <Topheader />
        <LoadingOverlay
          className={classes.tpTableFullW}
          active={this.state.isLoading}
          spinner
          text="Loading your content..."
        >
          <Container component="main" maxWidth="xl" className="desktopPage">
            {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
            {this.state.successMessages ? (
              <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} />
            ) : (
              ''
            )}

            <form className={classes.form} noValidate ref={this.myRef}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} sm={12} md={12}> */}
                <Grid item xs={12} sm={12} md={12}>
                  {this.state.STATUS === 'PAID' ? (
                    <Alert severity="success">Paid invoice details.</Alert>
                  ) : this.state.STATUS === 'UNPAID' ? (
                    <Alert severity="warning">Un-Paid invoice details.</Alert>
                  ) : (
                    renderInvoiceStatus(this.state.STATUS)
                  )}
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="invoice"
                    type="text"
                    labels={t(`${this.state.invoiceLable ?? 'Invoice'}`)}
                    value={this.state.jobId ?? this.state.invoice}
                    width={true}
                    // onChange={(e) => this.handleChange(e, t)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    error={this.state.error.amountError}
                    id="amount"
                    type="text"
                    labels={t('Amount')}
                    value={formatCurrency(this.state.total).replace('$', '')}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="firstName"
                    type="text"
                    labels={t('FirstName')}
                    value={getFirstNameFieldValue()}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="lastName"
                    type="text"
                    labels={t('LastName')}
                    value={getLastNameFieldValue()}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="email"
                    type="text"
                    labels={t('Email')}
                    value={getEmailFieldValue()}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    variant="outlined"
                  />
                </Grid>
                {this.state.cardType && (
                  <Grid item xs={6} sm={6} md={6}>
                    <Textfield
                      disabled={true}
                      id="cardType"
                      type="text"
                      labels={t('CardType')}
                      value={this.state.cardType}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="creditOrDebit"
                    type="text"
                    labels={t('Payment Type')}
                    value={getPaymentTypeValue()}
                    width={true}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="public_notes"
                    type="text"
                    labels={t('PublicNotes')}
                    value={
                      refundNotes !== undefined
                        ? refundNotes
                            .map(
                              (item) =>
                                item.REFUND_BY.firstName +
                                '   ' +
                                item.REFUND_BY.lastName +
                                '   ' +
                                userRole(item.REFUND_BY.role) +
                                '   ' +
                                new Date(item.REFUND_DATE).toLocaleDateString() +
                                '   $' +
                                parseFloat(item.amt).toFixed(2)
                            )
                            .join('\n') +
                          '\n' +
                          (this.state.publicNotes !== undefined ? this.state.publicNotes : ' ')
                        : ' '
                    }
                    width={true}
                    multiline={true}
                  />
                </Grid>
                {!this.state.isCustomerUser && (
                  <Grid item xs={6} sm={6} md={6}>
                    <Textfield
                      disabled={true}
                      id="private_notes"
                      type="text"
                      labels={t('PrivateNotes')}
                      value={
                        refundNotes !== undefined
                          ? refundNotes
                              .map(
                                (item) =>
                                  item.REFUND_BY.firstName +
                                  '   ' +
                                  item.REFUND_BY.lastName +
                                  '   ' +
                                  userRole(item.REFUND_BY.role) +
                                  '   ' +
                                  new Date(item.REFUND_DATE).toLocaleDateString() +
                                  '   $' +
                                  parseFloat(item.amt).toFixed(2)
                              )
                              .join('\n') +
                            '\n' +
                            (this.state.privateNotes !== undefined ? this.state.privateNotes : ' ')
                          : ' '
                      }
                      width={true}
                      multiline={true}
                    />
                  </Grid>
                )}
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="invoiceDate"
                    type="text"
                    labels={t('InvoiceDate')}
                    value={
                      convertDateToTimeZoneFormat(this.state.invoiceDate, this.state.localTimeZone) ||
                      this.state.invoiceDate
                    }
                    width={true}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Textfield
                    disabled={true}
                    id="timestamp_PAID"
                    type="text"
                    labels={t('PaidDate')}
                    value={
                      convertDateToTimeZoneFormat(this.state.timestamp_PAID, this.state.localTimeZone) ||
                      this.state.timestamp_PAID
                    }
                    width={true}
                  />
                </Grid>
                <Grid>
                  <Buttons
                    // className={classesI.ml_2}
                    className={'emailInvoiceTerminal'}
                    variant="contained"
                    color="secondary"
                    text={t('Back')}
                    onClick={this.props.history.goBack}
                  />
                  <Buttons
                    variant="contained"
                    color="primary"
                    className={'emailInvoiceTerminal'}
                    onClick={this.handlePrint.bind(this)}
                    text="Print"
                    startIcon={<PrintIcon style={{ color: 'white' }} className={'printInvoice'} />}
                  />
                </Grid>
                {/* </Grid> */}
              </Grid>
            </form>
          </Container>
        </LoadingOverlay>
      </div>
    );
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return <EditInvoice theme={theme} classes={classes} {...props} />;
};

EditInvoice.propTypes = {
  theme: PropTypes.any,
  match: PropTypes.any,
  InvoiceDetailById: PropTypes.any,
  info: PropTypes.any,
  classes: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.any,
  adminUserDetail: PropTypes.any,
  settingDataByCompanyId: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.InvoiceReducer,
    adminUserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { settingDataByCompanyId, addInvoice, customerList, InvoiceDetailById })(
  withTranslation()(ApplyingStyles)
);
