import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import config from '../../constants/config';
import TypableSelect from '../../helpers/TypableSelect';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Textfield } from './../../components/Textfield';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import classes from './Company.module.scss';
import PropTypes from 'prop-types';

export default class LocationForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      paytraceEnabled: true,
      multipassEnabled: false,
    });
  }
  renderGatewayUi() {
    const { t } = this.props;
    if (this.props.state?.gateway == 'paytrace') {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            {' '}
            <h3>Paytrace Api Credentials</h3>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Textfield
              required={true}
              error={this.props.state.error.paytraceUserNameError}
              id="paytraceUserName"
              type="text"
              labels={t('UserName')}
              value={this.props.state.paytraceUserName}
              width={true}
              onChange={(e) => this.props.handleChange(e)}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Textfield
              required={true}
              error={this.props.state.error.paytracePasswordError}
              id="paytracePassword"
              type="text"
              labels={t('Password')}
              value={this.props.state.paytracePassword}
              width={true}
              onChange={(e) => this.props.handleChange(e)}
            />
          </Grid>
          <Grid item xs={6} sm={3} style={{ marginTop: '1.5rem' }}>
            <label>
              Paytrace key file
              {this.props.state.paytraceKey ? (
                <img
                  alt="enable"
                  className={classes.rightImg}
                  src={config.APPURL + '/assets/images/checkmark-24.png'}
                />
              ) : (
                ''
              )}
            </label>
            <input type="file" name="paytraceKey" onChange={(e) => this.props.uploadKey(e, t)} />
          </Grid>
        </React.Fragment>
      );
    }

    if (this.props.state?.gateway == 'multipass') {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            {' '}
            <h3>Multipass Credentials</h3>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Textfield
              required={true}
              error={this.props.state.error.multipassUserNameError}
              id="multipassUserId"
              type="text"
              labels={t('User ID')}
              value={this.props.state.multipassUserId}
              width={true}
              onChange={(e) => this.props.handleChange(e)}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <Textfield
              required={true}
              error={this.props.state.error.multipassPasswordError}
              id="multipassPassword"
              type="text"
              labels={t('Password')}
              value={this.props.state.multipassPassword}
              width={true}
              onChange={(e) => this.props.handleChange(e)}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Textfield
              required={true}
              error={this.props.state.error.paytracePasswordError}
              id="multipassmerchantId"
              type="text"
              labels={t('Multipass Merchant ID')}
              value={this.props.state.multipassmerchantId}
              width={true}
              onChange={(e) => this.props.handleChange(e)}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <Textfield
              required={true}
              error={this.props.state.error.multipassDeviceIdError}
              id="multipassDeviceId"
              type="text"
              labels={t('Multipass Device ID')}
              value={this.props.state.multipassDeviceId}
              width={true}
              onChange={(e) => this.props.handleChange(e)}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <Textfield
              required={true}
              error={this.props.state.error.multipassTSEPId}
              id="multipassTSEPId"
              type="text"
              labels={t('TSEP Device ID')}
              value={this.props.state.multipassTSEPId}
              width={true}
              onChange={(e) => this.props.handleChange(e)}
            />
          </Grid>
        </React.Fragment>
      );
    }
  }
  handleGatewayChange(event) {
    console.log('event', event.target);
    console.log('event', event.target.value);
    if (event.target.value == 'paytrace') {
      this.props.selectGateway('paytrace');
    }

    if (event.target.value == 'multipass') {
      this.setState({ selectedGateway: 'multipass' });
      this.props.selectGateway('multipass');
    }
  }
  render() {
    const filter = createFilterOptions();
    const { t } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Textfield
            required={true}
            error={this.props.state.error.locationNameError}
            id="locationName"
            type="text"
            labels={t('LocationName')}
            value={this.props.state.locationName}
            width={true}
            onChange={(e) => this.props.handleChange(e)}
            focus={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Textfield
            required={true}
            error={this.props.state.error.contactNameError}
            id="contactName"
            type="text"
            labels={t('ContactName')}
            value={this.props.state.contactName}
            width={true}
            onChange={(e) => this.props.handleChange(e)}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Textfield
            required={true}
            error={this.props.state.error.emailError}
            id="email"
            type="email"
            labels={t('Email')}
            value={this.props.state.email}
            width={true}
            onChange={(e) => this.props.handleChange(e)}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Textfield
            helperText="phone format xxx-xxx-xxxx"
            id="phone"
            type={'text'}
            pattern="[789][0-9]{9}"
            labels={t('Phone')}
            value={this.props.state.phone}
            width={true}
            onChange={(e) => this.props.handleChange(e)}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Textfield
            helperText="Unique mId"
            required={true}
            error={this.props.state.error.mIdError}
            id="mId"
            type={'text'}
            labels={t('mId')}
            value={this.props.state.mId}
            width={true}
            onChange={(e) => this.props.handleChange(e)}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Textfield
            helperText="Pax Location Id"
            error={this.props.state.error.mIdError}
            id="paxLocationId"
            type={'text'}
            disabled={true}
            value={this.props.state.paxLocationId}
            width={true}
          />
        </Grid>
        {/* f<Grid item xs={12}> <h3>Location info same as DBA</h3></Grid> */}
        <Grid item xs={12} sm={12}>
          <label>
            <Checkbox
              id="locationSameAsDba"
              labels="locationSameAsDba"
              checked={this.props.state.locationSameAsDba}
              onChange={(e) => {
                this.props.handleCheckboxDba(e);
              }}
              value={this.props.state.locationSameAsDba}
              color="primary"
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
            <span>Location info same as DBA</span>
          </label>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Textfield
            required={true}
            error={this.props.state.error.address1Error}
            id="address1"
            type="text"
            labels={t('Address1')}
            value={this.props.state.address1}
            width={true}
            onChange={(e) => this.props.handleChange(e)}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Textfield
            id="address2"
            type="text"
            labels={t('Address2')}
            value={this.props.state.address2}
            width={true}
            onChange={(e) => this.props.handleChange(e)}
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ marginTop: '4.1rem' }}>
          <FormControl
            fullWidth
            style={this.props.getValidationStyle(!this.props.state.country && this.props.state.isSubmit)}
          >
            <TypableSelect
              placeholder={'Select country name'}
              suggestions={this.props.mapToFormatCountry(this.props.Countrydata)}
              onChange={(val) => this.props.setCountryState(val)}
              isClearable={true}
              isSelected={this.props.state.country ? this.props.state.country : ''}
              // MultiValue={(e)=>{this.props.onCloseState(val)}}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} style={{ marginTop: '4.1rem' }}>
          <FormControl
            fullWidth
            style={this.props.getValidationStyle(!this.props.state.state && this.props.state.isSubmit)}
          >
            <TypableSelect
              placeholder={'Select state name'}
              suggestions={this.props.mapToFormatStates(this.props.state.stateList)}
              onChange={(val) => this.props.setStatesSet(val)}
              isClearable={true}
              isSelected={this.props.state.state ? this.props.state.state : ''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} style={{ marginTop: '2.3rem' }}>
          <FormControl
            fullWidth
            style={this.props.getValidationStyle(!this.props.state.city && this.props.state.isSubmit)}
          >
            <Autocomplete
              value={this.props.state.city}
              onChange={(e, value) => this.props.setCitySet(e, value)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="city"
              options={this.props.mapToFormatCity(this.props.state.cityList)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(option) => option.title}
              freeSolo
              renderInput={(params) => <TextField {...params} label="city" />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} style={{ marginTop: '4.1rem' }}>
          <FormControl fullWidth>
            <TypableSelect
              id="localTimezone"
              placeholder={'Select Timezone'}
              isSelected={this.props.state.localTimeZoneLabel || ''}
              suggestions={this.props.maptoFormatTimezones(this.props.state.timezones)}
              labels={t('Time Zone')}
              onChange={(evt) => this.props.handleTimeZoneChange(evt)}
              isClearable={true}
              width={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Textfield
            required={true}
            error={this.props.state.error.zipError}
            id="zip"
            type="text"
            labels={t('Zip')}
            value={this.props.state.zip}
            width={true}
            onChange={(e) => this.props.handleChange(e)}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Grid item xs={6} sm={6}>
            <label>
              Company Logo
              <input type="file" name="file" onChange={(e) => this.props.onChangeHandler(e, t)} />
            </label>
            {this.props.state.logoError.length > 0 ? <ErrorMessage errors={[this.props.state.logoError]} /> : ''}
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className={this.props.state.preview ? classes.show : classes.hide}>
              {this.props.state.selectedFile ? (
                <img alt="preview" className={classes.preview} src={this.props.state.selectedFile} />
              ) : (
                ''
              )}
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            {this.props.state.imageUrl ? (
              <img alt="profile" src={this.props.state.imageUrl} className={classes.preview} />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {' '}
          <h3>Select Gateway</h3>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="paytrace"
            name="radio-buttons-group"
            value={this.props?.state?.gateway ?? 'paytrace'}
            onChange={this.handleGatewayChange.bind(this)}
          >
            <FormControlLabel value="paytrace" control={<Radio />} label="Paytrace" />
            <FormControlLabel value="multipass" control={<Radio />} label="Multipass" />
          </RadioGroup>
        </Grid>
        {this.renderGatewayUi()}
        <Grid item xs={6} sm={3}></Grid>
        <Grid item xs={6} sm={3}>
          <Textfield
            required={true}
            error={this.props.state.error.merchantIdError}
            id="merchantId"
            type="text"
            labels={t('TitaniumMerchantId')}
            value={this.props.state.mId}
            width={true}
            onChange={(e) => this.props.handleChange(e)}
            disabled={true}
          />
        </Grid>

        <Grid item xs={6} sm={6} style={{ marginTop: '1.3rem' }}>
          <Textfield
            id="notes"
            type="text"
            labels={t('Notes')}
            value={this.props.state.notes}
            width={true}
            rowsMax={'2'}
            multiline={true}
            onChange={(e) => this.props.handleChange(e)}
          />
        </Grid>
        <Grid item xs={6} sm={3}></Grid>
        <Grid item xs={12} sm={6}>
          <Buttons
            id="merchantForm"
            type="button"
            variant="contained"
            color="info"
            onClick={(e) => this.props.handleSaveAndContinue(e)}
            text={t('Save And Continue')}
          />
          <Buttons
            className={classes.ml_2}
            variant="contained"
            color="secondary"
            text={t('Back')}
            onClick={(e) => this.props.goBack(e)}
          />
        </Grid>
      </Grid>
    );
  }
}

LocationForm.propTypes = {
  props: PropTypes.any,
  t: PropTypes.any,
  state: PropTypes.any,
  handleChange: PropTypes.any,
  handleCheckboxDba: PropTypes.any,
  getValidationStyle: PropTypes.any,
  Countrydata: PropTypes.any,
  setCountryState: PropTypes.any,
  mapToFormatStates: PropTypes.any,
  mapToFormatCity: PropTypes.any,
  goBack: PropTypes.any,
  handleSaveAndContinue: PropTypes.any,
  uploadKey: PropTypes.any,
  onChangeHandler: PropTypes.any,
  mapToFormatCountry: PropTypes.any,
  setCitySet: PropTypes.any,
  setStatesSet: PropTypes.any,
  maptoFormatTimezones: PropTypes.any,
  handleTimeZoneChange: PropTypes.any,
  selectGateway: PropTypes.any,
};
