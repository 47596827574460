import { config } from '../constants';
import { doesFileExist } from './commonFunction';

export const configurePaytracePermFile = (locationDetail = null) => {
  let locationPaydetails = locationDetail || JSON.parse(localStorage.getItem('locationPaydetails'));

  const localUrl = config.BASEURL + 'getPemFile?fileName=' + locationPaydetails.paytraceKey;
  let file = doesFileExist(localUrl);
  if (window.paytrace && file) {
    window.paytrace.setKeyAjax(localUrl);
    return true;
  } else {
    return false;
  }
};
