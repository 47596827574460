import { ActionType } from './../constants';

const initialState = {
  signin: [],
  currentLocation: {},
  loading: false,
  error: null,
  msg: null,
  tsepAuth: false,
};

const LocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOCATION_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionType.LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locationInfo: action.payload.locationData,
      };

    case ActionType.LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        locationInfo: action.payload.error,
      };

    case ActionType.SET_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };

    case ActionType.SET_TSEP_AUTH:
      return {
        ...state,
        tsepAuth: action.payload,
      };

    case ActionType.SET_All_LOCATION:
      return {
        ...state,
        currentLocation: {},
      };
    case 'INITIALIZE_STATE':
      return initialState;
    default:
      return state;
  }
};

export { LocationReducer };
