import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { setLocation, setAllLocation, setTsepAuth } from '../../actions/LocationAction';
import { connect } from 'react-redux';
import { updateDefaultLocation } from '../../actions/AdminUserAction';
import { loadTsepManifestScript } from '../../helpers/tsepUtils';
// import { companyTermsConditions } from '../../actions/TermConditionsAction';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100px',
    textAlign: 'right',
    maxWidth: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.background.paper,
    '@media(max-width: 768px)': {
      minWidth: '50px',
      maxWidth: '150px',
    },
  },
  Mupadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  Mumargin: {
    marginTop: 0,
    marginBottom: 0,
    color: '#fff',
  },
  locationIcon: {
    marginTop: 0,
    color: '#fff',
    minWidth: '1rem',
  },
  locationIconBox: {
    backgroundColor: '#117CC1',
    color: '#fff',
    paddingTop: '0',
    paddingBottom: '0',
  },
}));

class LocationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selected: -1,
      anchorEl: null,
      sideShow: true,
      isLoading: true,
      allLocation: true,
      defaultLocation: null,
      userData: { permissions: {} },
      isMenueDisabled: true,
    };
  }

  handleClickListItem = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  /**
   * Handle update default location
   *
   * @param {Object} item
   */
  updateDefaultLocation = (item) => {
    let userData = JSON.parse(localStorage.getItem('user'));

    //add  routes which contains location specific data
    const routesToCheck = ['/EditInvoices', '/AddInvoices', '/CustomerDetails', '/Viewtransaction'];

    const shouldRedirectBack = routesToCheck.some((route) => window.location.pathname.includes(route));

    this.props
      .updateDefaultLocation({
        _id: userData._id,
        defaultLocation: item._id,
      })
      .then(
        (_res) => {
          if (shouldRedirectBack) {
            if (window.location.pathname.includes('/Viewtransaction')) {
              window.location.href = '/Transactions';
            }
            window.history.back();
          } else {
            window.location.reload();
          }
        },
        (_error) => {
          if (shouldRedirectBack) {
            window.history.back();
          } else {
            window.location.reload();
          }
        }
      );
  };

  handleMenuItemClick = (event, index, item) => {
    this.props.setLocation(item);
    localStorage.setItem('locationId', JSON.stringify(item._id));
    localStorage.setItem('locationPaydetails', JSON.stringify(item));
    let setArr = [item._id];
    localStorage.setItem('locationArrIds', JSON.stringify(setArr));
    localStorage.setItem('selected', index + 1);
    this.setState({ selected: index + 1 });
    // cookie.save('locationId', item._id)
    this.setState({ anchorEl: null });
    this.updateDefaultLocation(item);
  };

  setInitialLocation = () => {
    const payDetails = localStorage.getItem('locationPaydetails');

    if (
      payDetails === undefined ||
      payDetails === 'undefined' ||
      payDetails === null ||
      localStorage.getItem('locationArrIds') === null
    ) {
      let locationIds = this.props.location.map((curr) => {
        return curr._id;
      });
      let locationPaydetails = this.props.location.filter((curr) => {
        return curr._id == locationIds[0];
      });
      localStorage.setItem('locationPaydetails', JSON.stringify(locationPaydetails[0]));
      localStorage.setItem('locationArrIds', JSON.stringify(locationIds));
    }
  };

  async componentDidMount() {
    let userData = JSON.parse(localStorage.getItem('user'));
    // await this.props.companyTermsConditions(userData.company.id, userData?.defaultLocation);

    this.setInitialLocation();

    this.setState({ userData: this.props.UserDetail.userData });

    let defaultLocation = userData?.data?.defaultLocation;
    if (!defaultLocation && this.props.location.length > 1) {
      console.log('default location not set');
      let selectedLocation = JSON.parse(localStorage.getItem('locationPaydetails'));
      this.setState({ defaultLocation: selectedLocation.id });
    } else {
      localStorage.setItem('selected', -1);
      this.setState({ selected: -1 });
      if (this.props.location.length == 1) {
        this.setState({ defaultLocation: this.props.location[0]._id });
      }
    }

    setTimeout(() => {
      if (defaultLocation !== null) {
        let locationIds = this.props.location.filter((curr) => {
          return curr._id == this.state.defaultLocation;
        });
        if (locationIds && locationIds.length > 0) {
          localStorage.setItem('locationPaydetails', JSON.stringify(locationIds[0]));
          locationIds = locationIds.map((curr) => {
            return curr._id;
          });
          if (localStorage.getItem('selected') !== '0' || localStorage.getItem('selected') === undefined) {
            localStorage.setItem('locationArrIds', JSON.stringify(locationIds));
          }
        }

        this.setState({ isMenueDisabled: false });
      }
    }, 2000);
    let selectedLocation = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (selectedLocation.gateway === 'multipass' && selectedLocation.multipassCredentials) {
      this.handleTsepManifestUpdate();
    } else {
      this.props.setTsepAuth(false);
    }
  }

  handleTsepManifestUpdate = () => {
    loadTsepManifestScript().then((auth) => {
      this.props.setTsepAuth(auth);
    });
  };

  static getDerivedStateFromProps(nextProps, state) {
    const {
      UserDetail: { defaultLocation: newDefaultLocation, userId: userId },
    } = nextProps;
    if (newDefaultLocation && newDefaultLocation !== state.defaultLocation) {
      const locationIds = nextProps.location.filter((curr) => {
        return curr._id == newDefaultLocation;
      });
      const isExist = locationIds.length > 0 ? true : false;
      if (isExist) {
        return {
          ...state,
          defaultLocation: newDefaultLocation,
        };
      } else {
        nextProps.updateDefaultLocation({
          _id: userId,
          defaultLocation: nextProps.location[0]._id,
        });
        return {
          ...state,
          defaultLocation: nextProps.location[0]._id,
        };
      }
    }
    return null;
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleAllMenu = () => {
    this.props.setAllLocation();
    localStorage.setItem('selected', 0);
    this.setState({ selected: 0 });
    this.setState({ anchorEl: null });
    let locationIds = this.props.location.reduce((acc, curr) => {
      acc.push(curr._id);
      return acc;
    }, []);
    localStorage.setItem('locationArrIds', JSON.stringify(locationIds));
    localStorage.removeItem('locationPaydetails');
    this.updateDefaultLocation({ _id: null });
  };
  render() {
    const selected = JSON.parse(localStorage.getItem('selected'));
    let showAllLocation = false;
    if (Object.hasOwn(this.state.userData, 'permissions')) {
      showAllLocation = this.props.location.length > 1 && this.state.userData.permissions?.allLocaton;
    } else {
      let user = JSON.parse(localStorage.getItem('user'));
      showAllLocation = this.props.location.length > 1 && user.permissions?.allLocaton;
    }
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <List className={classes.locationIconBox} component="nav" aria-label="Device settings">
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="location"
            disabled={this.state.isMenueDisabled}
            onClick={(e) => this.handleClickListItem(e)}
          >
            <ListItemIcon className={classes.locationIcon}>
              <LocationOnIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText
              className={classes.Mumargin}
              primary={
                selected != 0
                  ? this.props.location.map((location) => {
                      if (location._id === this.state.defaultLocation) {
                        return location.locationName.length > 32
                          ? location.locationName.substr(0, 32) + '...'
                          : location.locationName;
                      } else {
                        return '';
                      }
                    })
                  : showAllLocation
                  ? 'All Location'
                  : selected === 0
                  ? this.props.location[0].locationName
                  : ''
              }
            />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={(e) => this.handleClose(e)}
        >
          {showAllLocation && (
            <MenuItem selected={selected == 0} onClick={(e) => this.handleAllMenu(e)}>
              <ListItemIcon>
                <LocationOnIcon fontSize="small" />
              </ListItemIcon>{' '}
              All location{' '}
            </MenuItem>
          )}
          {this.props.location.map((item, index) => {
            if (!item.isDeleted) {
              return (
                <MenuItem
                  key={item._id}
                  selected={item._id === this.state.defaultLocation && selected !== 0}
                  onClick={(event) => this.handleMenuItemClick(event, index, item)}
                >
                  <ListItemIcon>
                    <LocationOnIcon fontSize="small" />
                  </ListItemIcon>
                  {item.locationName}
                </MenuItem>
              );
            }
          })}
        </Menu>
      </div>
    );
  }
}

LocationBar.propTypes = {
  updateDefaultLocation: PropTypes.func,
  setLocation: PropTypes.func,
  setAllLocation: PropTypes.func,
  setTsepAuth: PropTypes.func,
  location: PropTypes.any,
  classes: PropTypes.object,
  // companyTermsConditions: PropTypes.func,
  UserDetail: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    UserDetail: state.AdminUserReducer,
  };
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <LocationBar classes={classes} {...props} />;
};

export default connect(mapStateToProps, {
  setLocation,
  setTsepAuth,
  setAllLocation,
  updateDefaultLocation /* companyTermsConditions */,
})(ApplyingStylesOnClasses);
