import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import classes from './ForgotPassword.module.scss';
import { CheckEmail } from './';
import { ResetPassword } from './';
import { withRouter } from 'react-router';
import { fetchHashDetails } from '../../actions/RequestFormAction';
import { connect } from 'react-redux';
import queryString from 'query-string';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetPage: false,
      email: '',
      userName: '',
    };
  }
  showResetPassword(e, Email) {
    this.setState({ showResetPage: e, email: Email });
  }
  UNSAFE_componentWillMount() {
    let token = queryString.parse(this.props.location.hash);

    if (token) {
      this.props.fetchHashDetails(token.token);
    }
  }

  render() {
    const { t } = this.props;
    const { email, updatedAt, userName } = this.props.user;
    let lastUpdate = new Date(updatedAt);
    let currentDateTime = new Date();
    let TimeOutdiff = Math.round(((currentDateTime - (lastUpdate % 86400000)) % 3600000) / 60000);

    return (
      <div className={classes.forgotPassword}>
        <Container component="main" className={classes.max_Width}>
          <div className={classes.paper}>
            {email ? (
              <ResetPassword email={email} userName={userName} TimeOutdiff={TimeOutdiff} />
            ) : (
              <CheckEmail onChange={(e, Email) => this.showResetPassword(e, Email)} />
            )}
            <Grid container>
              <Grid item xs className={classes.text_right}>
                <Link to="/Login" variant="body2">
                  {t('Back')}
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  t: PropTypes.func,
  location: PropTypes.any,
  user: PropTypes.any,
  fetchHashDetails: PropTypes.func,
};
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { fetchHashDetails })(withTranslation()(withRouter(ForgotPassword)));
