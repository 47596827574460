const getCardType = (val) => {
  let number = Number(val);
  switch (number) {
    case 4:
      return 'Visa';
    case 5:
      return 'MasterCard';
    case 3:
      return 'American Express';
    case 6:
      return 'Discover Card';
    default:
      return 'ACH';
  }
};

export default getCardType;
