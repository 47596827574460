import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  left: { textAlign: 'left' },
  center: { textAlign: 'center' },
  right: { textAlign: 'right' },
};

const totalCalculation = (data) => {
  const convenienceFee = ((data.virtualTerminalSettings.InvoiceSVCFee / 100) * data.amount).toFixed(2);
  const salesTaxAmount = ((data.virtualTerminalSettings.InvoiceSalesTaxFee / 100) * data.amount).toFixed(2);
  const totalAmountDue = (data.amount + parseFloat(convenienceFee) + parseFloat(salesTaxAmount)).toFixed(2);
  return totalAmountDue;
};

export const PaymentInfo = ({ style, data }) => (
  <div className="payment-info" style={style}>
    <span style={styles.left}>
      {' '}
      Hi <strong> {data.firstName}, </strong>{' '}
    </span>{' '}
    <br /> <br />
    <span style={styles.center}>
      Your truck and rate are reserved for the time above. <br /> <br />
      Please note other customers also need our assistance, and your rate may change once the time expires. <br />
    </span>{' '}
    <br />
    <div style={styles.right}>
      <strong style={styles.right}>Subtotal: ${parseFloat(data.amount).toFixed(2)}</strong> <br />
      <strong style={styles.right}>
        Convenience Fee: ${parseFloat((data.virtualTerminalSettings.InvoiceSVCFee / 100) * data.amount).toFixed(2)}
      </strong>{' '}
      <br />
      <strong style={styles.right}>
        Sales Tax: ${parseFloat((data.virtualTerminalSettings.InvoiceSalesTaxFee / 100) * data.amount).toFixed(2)}
      </strong>{' '}
      <br />
      <strong>Total Amount Due: ${totalCalculation(data)}</strong>
    </div>
  </div>
);

PaymentInfo.propTypes = {
  style: PropTypes.object,
  data: PropTypes.object,
};

export default PaymentInfo;
