import React, { Component } from 'react';
import PropTypes from 'prop-types';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: 'Please try after some time' };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(_error, _errorInfo) {
    // this.setState({'error':errorInfo})
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          {' '}
          <h1>An error has occurred. Please refresh the page.</h1>
          <details>{this.state.error}</details>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};
export default ErrorBoundary;
