import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
// import { userSettings } from '../../actions/AdminUserAction';
import { Grid } from '@material-ui/core';
import Fee from './../Settings/components/Fee';
import { Close } from '@mui/icons-material';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const AdjustSettings = ({ handleClose, open, settings, handleFeeSettingsType, handleFeeSettingsValue, svcEdit }) => {
  const { virtualTerminalSettings } = settings;

  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      keepMounted
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Grid container spacing={3}>
          {virtualTerminalSettings && (
            <Grid item xs={12} sm={12} md={12} className="vt-settings-container">
              <Close style={{ cursor: 'pointer', position: 'absolute', top: 20, right: 20 }} onClick={handleClose} />
              <div className="vt-settings-inner">
                <h3 className="settings-heading">Fee Settings</h3>
                <p>These fee setting won't be applied for paylink</p>
                <Divider />
                <Grid container spacing={3}>
                  {virtualTerminalSettings && virtualTerminalSettings.InvoiceSurcharge && (
                    <Grid item xs={6} sm={10} md={6} className="vt-settings-container">
                      <div className="vt-settings-inner">
                        <Grid container spacing={3}>
                          <Fee
                            nameLabel="Fee Name"
                            // error={this.state.error.InvoiceSurchargeFeeNameError}
                            id="virtualTerminalSettings.InvoiceSurchargeFeeName"
                            nameValue="Surcharge Fee"
                            // onNameChange={(e) => this.handleFeeNameChange(e)}
                            typeLabel="Fee Type"
                            feeInputType="number"
                            typeValue={virtualTerminalSettings?.InvoiceSurchargeFeeType}
                            onTypeChange={(e) => {
                              handleFeeSettingsType(e, 'InvoiceSurchargeFeeType');
                            }}
                            // valueError={this.state.error.InvoiceSurchargeFeeError}
                            valueID="virtualTerminalSettings.InvoiceSurchargeFee"
                            valueLabel="Fee"
                            amountValue={virtualTerminalSettings?.InvoiceSurchargeFee}
                            onValueChange={(e) => handleFeeSettingsValue(e, 'InvoiceSurchargeFee')}
                            disabled={true}
                          />
                        </Grid>
                      </div>
                    </Grid>
                  )}
                  {svcEdit && (
                    <Grid item xs={6} sm={10} md={6} className="vt-settings-container">
                      <div className="vt-settings-inner">
                        <Grid container spacing={3}>
                          <Fee
                            nameLabel="Fee Name"
                            // error={this.state.error.InvoiceSurchargeFeeNameError}
                            id="virtualTerminalSettings.DiscountFeeName"
                            nameValue="Svc Fee"
                            // onNameChange={(e) => this.handleFeeNameChange(e)}
                            typeLabel="Fee Type"
                            typeValue={virtualTerminalSettings?.InvoiceSVCFeeType}
                            onTypeChange={(e) => {
                              handleFeeSettingsType(e, 'InvoiceSVCFeeType');
                            }}
                            feeInputType="number"
                            // valueError={this.state.error.InvoiceSurchargeFeeError}
                            valueID="virtualTerminalSettings.InvoiceSVCFee"
                            valueLabel="Fee"
                            amountValue={virtualTerminalSettings && virtualTerminalSettings.InvoiceSVCFee}
                            onValueChange={(e) => handleFeeSettingsValue(e, 'InvoiceSVCFee')}
                            disabled={true}
                          />
                        </Grid>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AdjustSettings;

AdjustSettings.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  settings: PropTypes.any,
  handleFeeSettingsType: PropTypes.func,
  handleFeeSettingsValue: PropTypes.func,
  svcEdit: PropTypes.any,
};
