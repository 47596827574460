import { ActionType } from '../constants';

const initialState = {
  loading: false,
  error: null,
  data: {},
};

const EnvironmentVariablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.ENV_VAR_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionType.ENV_VAR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };

    case ActionType.ENV_VAR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export { EnvironmentVariablesReducer };
