import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

export const RenderMenuItem = (DATA) =>
  DATA.map((value, key) => (
    //	console.log('value, key'+value+ key)
    <MenuItem value={value} key={key}>
      {' '}
      {value}{' '}
    </MenuItem>
  ));
