import PropTypes from 'prop-types';
import queryString from 'query-string';
import { CustomerUserDetailByLink, CustomerUpdateUserDetailByLink } from '../../actions/AdminUserAction';
import classes from './User.module.scss';
import { Buttons } from '../../components/Buttons';
import { config, message } from '../../constants';
import LoadingOverlay from 'react-loading-overlay';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getEnvironmentVariablesList } from '../../actions/EnvironmentVariablesAction';
const axios = require('axios');

const ERROR_MESSAGE = 'Either the link has been expired or there is Something is wrong, Kindly contact admin.';

CustomerCreation.propTypes = {
  location: PropTypes.object,
};

ErrorDisplay.propTypes = {
  hasError: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.string,
};

const styles = {
  imageStyle: { maxWidth: '95%' },
};

function ErrorDisplay({ hasError, className, message }) {
  return hasError ? <div className={className}>{message}</div> : '';
}

export default function CustomerCreation({ location }) {
  const dispatch = useDispatch();
  const EnvironmentVariablesReducer = useSelector((state) => state.EnvironmentVariablesReducer);
  const [paytraceAwsPath, setPaytraceAwsPath] = useState(null);
  const [link, setLink] = useState('');
  const [activateOnly, setActivateOnly] = useState(false);
  const [data, setData] = useState({
    location: { image: false },
    company: { image: false },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [timeOutdiff, setTimeOutdiff] = useState();
  const [isError, setIsError] = useState({
    error: false,
    errorMessage: '',
  });
  const [isMessage, setIsMessage] = useState({
    messageAvailable: false,
    message: '',
    type: 'success',
  });
  const [companyImageUrl, setCompanyImageUrl] = useState(paytraceAwsPath + 'titanium-logo.png');
  const [isUserCreated, setIsUserCreated] = useState(false);
  const { imageStyle } = styles;

  const validationSchema = Yup.object({
    username: Yup.string().min(3, 'Username must be at least 3 characters').required('Username is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const submit = async (values) => {
    values.hash = link;
    values.id = data?.id;
    values.activateOnly = activateOnly;
    console.log('Form data:', values);
    setIsLoading(true);
    const response = await CustomerUpdateUserDetailByLink(values);
    if (response.type === 'USER_SUCCESS') {
      console.log(response);
      setIsUserCreated(true);
      setIsMessage({
        messageAvailable: true,
        message: 'Your account has been successfully created!',
        type: 'success',
      });
      setIsLoading(false);
    }
    if (response.payload.error.data.success === false) {
      setIsMessage({
        messageAvailable: true,
        message: response.payload.error.data.message,
        type: 'error',
      });
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  const formikActivate = useFormik({
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: submit,
  });

  useEffect(() => {
    dispatch(getEnvironmentVariablesList());
    const params = queryString.parse(location.search);
    setLink(params.link);
    setIsLoading(true);
    CustomerUserDetailByLink(params)
      .then((res) => {
        if (res?.payload?.userData?.data?.success === true) {
          const response = res?.payload?.userData?.data?.response;
          if (response.isStaticActivationRequired) {
            setActivateOnly(true);
          }
          const lastUpdate = new Date(response.hashCreatedAt);
          const currentDateTime = new Date();
          const diffTime = currentDateTime - lastUpdate;
          setTimeOutdiff(Math.round(diffTime / 1000 / 60));
          setData({ ...response });
        }
        if (res.type === 'USER_ERROR') {
          setIsError({
            error: true,
            errorMessage: ERROR_MESSAGE,
          });
        }
        setIsLoading(false);
      })
      .catch((_err) => {
        setIsError({
          error: true,
          errorMessage: ERROR_MESSAGE,
        });
        console.log(_err);
      });
  }, []);

  useEffect(() => {
    console.log('EnvironmentVariablesReducer', EnvironmentVariablesReducer);
    if (EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH) {
      setPaytraceAwsPath(EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH);
    }
  }, [EnvironmentVariablesReducer]);

  useEffect(() => {
    console.log('data', data);
    console.log('paytraceAwsPath', paytraceAwsPath);
    if (data?.location.length && data?.location[0]?.image) {
      setCompanyImageUrl(paytraceAwsPath + data?.location[0]?.image);
    } else if (data.company.image && data.company.image !== 'null') {
      setCompanyImageUrl(paytraceAwsPath + data.company.image);
    } else {
      setCompanyImageUrl(paytraceAwsPath + 'titanium-logo.png');
    }
  }, [data]);

  const redirectLogin = () => {
    window.location.href = '/login';
  };

  const getClasses = (formik, field) => {
    return `${classes.signUpInput} ${formik.touched[field] && formik.errors[field] ? classes.errorHighlight : ''}`;
  };

  const hasError = (tVar, eVar) => {
    return tVar && eVar;
  };

  const messageType = (type, errorClass) => {
    return type === 'error' ? errorClass : 'success';
  };

  return (
    <div className={classes.userLink}>
      <LoadingOverlay active={isLoading} spinner text="please wait...">
        <div className={classes.paper}>
          {isError.error ? '' : <img src={companyImageUrl} style={imageStyle} height={'100rem'} alt="logo" />}
          {isError.error ? (
            <div className={classes.error}>{isError.errorMessage}</div>
          ) : (
            <>
              {isMessage.messageAvailable ? (
                <div className={messageType(isMessage.type, classes.error)}>{isMessage.message}</div>
              ) : (
                ''
              )}
              {isUserCreated ? (
                <Buttons
                  id="login"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submitButtonTopper}
                  onClick={redirectLogin}
                  text="Click Here to Login"
                />
              ) : (
                <>
                  {timeOutdiff > config.ACTIVATION_LINK ? (
                    <p className={classes.error}>
                      This link has expired. Please contact Titanium Payments support staff to receive a new one.{' '}
                      <a href={config.APPURL}>back</a>
                    </p>
                  ) : activateOnly ? (
                    <div className={classes.signupFormContainer}>
                      <h2>Activate your Account</h2>
                      <form onSubmit={formikActivate.handleSubmit} className="classes.signupForm">
                        <p>Hello {data.firstName}, please click below to complete your account activation. </p>

                        <button type="submit" className={classes.submitButtonTopper}>
                          Activate
                        </button>
                      </form>
                    </div>
                  ) : (
                    <div className={classes.signupFormContainer}>
                      <h2>Create your Username and Password</h2>
                      <form onSubmit={formik.handleSubmit} className="classes.signupForm">
                        <div className={classes.formGroup}>
                          <label htmlFor="username" className={classes.labelSignup}>
                            Username
                          </label>
                          <input
                            type="text"
                            id="username"
                            name="username"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                            className={getClasses(formik, 'username')}
                          />
                          <ErrorDisplay
                            hasError={hasError(formik.touched.username, formik.errors.username)}
                            className={classes.errorMessage}
                            message={formik.errors.username}
                          />
                        </div>

                        <div className={classes.formGroup}>
                          <label htmlFor="password" className={classes.labelSignup}>
                            Password
                          </label>
                          <input
                            type="password"
                            id="password"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className={getClasses(formik, 'password')}
                          />
                          <ErrorDisplay
                            hasError={hasError(formik.touched.password, formik.errors.password)}
                            className={classes.errorMessage}
                            message={formik.errors.password}
                          />
                        </div>

                        <div className={classes.formGroup}>
                          <label htmlFor="confirmPassword" className={classes.labelSignup}>
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            className={getClasses(formik, 'confirmPassword')}
                          />
                          <ErrorDisplay
                            hasError={hasError(formik.touched.confirmPassword, formik.errors.confirmPassword)}
                            className={classes.errorMessage}
                            message={formik.errors.confirmPassword}
                          />
                        </div>

                        <button type="submit" className={classes.submitButton}>
                          Submit
                        </button>
                      </form>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </LoadingOverlay>
    </div>
  );
}
