import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';

/**
 * payment info Begin Action
 */
export const paymentStart = () => ({
  type: ActionType.PAYMENT_START,
});

/**
 * payment info Success Action
 */
export const paymentSuccess = (paymentData) => {
  return {
    type: ActionType.PAYMENT_SUCCESS,
    payload: { paymentData },
  };
};

/**
 * payment info Failure Action
 */
export const paymentError = (error) => ({
  type: ActionType.PAYMENT_ERROR,
  payload: { error },
});

/**
 * payment info Begin Action
 */
export const recurranceStart = () => ({
  type: ActionType.RECURRANCE_START,
});

/**
 * recurrance info Success Action
 */
export const recurranceSuccess = (paymentData) => {
  return {
    type: ActionType.RECURRANCE_SUCCESS,
    payload: { paymentData },
  };
};

/**
 * recurrance info Failure Action
 */
export const recurranceError = (error) => ({
  type: ActionType.RECURRANCE_ERROR,
  payload: { error },
});

/**
 * payment driver info
 */

export const hashSecureCard = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'hashSecureCard', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const secureCardAdd = (data) => {
  return (dispatch) => {
    dispatch(paymentStart());
    return DataApi(data, config.BASEURL + 'secureCardAdd')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getRecurringVaribale = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getRecurringVaribale', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getPaymentVaribale = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getPaymentVaribale', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getInvoiceNumber = () => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  let rand = Math.floor(Math.random() * 1000000000);
  let data = { invoice: rand };
  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getInvoiceNumber', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getTransactionList = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getTransactionList', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getTransactionDailyHours = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getTransactionDailyHours', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getTransactionListTodayData = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getTransactionListTodayData', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getRefundedDailyData = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getRefundedDailyData', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getTransactionsDataForRefund = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  let locationId = '';
  if (localStorage.getItem('locationArrIds') !== null) {
    locationId =
      JSON.parse(localStorage.getItem('locationArrIds')).length > 1
        ? null
        : JSON.parse(localStorage.getItem('locationArrIds'))[0];
  }
  if (locationId === null) {
    locationId = JSON.parse(localStorage.getItem('locationArrIds'))[0];
  }
  return (dispatch) => {
    dispatch(paymentStart());
    // return DataGetApi(config.BASEURL + 'getTransactionsDataForRefund', data, token).then((response) => {
    return DataGetApi(config.BASEURL + 'getTransactionsDataForRefund', { ...data, locationId: locationId }, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getTransactionOverview = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getTransactionOverview', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getTransactionMonthlyOverview = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getTransactionMonthlyOverview', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getRecurrenceList = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getRecurrenceList', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const TransactionDetailsId = (data, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'TransactionDetailsId', data, token, paytraceCredentials)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const recurrenceStatus = (data) => {
  return (dispatch) => {
    dispatch(recurranceStart());
    return DataApi(data, config.BASEURL + 'RecurrenceStatus')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(recurranceSuccess(response));
        } else {
          return dispatch(recurranceError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const RecurrenceDetailsId = (data, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'RecurrenceDetailsId', data, token, paytraceCredentials)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const refundAuth = (data, csrf, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(paymentStart());
    return DataApi(data, config.BASEURL + 'refundAuth', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const refundAuthManual = (data, csrf, paytraceCredentials) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(paymentStart());
    return DataApi(data, config.BASEURL + 'refundAuthManual', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const voidTransaction = (data, csrf, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(paymentStart());
    return DataApi(data, config.BASEURL + 'voidTransaction', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getInvoiceList = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getInvoiceList', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getPendingStatusInvoiceList = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getPendingStatusInvoiceList', data, token, {})
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const InvoiceDetailById = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'InvoiceDetailById', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const InvoiceDetailByIdForPay = (data) => {
  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'InvoiceDetailByIdForPay', data)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getOntimeCustomerDataForReccurringPay = (data) => {
  const hash = { id: data };
  console.log('data', data);
  console.log('config.BASEURL', config.BASEURL);

  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getOntimeCustomerDataForReccurringPay', hash)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          console.log('response.response.data errrr', response.response.data);
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const PaymentAuthPage = (data) => {
  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'PaymentAuthPage', data)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const createRecurrence = (data, csrf, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(paymentStart());
    return DataApi(data, config.BASEURL + 'createRecurrence', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const updateRecurrence = (data, csrf, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(paymentStart());
    return DataApi(data, config.BASEURL + 'updateRecurrence', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const deleteRecurrence = (data, csrf, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(paymentStart());
    return DataApi(data, config.BASEURL + 'deleteRecurrence', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const emailInvoice = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(paymentStart());
    return DataApi(data, config.BASEURL + 'emailInvoice', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getCsrfToken = (data) => {
  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'getCsrfToken', data, null)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const DownloadInvoice = (data) => {
  return (dispatch) => {
    dispatch(paymentStart());
    return DataGetApi(config.BASEURL + 'downloadInvoice', data, null)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(paymentSuccess(response));
        } else {
          return dispatch(paymentError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
