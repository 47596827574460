import { initialAddressSettings, initialAddressState } from '../../helpers/addressSettingsState';

export default {
  FRONT_END: 'Virtual Terminal',
  currentTransactionAmount: '',
  GetPayLink: '0',
  redirectTransaction: false,
  isPaid: false,
  isCredit: true,
  isRefund: false,
  isRefundManual: false,
  isInvoice: false,
  isNewCustomer: false,
  cardPanelShow: true,
  cardPanelCustomerShow: false,
  mask_number: '',
  americanExpress: false,
  isLoading: false,
  companyLogoPath: null,
  isOpen: false,
  amount: '',
  cardAmount: 0,
  email: '',
  phone: '',
  total: 0,
  cashTotal: 0,
  lateFee: 0,
  svcFee: '',
  surcharge: 0,
  cardType: 'Credit',
  cash: false,
  tax: 0,
  taxType: '%',
  svcType: '%',
  invoice: '',
  notes: '',
  createdBy: '',
  companyId: '',
  discountType: 1,
  discount: 0,
  isSubmit: false,
  addNewCard: false,
  isClose: false,
  dueDate: new Date(),
  dualFeeEnabled: false,
  invoiceDate: new Date(),
  misMatchError: [],
  achDisclaimerError: [],
  CoustmerData: [],
  CardData: [],
  InvoicePendingData: [],
  RefundPendingData: [],
  TransactionData: [],
  TransactionRefundData: [],
  successMessages: false,
  discountErr: false,
  updateCard: false,
  cardWalletID: null,
  addNewCardDisabled: false,
  tsepFieldError: null,
  tsepFieldSuccess: false,
  tsepSuccessResponse: null,
  tsepAuth: false,
  tabValue: 0,
  indexValue: 0,
  userDetails: JSON.parse(localStorage.getItem('user')),
  customerDetails: { _id: '', firstName: '', lastName: '' },
  userData: { permissions: {} },
  paytraceCredentials: {},
  csrf: { 'csrf-token': '', 'csrf-secret': '' },
  locationId: '',
  feeSettings: {
    InvoiceTax: false,
    InvoiceSurcharge: false,
    InvoiceDiscount: false,
    virtualTerminalSettings: {
      InvoiceTax: false,
      InvoiceSurcharge: false,
      InvoiceDiscount: false,
      DiscountFeeName: '',
      InvoiceSVCFeeType: '%',
      InvoiceSVCFee: 0,
      InvoiceSalesTaxFee: 0,
      InvoiceSalesTaxFeeType: '%',
      InvoiceSurchargeFeeType: '%',
      InvoiceSurchargeFee: 0,
      InvoiceSurchargeFeeName: 'Surcharge Fee',
    },
  },
  warning: false,
  warningMessage: 'warning message',
  openAdjustSettings: false,
  ach: {
    isOpen: false,
    accountName: '',
    accountNumber: '',
    routingNumber: '',
  },
  transactionDetails: { TXNID: '' },
  manualRefund: { amount: '' },
  errorAch: {
    accountNameError: false,
    accountNumberError: false,
    routingNumberError: false,
  },
  card: {
    isOpen: true,
    cardHolderName: '',
    cardNumber: '_______________',
    cardExpiryDate: '__/____',
    cardCsc: '___',
    customerToken: false,
    customerTokenUpdate: false,
    creditOrDebit: null,
  },
  creditOrDebit: null,
  errorManualRefund: {
    amountError: false,
  },
  error: {
    amountError: false,
    emailError: false,
    phoneError: false,
    svcFeeError: false,
    zipError: false,
    allLocationError: false,
  },
  errorCard: {
    cardNumber: false,
    cardHolderName: false,
    cardExpiryDate: false,
    cardCsc: false,
  },
  limit: {
    perTransactionLimit: false,
    dailyTransactionLimit: false,
    perRefundLimit: false,
    dailyRefundLimit: false,
  },
  ...initialAddressSettings,
  ...initialAddressState,
  paymentLinkMessage: false,
  invoiceLabel: 'Invoice Number',
  isJobIdenabled: false,
  isNewInvoiceFromJobId: false,
  selectedInvoice: '',
  isLateFeeApplicable: false,
  showTextLink: false,
  openQRModal: false,
  isOneTimeCustomer: false,
  customerName: '',
  oneTimeCustomerInvoice: '',
  isPaxTransactionEnable: false,
  paymentGenerationSuccess: false,
  isStateSVCApplied: false,
  stateSVCAppliedRate: 0.0,
  isOpenDialogue: false,
  paxTerminalsList: [{ paxterminalName: '', paxTerminalSerial: '' }],
  selectedPaxTerminal: null,
  paxSelectError: false,
  submitedToPax: false,
  submitedToPaxModal: false,
  isCancelledFromPax: false,
  proceedAnyway: false,
  proceedAnywayAcknowledgement: false,
  binCodesResponse: {},
};
