import { isNotNullOrUndefined } from '../helpers/util';

const tsepKeys = {
  TSEP_URL_DEV: 'https://stagegw.transnox.com/transit-tsep-web/jsView/',
  TSEP_URL_PROD: 'https://gateway.transit-pass.com/transit-tsep-web/jsView/',
  TSEP_DEVICE_ID_LOCAL: '88600000343702',
  TSEP_DEVICE_ID_DEV: '88600000343703',
  TSEP_DEVICE_ID_TEST: '88600000343706',
  TSEP_DEVICE_ID_STAGING: '88600000343707',
  TSEP_DEVICE_ID_PROD: '00000000000000',
};

const getTsepDeviceIdsAndUrls = (locationDetails) => {
  const locationPaydetails = getLocationPaydetails();
  const location = locationDetails || locationPaydetails;
  // For Prod we need to update the device ID since each merchant has different id
  if (location === undefined || location === 'undefined' || location === null) {
    console.warn('Location details not defined');
  } else {
    const loc = location;
    tsepKeys.TSEP_DEVICE_ID_PROD =
      loc &&
      loc.multipassCredentials &&
      loc.multipassCredentials.multipassTSEPId &&
      loc.multipassCredentials.multipassTSEPId
        ? loc.multipassCredentials.multipassTSEPId
        : '00000000000000';
  }

  return tsepKeys;
};
const getLocationPaydetails = () => {
  const locationPaydetails = localStorage.getItem('locationPaydetails');
  if (isNotNullOrUndefined(locationPaydetails)) {
    return JSON.parse(locationPaydetails);
  }
  return locationPaydetails;
};

export default getTsepDeviceIdsAndUrls;
