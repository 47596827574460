import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InputAdornment } from '@material-ui/core';
import { InsertInvitation } from '@material-ui/icons';

function DatePickerInput({ handler, value, label, views, format, required, error }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        views={views}
        inputFormat={format}
        onChange={(newValue) => {
          handler(newValue);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <InsertInvitation />
            </InputAdornment>
          ),
        }}
        styles={{ width: '100%', justifyContent: 'center' }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="standard"
              style={{
                padding: '4px',
                marginLeft: '19px',
                marginRight: '6px',
                marginTop: '7px',
              }}
              required={required}
              error={error}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

DatePickerInput.propTypes = {
  handler: PropTypes.func,
  views: PropTypes.any,
  value: PropTypes.any,
  format: PropTypes.any,
  required: PropTypes.bool,
  error: PropTypes.any,
  label: PropTypes.any,
};

export default DatePickerInput;
