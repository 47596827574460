import { ActionType } from './../constants';
import { DataApi } from './../helpers/PostDataApi';
import { config } from './../constants';
import cookie from 'react-cookies';
/**
 * User info Begin Action
 */
export const loginStart = () => ({
  type: ActionType.LOGIN_START,
});

/**
 * User info Success Action
 */
export const loginSuccess = (loginData) => {
  return {
    type: ActionType.LOGIN_FETCH_DATA,
    payload: { loginData },
  };
};

/**
 * User info Failure Action
 */
export const loginError = (error) => ({
  type: ActionType.LOGIN_ERROR,
  payload: { error },
});

/**
 * User driver info
 */
export const login = (data, props) => {
  return (dispatch) => {
    dispatch(loginStart());
    return DataApi(data, config.BASEURL + 'logIn')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          cookie.save('user', response.data.response, { path: '/' });
          props.history.push('/signMeUp');
        } else {
          return dispatch(loginError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const Empty = (key) => (dispatch) => {
  console.log('error');
  dispatch({
    type: 'Empty_state',
    payload: { property: key, value: '' },
  });
};
