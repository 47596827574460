import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core/';
import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Buttons } from '../../../components/Buttons';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import { setEventListners, unsetEventListners } from './tsepUtils';

import './tsep.css';

const TsepForm = (
  cardWalletID,
  cardPanelCustomerShow,
  updateCard,
  customerToken,
  customerTokenUpdate,
  handleChangeCheckbox,
  isOneTimeCustomer,
  isRefund,
  isMobile
) => {
  const cardDetailsWidth = isMobile ? '57%' : '50%';
  return (
    <div
      className={cardWalletID === null ? 'block' : 'none'}
      style={{ background: 'linear-gradient(90deg,#4c8390,#238ca1 3%,#345da9 61%)', color: '#fff', borderRadius: 5 }}
    >
      <div className="card-header">
        <Typography variant="h6" component="h2">
          Card Details
        </Typography>
      </div>
      <form style={{ padding: 10, width: '100%' }} method="POST" id="payment-form">
        <div style={{ marginTop: 10, width: cardDetailsWidth }} className="card-details0">
          <label className="card-labels" id="card-label-id">
            Card Number
          </label>
          <div
            id="tsep-cardNumDiv"
            className="tsep-cardNumDiv"
            data-auto-formatting="Y"
            data-validate-cc="Y"
            data-detect-card-type="Y"
          ></div>
        </div>
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <div className="card-details0">
            <label className="card-labels" id="card-label-id">
              Exp Date
            </label>
            <div id="tsep-datepickerDiv" className="tsep-datepickerDiv" data-validate-expiry-date="Y"></div>
          </div>
          <div style={{ marginLeft: 10 }} className="card-details0">
            <label className="card-labels" id="card-label-id">
              CVV
            </label>
            <div id="tsep-cvv2Div" className="tsep-cvv2Div" data-validate-cvv2="Y"></div>
          </div>
        </div>
        <div style={{ marginTop: 10, display: 'none' }}>
          <div id="tsep-cardHolderNameDiv" data-validate-name="Y"></div>
        </div>
        <div style={{ marginTop: 10, display: 'none' }}>
          <div id="tsep-zipCodeDiv" data-validate-zipcode="Y"></div>
        </div>

        {saveAndUpdateChecks(
          isOneTimeCustomer,
          updateCard,
          customerToken,
          handleChangeCheckbox,
          customerTokenUpdate,
          isRefund
        )}
      </form>
    </div>
  );
};

const saveAndUpdateChecks = (
  isOneTimeCustomer,
  updateCard,
  customerToken,
  handleChangeCheckbox,
  customerTokenUpdate,
  isRefund
) => {
  if (!isOneTimeCustomer && !isRefund) {
    if (!updateCard) {
      return updateCheckbox('customerToken', customerToken, handleChangeCheckbox, 'Save card for future payments');
    }
    return updateCheckbox(
      'customerTokenUpdate',
      customerTokenUpdate,
      handleChangeCheckbox,
      'Update card for future payments'
    );
  } else {
    return null;
  }
};

const updateCheckbox = (id, checkedValue, handler, label) => {
  return (
    <>
      <Checkbox
        id={id}
        labels="creatNewUser"
        checked={checkedValue}
        onChange={(e) => handler(e)}
        value={checkedValue}
        color="primary"
        inputProps={{
          'aria-label': 'secondary checkbox',
        }}
      />
      <label>{label}</label>
    </>
  );
};

const TsepCardDetails = (
  cardWalletID,
  handleSubmitAddCard,
  customerTokenUpdate,
  maskedCardNumber,
  addNewCardDisabled
) => {
  return (
    <div className={cardWalletID !== null ? 'block' : 'none'}>
      <h3>
        Card wallet ID: <p>{cardWalletID ?? 'N/A'}</p>
      </h3>
      <h3>
        Masked Card Number: <p>{maskedCardNumber ?? 'N/A'}</p>
      </h3>
      {!addNewCardDisabled && (
        <Buttons
          id="AddNewCard"
          type="button"
          variant="contained"
          color="primary"
          className={'addNewCardBtn'}
          onClick={(e) => handleSubmitAddCard(e)}
          text={'Add new card'}
        />
      )}
    </div>
  );
};

const TsepCardError = () => {
  return (
    <div>
      <Alert variant="outlined" severity="error">
        Payment form failed to load due to a configuration issue. Please contact techsupport@titaniumpayments.com or
        call 877-972-0700.
      </Alert>
    </div>
  );
};

const TsepCard = (props) => {
  const {
    cardPanelCustomerShow,
    handleChangeCheckbox,
    customerToken,
    cardWalletID,
    maskedCardNumber,
    customerTokenUpdate,
    updateCard,
    handleSubmitAddCard,
    tsepAuth,
    isOneTimeCustomer,
    addNewCardDisabled,
    isRefund = false,
    card,
    isMobile,
  } = props;
  useEffect(() => {
    setEventListners();
    return () => {
      unsetEventListners();
    };
  }, []);
  let cardOpen = 'block';
  if (card && card.isOpen === false) {
    cardOpen = 'none';
  }
  return (
    <>
      <Grid item xs={12} sm={12} md={12} style={{ display: cardOpen }}>
        {tsepAuth ? (
          <>
            {TsepForm(
              cardWalletID,
              cardPanelCustomerShow,
              updateCard,
              customerToken,
              customerTokenUpdate,
              handleChangeCheckbox,
              isOneTimeCustomer,
              isRefund,
              isMobile
            )}
            {TsepCardDetails(
              cardWalletID,
              handleSubmitAddCard,
              customerTokenUpdate,
              maskedCardNumber,
              addNewCardDisabled
            )}
          </>
        ) : (
          <>{TsepCardError()}</>
        )}
      </Grid>
    </>
  );
};

TsepCard.propTypes = {
  handleChangeCheckbox: PropTypes.any,
  tsepFieldError: PropTypes.any,
  tsepSuccessResponse: PropTypes.any,
  tsepFieldSuccess: PropTypes.any,
  customerToken: PropTypes.any,
  cardWalletID: PropTypes.any,
  maskedCardNumber: PropTypes.any,
  cardPanelCustomerShow: PropTypes.any,
  customerTokenUpdate: PropTypes.any,
  updateCard: PropTypes.any,
  handleSubmitAddCard: PropTypes.any,
  tsepAuth: PropTypes.any,
  isOneTimeCustomer: PropTypes.any,
  addNewCardDisabled: PropTypes.any,
  isRefund: PropTypes.any,
  card: PropTypes.any,
  isMobile: PropTypes.any,
};

export default TsepCard;
