import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import timezonesData from '../../constants/Timezones';
import PropTypes from 'prop-types';
import { CustomFields } from '../Company/CompanyCustomFields';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { locationAdd } from '../../actions/LocationAction';
import { countryDataList, CompanyDetailById } from '../../actions/CompanyAction';
import { handleCompanyCheckboxChange } from '../Company/CommonFunctions';
import Resizer from 'react-image-file-resizer';
import validator from './../../helpers/checkValidations';
import LoadingOverlay from 'react-loading-overlay';
import Checkbox from '@material-ui/core/Checkbox';
import { changeHandler, checkErrorFields } from './CommonFunctions';
import { CustomBuild, CustomBuildFields } from '../../components/CustomBuild';
import LocationForm from './LocationForm';

class AddLocationsCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.goBackTab = this.goBackTab.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.mapToFormatCity = this.mapToFormatCity.bind(this);
    this.mapToFormatCountry = this.mapToFormatCountry.bind(this);
    this.mapToFormatStates = this.mapToFormatStates.bind(this);
    this.setCitySetValue = this.setCitySetValue.bind(this);
    this.setCountryStateValue = this.setCountryStateValue.bind(this);
    this.setStatesSetValue = this.setStatesSetValue.bind(this);
    this.getValidationStyle = this.getValidationStyle.bind(this);
    this.handleCheckboxDba = this.handleCheckboxDba.bind(this);
    this.handleSaveAndContinued = this.handleSaveAndContinued.bind(this);
    this.uploadKey = this.uploadKey.bind(this);
    this.goBackLocationForm = this.goBackLocationForm.bind(this);
    this.onChangeImageHandler = this.onChangeImageHandler.bind(this);
    this.maptoFormatTimezones = this.maptoFormatTimezones.bind(this);
    this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
    this.state = {
      sideShow: true,
      isSubmit: false,
      locationSameAsDba: false,
      isLoading: true,
      locationName: '',
      merchantForm: false,
      email: '',
      phone: '',
      mId: '',
      contactName: '',
      address1: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      stateList: {},
      cityList: [],
      Countrydata: [],
      zip: '',
      timeZone: new Date(),
      merchantId: '',
      notes: '',
      misMatchError: [],
      successMessages: false,
      selectedFile: null,
      image: null,
      preview: false,
      imageUrl: null,
      logoError: [],
      user: JSON.parse(localStorage.getItem('user')),
      companyDetails: { country: '', city: '', state: '', address1: '', address2: '', zip: '' },
      paytraceKey: null,
      gateway: '',
      paytracePassword: '',
      paytraceUserName: '',
      multipassUserId: '',
      multipassPassword: '',
      multipassDeviceId: '',
      multipassmerchantId: '',
      multipassTSEPId: '',
      customFields: [],
      customFieldsEnabled: false,
      customTransactionColumnFields: [],
      customTransactionColumnEnabled: false,
      customTowingBuild: false,
      customDefaultBuild: true,
      paxTransactionEnable: false,
      customInvoicePageFields: [],
      textFlexPermissions: {
        textFlex: false,
        customId: false,
        customerName: false,
        phoneNumber: false,
        amount: false,
      },
      localTimeZone: '',
      error: {
        nameError: false,
        paytracePasswordError: false,
        paytraceUserNameError: false,
        locationNameError: false,
        phoneError: false,
        emailError: false,
        contactNameError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
        multipassPasswordError: false,
        multipassUserNameError: false,
        multipassmerchantError: false,
        multipassDeviceIdError: false,
        multipassTSEPError: false,
      },
      timezones: timezonesData,
      localTimeZoneLabel: '',
    };
  }

  mapToFormatCountry(data) {
    return data.map((Obj) => ({ value: Obj.Country, label: Obj.Country }));
  }
  mapToFormatStates(stateArray) {
    return Object.keys(stateArray).map((name) => ({ value: name, label: name }));
  }

  mapToFormatCity(stateArray) {
    return stateArray.map((name) => ({ value: name, title: name }));
  }

  maptoFormatTimezones(state) {
    return Object.keys(state).map((name) => ({ value: name, label: state[name] }));
  }

  getValidationStyle(condition) {
    if (condition) {
      return { width: '100%', border: '2px solid red', padding: '10px 0px 0px 0px' };
    } else {
      return { width: '100%' };
    }
  }

  getCountryDataList() {
    this.setState({ isLoading: true });
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false });
    });
  }

  componentDidMount() {
    if (this.state.user.company) {
      this.setState({ merchantId: this.state.user.company._id });
    }
    this.getCountryDataList();
    const {
      match: { params },
    } = this.props;
    let merchantId = params.id;
    this.setState({ merchantId: merchantId });
    this.getCompanyData();
  }
  getCompanyData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.CompanyDetailById(params.id).then((res) => {
      if (res.payload.companyData) {
        this.setState({ companyDetails: { ...this.state, ...res.payload.companyData.data.response } }, () => {
          this.setState({ isLoading: false });
        });
      }
    });
  }
  handleTimeZoneChange(event) {
    if (event && event.value && event.label) {
      this.setState({ localTimeZoneLabel: event.label });
      this.setState({ localTimeZone: event.value });
    } else {
      this.setState({ localTimeZoneLabel: '' });
      this.setState({ localTimeZone: '' });
    }
  }
  handleClickMenu(_e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    window.location.reload();
  }
  handleDateChange = (date) => {
    this.setState({ timeZone: date });
  };
  handleChange(event, _t) {
    const { id, value, misMatchError, error, validationMarkup } = changeHandler(event);
    if (!validationMarkup) {
      return false;
    }
    this.setState({
      [id]: value,
      error,
      misMatchError,
      isSubmit: false,
      successMessages: false,
    });
  }

  handleSubmit(e, _t) {
    let successMessages = false;
    let stateArray = this.state;
    let error = {};
    let errorFlag = false;
    let misMatchError = [];
    if (this.state.gateway == 'multipass') {
      delete this.state.paytraceKey;
      delete this.state.paytraceUserName;
      delete this.state.paytracePassword;
    }
    if (this.state.gateway == 'paytrace') {
      delete this.state.multipassDeviceId;
      delete this.state.multipassPassword;
      delete this.state.multipassmerchantId;
      delete this.state.multipassTSEPId;
      delete this.state.multipassUserId;
    }
    if (this.state.address2 === '') {
      delete this.state.address2;
    }
    if (this.state.notes === '') {
      delete this.state.notes;
    }
    if (this.state.phone === '') {
      delete this.state.phone;
    }
    this.setState({ isSubmit: true });
    Object.keys(stateArray).map((keyValue, _value) => {
      if (keyValue === 'localTimeZone') {
        return false;
      }
      if (validator(keyValue, stateArray[keyValue])) {
        return validator(keyValue, stateArray[keyValue])
          ? ((error[keyValue + 'Error'] = true), (errorFlag = true))
          : (error[keyValue + 'Error'] = false);
      } else {
        return stateArray[keyValue] === '' ||
          (typeof stateArray[keyValue] === 'string' && stateArray[keyValue].trim() === '')
          ? ((error[keyValue + 'Error'] = true), (errorFlag = true))
          : '';
      }
    });
    const errorObj = checkErrorFields(this.state, error, errorFlag, misMatchError);
    if (this.state.customFieldsEnabled && this.state.customFields[0]?.value === '') {
      errorObj.misMatchError.push('Custom Field Name can not be empty');
      errorObj.errorFlag = true;
    }
    if (errorObj.errorFlag) {
      console.log('>>>', error);
      window.scrollTo(0, 0, 0);
      this.setState({ misMatchError: errorObj.misMatchError, error, successMessages });
      return false;
    } else {
      const data = new FormData();
      for (const key in this.state) {
        if (
          key == 'customFields' ||
          key === 'textFlexPermissions' ||
          key === 'customTransactionColumnFields' ||
          key === 'customInvoicePageFields'
        ) {
          data.append(key, JSON.stringify(this.state[key]));
        } else {
          data.append(key, this.state[key]);
        }
      }
      this.setState({ isLoading: true });
      this.props.locationAdd(data).then((result) => {
        if (result !== undefined && result.type === 'LOCATION_SUCCESS') {
          window.scrollTo(0, 0);
          this.setState({ successMessages: true, isLoading: false, misMatchError }, () => {
            setTimeout(() => {
              this.goBackTab(e);
            }, 2000);
          });
        }
        if (result !== undefined && result.type === 'LOCATION_ERROR') {
          misMatchError.push(this.props.info.locationInfo.data.message);
          this.setState({
            misMatchError,
            successMessages,
            isLoading: false,
          });
        }
      });
    }
  }
  setCountryStateValue(val) {
    if (val !== null) {
      this.setState({
        stateList: this.state.Countrydata.filter((obj) => obj.Country === val.label)[0].State,
        country: val.label,
      });
    } else {
      this.setState({ stateList: [], cityList: [], state: '', city: '' });
    }
  }
  setStatesSetValue(val) {
    if (val !== null) {
      this.setState({
        cityList: this.state.stateList[val.label],
        state: val.label,
      });
    } else {
      this.setState({ cityList: [], state: '', city: '' });
    }
  }

  setCitySetValue(_event, val) {
    if (val !== null && Object.prototype.hasOwnProperty.call(val, 'value')) {
      this.setState({ city: val.title });
    } else if (val && val.inputValue) {
      this.setState({ city: val.inputValue });
    } else {
      this.setState({ city: val });
    }
  }

  goBackTab(e) {
    this.props.handleChangeTab(e, 1);
  }

  goBackLocationForm() {
    this.setState({ merchantForm: false });
  }

  //upload paytace key
  uploadKey = (event, _t) => {
    let file = event.target.files[0];
    this.setState({ paytraceKey: file });
  };
  handleCheckboxDba(event) {
    this.setState({ [event.target.id]: event.target.checked }, () => {
      if (this.state.locationSameAsDba) {
        this.setState({
          city: this.state.companyDetails.city,
          state: this.state.companyDetails.state,
          country: this.state.companyDetails.country,
          zip: this.state.companyDetails.zip,
          address1: this.state.companyDetails.address1,
          address2: this.state.companyDetails.address2,
        });
      } else {
        this.setState({ city: '', state: '', country: '', address1: '', zip: '', address2: '' });
      }
    });
  }

  //upload image
  onChangeImageHandler = (event, t) => {
    let logoError = [];
    let error = false;
    let selectedFile = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : '';
    let imageValue = event.target.files[0];
    const allowedExtension = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (imageValue && !allowedExtension.exec(imageValue.name)) {
      logoError.push(t('CompanyLogoFileTypeNotCorrect'));
      error = true;
      this.setState({ logoError, imageValue: null, preview: false });
    }
    if (imageValue) {
      let imgName = imageValue.name.replace(/\s+/g, '-');
      var imgNameWithOutExt = imgName.split('.')[0] + Date.now() + '.' + imgName.split('.')[1];

      const size = 5 * 1048576;
      if (imageValue.size > size) {
        logoError.push(t('CompanyLogoFileSizeNotCorrect'));
        this.setState({ logoError, image: null });
      }
    }
    if (!error) {
      Resizer.imageFileResizer(
        event.target.files[0],
        150,
        150,
        'JPEG',
        100,
        0,
        (uri) => {
          let file = new File([uri], imgNameWithOutExt);
          this.setState({ selectedFile: selectedFile, logo: file, preview: true, logoError });
        },
        'blob'
      );
    }
  };

  handleChangeCheckbox = (event) => {
    const newStates = handleCompanyCheckboxChange(event, this.state);
    this.setState(newStates);
  };

  setCustomFields(fields) {
    console.log('parent customFields', fields);
    if (
      (fields[0]?.enabled === false || fields[0]?.autoGenerate === true || fields[0]?.required === true) &&
      this.state.textFlexPermissions.customId === true
    ) {
      this.setState({
        textFlexPermissions: { ...this.state.textFlexPermissions, customerName: true, customId: false },
      });
    }
    this.setState({ customFields: fields });
  }

  enableCustomFields(isCustomFieldsEnabled) {
    if (isCustomFieldsEnabled === false) {
      this.setState({
        textFlexPermissions: { ...this.state.textFlexPermissions, customerName: true, customId: false },
      });
    }
    this.setState({ customFieldsEnabled: isCustomFieldsEnabled });
    this.forceUpdate();
  }

  setCustomTransactionColumnFields(fields) {
    this.setState({ customTransactionColumnFields: fields });
  }

  enableCustomTransactionsFields(isCustomTransactionFieldEnabled) {
    this.setState({ customTransactionColumnEnabled: isCustomTransactionFieldEnabled });
  }

  handleChangeCustomInvoicePageFields = (fields) => {
    this.setState({ customInvoicePageFields: fields });
  };
  handleChangeCustomBuild = (value) => {
    const checkboxName = value.target.name;
    const checked = value.target.checked;
    if (checkboxName === 'towing' && checked) {
      this.setState({ customTowingBuild: true, customDefaultBuild: false });
    } else if (checkboxName === 'default' && checked) {
      this.setState({ customDefaultBuild: true, customTowingBuild: false });
    }
  };

  handleSaveAndContinued = () => {
    this.setState({ merchantForm: true });
  };

  render() {
    const { t } = this.props;
    const { merchantForm, Countrydata, customInvoicePageFields, customTowingBuild, customDefaultBuild } = this.state;
    return (
      <LoadingOverlay className={classes.tpTableFullW} active={this.state.isLoading}>
        <Container component="main" maxWidth="md">
          {this.state.misMatchError && this.state.misMatchError.length > 0
            ? this.state.misMatchError.map((error, index) => {
                return <ErrorMessage errors={[error]} key={index} />;
              })
            : ''}
          {this.state.successMessages ? <SuccessMessage successes={[this.props.info.locationInfo.data.message]} /> : ''}

          <form className={classes.form} noValidate encType="multipart/form-data">
            {merchantForm ? (
              <>
                <Grid item xs={12}>
                  {' '}
                  <h3>Merchant Permission</h3>
                  <label>
                    <Checkbox
                      id="ACH"
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.ACH}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>ACH</span>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <Checkbox
                      id="customerPortal"
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.customerPortal}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Customer Portal</span>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <Checkbox
                      id="multiInvoicePayment"
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.multiInvoicePayment}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Customer Portal Multiple Invoice Payment</span>
                  </label>
                </Grid>
                <CustomFields
                  setCustomFields={this.setCustomFields.bind(this)}
                  enableCustomFields={this.enableCustomFields.bind(this)}
                  customFields={this.state.customFields}
                  customFieldsEnabled={this.state.customFieldsEnabled}
                  title="Custom Fields"
                  displayAutoGenerateCheckBox={true}
                  displayRequiredCheckBox={true}
                />
                <Grid item>
                  <CustomFields
                    setCustomFields={this.setCustomTransactionColumnFields.bind(this)}
                    enableCustomFields={this.enableCustomTransactionsFields.bind(this)}
                    customFields={this.state.customTransactionColumnFields}
                    customFieldsEnabled={this.state.customTransactionColumnEnabled}
                    title="Custom Transaction Column"
                    displayAutoGenerateCheckBox={false}
                  />
                </Grid>
                <Grid item>
                  <CustomBuild
                    required={true}
                    customTowingBuild={customTowingBuild}
                    customDefaultBuild={customDefaultBuild}
                    type="text"
                    width={true}
                    handleChange={this.handleChangeCustomBuild}
                  />

                  {customTowingBuild && customTowingBuild && (
                    <>
                      <CustomBuildFields
                        fields={customInvoicePageFields}
                        handleChange={this.handleChangeCustomInvoicePageFields}
                      />
                    </>
                  )}

                  {this.state.gateway === 'multipass' ? (
                    <Grid item>
                      <label>
                        <Checkbox
                          onChange={(e) => {
                            this.handleChangeCheckbox(e);
                          }}
                          checked={this.state.paxTransactionEnable}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                          color="primary"
                          value={this.state.paxTransactionEnable}
                          id="paxTransactionEnable"
                        />
                        <span>PAX Transaction</span>
                      </label>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <div style={{ padding: 20 }}>
                    <Buttons
                      className={classes.btn_space}
                      id="merchantForm"
                      type="button"
                      variant="contained"
                      color="info"
                      onClick={(_e) => this.setState({ merchantForm: false })}
                      text="Back"
                    />
                    <Buttons
                      id="signUp"
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={(e) => this.handleSubmit(e, t)}
                      text={t('Save')}
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <LocationForm
                  handleChange={this.handleChange}
                  mapToFormatCountry={this.mapToFormatCountry}
                  maptoFormatTimezones={this.mapToFormatStates}
                  handleTimeZoneChange={this.handleTimeZoneChange}
                  mapToFormatCity={this.mapToFormatCity}
                  mapToFormatStates={this.mapToFormatStates}
                  state={this.state}
                  handleCheckboxDba={this.handleCheckboxDba}
                  setCitySet={this.setCitySetValue}
                  onChangeHandler={this.onChangeImageHandler}
                  getValidationStyle={this.getValidationStyle}
                  uploadKey={this.uploadKey}
                  goBack={this.goBackTab}
                  setCountryState={this.setCountryStateValue}
                  setStatesSet={this.setStatesSetValue}
                  handleSaveAndContinue={this.handleSaveAndContinued}
                  goBackLocationForm={this.goBackLocationForm}
                  Countrydata={Countrydata}
                  selectGateway={(gateway) => {
                    this.setState({ gateway: gateway });
                  }}
                  t={t}
                />
              </>
            )}
          </form>
        </Container>
      </LoadingOverlay>
    );
  }
}

AddLocationsCompanyDetails.propTypes = {
  countryDataList: PropTypes.func,

  CompanyDetailById: PropTypes.func,

  handleChangeTab: PropTypes.func,

  locationAdd: PropTypes.func,

  match: PropTypes.any,

  t: PropTypes.any,

  info: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.LocationReducer,
  };
}
export default connect(mapStateToProps, { CompanyDetailById, locationAdd, countryDataList })(
  withTranslation()(withRouter(AddLocationsCompanyDetails))
);
