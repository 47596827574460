import { ActionType } from './../constants';
const initialState = () => ({
  successPage: false,
  loading: false,
  amount: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
});

const InvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.INVOICE_START:
      return {
        ...state,
        loading: true,
      };
    case ActionType.INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceInfo: action.payload.invoiceData,
      };
    case ActionType.INVOICE_ERROR:
      return {
        ...state,
        loading: false,
        invoiceInfo: action.payload.error,
      };

    default:
      return state;
  }
};
export { InvoiceReducer };
