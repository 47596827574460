import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Filter, Export, Print, View } from './ui';
import Utilities from './utilities';
import ExportMethod from './export';

class DataTableExtensions extends Component {
  constructor(props) {
    super(props);

    const { columns, data, filterDigit } = props;

    this.state = {
      dropdown: false,
      coldropdown: false,
      columns,
      data,
      constData: data,
      filter: '',
      filterDigit,
    };

    this.raw = {
      header: [],
      data: [],
    };
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickEvent.bind(this));
  }
  handleClickEvent(event) {
    if (event.target.name !== 'view-col' && this.state.coldropdown === true) {
      this.setState({ coldropdown: true });
      //call the api to save user's enabled disabled columns,
      this.props.saveUserSelection();
    }

    if (event.target.name !== 'export-data' && this.state.dropdown) {
      this.setState({ dropdown: false });
    }
  }
  componentDidMount() {
    window.addEventListener('click', this.handleClickEvent.bind(this));
    const { columns } = this.state;

    // column properties and select fields to export
    columns.forEach((element) => {
      if (element.export !== false) {
        this.raw.header.push(element);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { columns, data, filterDigit } = this.props;
    const { filter } = this.state;

    if (prevProps.columns !== columns || prevProps.data !== data) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          columns,
          data,
          filterDigit,
          constData: data,
        },
        () => {
          this.checkHeader();

          if (filter.length > filterDigit) {
            this.onFilter(filter);
          }
        }
      );
    }
  }

  onDataRender() {
    const { constData } = this.state;
    // get and render data
    this.raw.data = Utilities.dataRender(constData, this.raw.header);
  }

  onExport(e, type) {
    this.onDataRender();

    const { exportHeaders, fileName } = this.props;
    const { data, header } = this.raw;
    let notesIndexNum;

    header.forEach((value, index) => {
      if (value.name === 'Notes') {
        notesIndexNum = index;
      }
    });

    const formatedData = data.map((value) => {
      value[notesIndexNum] = value[notesIndexNum]?.replaceAll('\n', ' ');
      // prettier-ignore
      value[notesIndexNum] = value[notesIndexNum]?.replaceAll(',', '\,'); // eslint-disable-line
      return value;
    });

    const exportData = ExportMethod[type](formatedData, exportHeaders ? header : null, fileName);

    Utilities.download(exportData);

    this.setState({ dropdown: false });

    e.preventDefault();
  }

  onView(e, type) {
    console.log('e', e);
    console.log('type', type);
  }

  onFilter(text) {
    const value = Utilities.lower(text);

    const { constData, filterDigit } = this.state;
    const { filterHidden } = this.props;

    let filtered = constData;
    if (value.length > filterDigit) {
      if (!filterHidden) {
        this.onDataRender();
      }
      filtered = Utilities.filter(value, constData, this.raw.data, filterHidden);
    }

    this.setState({ data: filtered, filter: value });
  }

  onPrint() {
    this.onDataRender();

    const { data, header } = this.raw;
    const table = ExportMethod.print(data, header);

    Utilities.print(table);
  }

  checkHeader() {
    const { columns } = this.state;
    if (columns.length !== this.raw.header.length) {
      this.raw.header = [];
      columns.forEach((element) => {
        if (element.export !== false) {
          this.raw.header.push(element);
        }
      });
    }
  }
  onCheck(name, checkValue) {
    this.props.checkColumn(name, checkValue);
  }

  render() {
    const { dropdown, columns, data, coldropdown } = this.state;
    const { filter, print, children, filterPlaceholder } = this.props;
    return (
      <>
        <div className="data-table-extensions">
          {filter && <Filter onChange={(e) => this.onFilter(e.target.value)} placeholder={filterPlaceholder} />}
          <div className="data-table-extensions-action">
            {/* eslint-disable-next-line react/destructuring-assignment */}

            {this.props.export &&
              JSON.parse(localStorage.getItem('user')).permissions &&
              JSON.parse(localStorage.getItem('user')).permissions.exportRecord && (
                <Export
                  className={dropdown ? 'drop' : ''}
                  onDropdown={() => this.setState((prevState) => ({ dropdown: !prevState.dropdown }))}
                  onClick={(e, type) => this.onExport(e, type)}
                />
              )}
            {print && <Print onClick={() => this.onPrint()} />}
            {!this.props?.hideView && (
              <View
                className={coldropdown ? 'drop' : ''}
                isDropdownOpen={() => {
                  if (this.state.coldropdown) {
                    this.setState((prevState) => ({ coldropdown: !prevState.coldropdown }));
                  }
                }}
                onDropdown={() => this.setState((prevState) => ({ coldropdown: !prevState.coldropdown }))}
                onClick={(e, type) => this.onView(e, type)}
                onSelect={(name, checkvalue) => this.onCheck(name, checkvalue)}
                columns={this.state.columns}
              />
            )}
          </div>
        </div>
        {React.cloneElement(children, { columns, data })}
      </>
    );
  }
}

DataTableExtensions.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  filter: PropTypes.bool,
  filterPlaceholder: PropTypes.string,
  export: PropTypes.bool,
  print: PropTypes.bool,
  exportHeaders: PropTypes.bool,
  children: PropTypes.node,
  filterHidden: PropTypes.bool,
  filterDigit: PropTypes.number,
  fileName: PropTypes.string,
  checkColumn: PropTypes.func,
  saveUserSelection: PropTypes.func,
  hideView: PropTypes.bool,
};

DataTableExtensions.defaultProps = {
  columns: [],
  data: [],
  filter: true,
  export: true,
  print: true,
  exportHeaders: true,
  children: null,
  filterHidden: true,
  filterPlaceholder: 'Filter Table',
  filterDigit: 2,
  fileName: document.title,
};

export default DataTableExtensions;
