import { config, ActionType } from './../constants';
import { DataGetApi } from './../helpers/PostDataApi';

/**
 * user info Begin Action
 */
export const batchStart = () => ({
  type: ActionType.BATCH_START,
});

/**
 * user info Success Action
 */
export const batchSuccess = (batchData) => {
  return {
    type: ActionType.BATCH_SUCCESS,
    payload: { batchData },
  };
};

export const LastbatchSuccess = (data) => {
  return {
    type: ActionType.BATCH_LAST_UPDATED_DATE,
    payload: { data },
  };
};

/**
 * user info Failure Action
 */
export const batchError = (error) => ({
  type: ActionType.BATCH_ERROR,
  payload: { error },
});

export const getBatchData = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(batchStart());
    return DataGetApi(config.BASEURL + 'getBatchReporting', { ...data }, token)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return dispatch(batchSuccess(response));
        } else {
          return dispatch(batchError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getLastUpdatedBatchData = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let companyId = user.company._id ? user.company._id : '';
  let token = user.token;
  return (dispatch) => {
    dispatch(batchStart());
    return DataGetApi(config.BASEURL + 'getLastUpdatedBatchInfo', { ...data, companyId: companyId }, token)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return dispatch(LastbatchSuccess(response));
        } else {
          return dispatch(batchError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getBatchFundData = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(batchStart());
    return DataGetApi(config.BASEURL + 'getBatchFundData', data, token)
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          return dispatch(batchSuccess(res));
        } else {
          return dispatch(batchError(res));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
