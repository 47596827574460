import { configurePaytracePermFile } from '../../helpers/paytraceFunctions';

const setupPaymentGatewayProperties = (paymentGateway, locationDetails = null) => {
  const misMatchErrors = [];
  if (paymentGateway === 'paytrace') {
    const properties = mapPaymentGatewayProperties(paymentGateway, locationDetails);
    const paytranceInit = configurePaytracePermFile(locationDetails);
    if (!paytranceInit) {
      let msg = 'Paytrace file not exist. Kindly contact to super admin to re-upload the key.';
      misMatchErrors.push(msg);
      return { paytraceCredentials: null, misMatchErrors };
    }
    const paytraceCredentials = { ...properties };
    return { paytraceCredentials, misMatchErrors: null };
  } else if (paymentGateway === 'multipass') {
    const properties = mapPaymentGatewayProperties(paymentGateway);
    const multipassCredentials = { ...properties };
    return { multipassCredentials, misMatchErrors: null };
  }
};

const mapPaymentGatewayProperties = (paymentGateway, locationDetails) => {
  const locationPaydetails = locationDetails || JSON.parse(localStorage.getItem('locationPaydetails'));
  switch (paymentGateway) {
    case 'paytrace':
      return {
        paytraceKey: locationPaydetails?.paytraceKey,
        userName: locationPaydetails?.paytraceUserName,
        password: locationPaydetails?.paytracePassword,
        integratorId: locationPaydetails?.paytraceIntegratorId,
        gateway: 'paytrace',
      };
    case 'multipass':
      return {
        multipassTSEPId: locationPaydetails?.multipassCredentials?.multipassTSEPId,
        multipassDeviceId: locationPaydetails?.multipassCredentials?.multipassDeviceId,
        transactionKey: locationPaydetails?.multipassCredentials?.transactionKey,
        multipassmerchantId: locationPaydetails?.multipassCredentials?.multipassmerchantId,
        gateway: 'multipass',
      };
    default:
      return {};
  }
};

export { setupPaymentGatewayProperties };
