import { validateHtmlMarkUp, validateCustomerName } from '../../../helpers/commonFunction';
import validator from '../../../helpers/checkValidations';
export function changeHandler(event) {
  let error = {};
  let misMatchError = [];
  let validationPhone = true;
  let validationMarkup = true;
  let { id, value } = event.target;
  const isHtmlMarkupValid = validateHtmlMarkUp(value);
  const isNameValid = validateCustomerName(value);
  if ((id === 'firstName' || id === 'lastName') && isNameValid) {
    validationMarkup = false;
  }
  if (isHtmlMarkupValid) {
    validationMarkup = false;
  }
  validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);

  if (id === 'email' && value == '') {
    error[id + 'Error'] = false;
  }
  if (id === 'phone' && value == '') {
    error[id + 'Error'] = false;
  }
  if (id === 'zip' && value == '') {
    error[id + 'Error'] = false;
  }
  validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
  return { error, misMatchError, id, value, validationMarkup, validationPhone };
}

export function validateInputFields(key, state) {
  const fieldValidations = {
    firstName: {
      condition: !state.firstName || validator('firstName', state.firstName),
      errorMessage: 'firstNameError',
    },
    lastName: {
      condition: !state.lastName || validator('lastName', state.lastName),
      errorMessage: 'lastNameError',
    },
    zip: {
      condition: state.settings !== null && state.settings.isZipRequired && (!state.zip || validator('zip', state.zip)),
      errorMessage: 'zipError',
    },
    address: {
      condition:
        state.settings !== null &&
        state.settings.isAddressRequired &&
        (!state.address || validator('address', state.address)),
      errorMessage: 'addressError',
    },
    email: {
      condition: state.email && validator('email', state.email),
      errorMessage: 'emailError',
    },
    phone: {
      condition: state.phone && validator('phone', state.phone),
      errorMessage: 'phoneError',
    },
  };
  let errorObj = {};
  let errorFlagValue = false;
  if (fieldValidations[key]) {
    const { condition, errorMessage } = fieldValidations[key];
    if (condition) {
      errorObj[errorMessage] = true;
      errorFlagValue = true;
    }
  }
  return { errorFlagValue, errorObj };
}
