import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

const FlexAlert = (props) => {
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity={props.severity}>{props.message}</Alert>
    </Stack>
  );
};

FlexAlert.propTypes = {
  severity: PropTypes.any,
  message: PropTypes.any,
};

export default FlexAlert;
