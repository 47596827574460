module.exports.getSVCFee = (InvoiceSVCFeeType, InvoiceSVCFee, amount) => {
  let svcFee = 0;
  if (isNaN(parseFloat(amount))) {
    return svcFee.toFixed(2);
  }
  if (InvoiceSVCFeeType === '$') {
    svcFee = parseFloat(InvoiceSVCFee).toFixed(2);
  } else if (InvoiceSVCFeeType === '%') {
    svcFee = parseFloat((InvoiceSVCFee / 100) * amount).toFixed(2);
  }
  return svcFee;
};
module.exports.getTotalAmount = (InvoiceDiscount, InvoiceSVCFeeType, InvoiceSVCFee, amount) => {
  if (isNaN(parseFloat(amount))) return '';
  let totalAmount = parseFloat(amount);
  if (InvoiceDiscount) {
    totalAmount += parseFloat(exports.getSVCFee(InvoiceSVCFeeType, InvoiceSVCFee, amount));
  }
  return totalAmount.toFixed(2);
};

module.exports.getRoadsideTotalAmount = (
  InvoiceDiscount,
  InvoiceSVCFeeType,
  InvoiceSVCFee,
  InvoiceSalesTaxFee,
  InvoiceSalesTaxFeeType,
  amount
) => {
  if (isNaN(parseFloat(amount))) return '';
  let totalAmount = parseFloat(amount);
  if (InvoiceDiscount) {
    totalAmount += parseFloat(exports.getSVCFee(InvoiceSVCFeeType, InvoiceSVCFee, amount));
    totalAmount += parseFloat(exports.getSVCFee(InvoiceSalesTaxFeeType, InvoiceSalesTaxFee, amount));
  }
  return totalAmount.toFixed(2);
};
