module.exports = {
  PASSWORD_EXPIRY_LIMIT: 'Password expiry limit have exceeded to days. Please update your password',
  PASSWORD_POLICY_MESSAGE:
    'Your password must be between 8 - 25 characters long having a combination of upper characters, lower characters, numbers and special characters.',
  ALL_LOCATION_ERROR: 'Select a location',
  ACH_WARNING_MSG:
    'Please note that ACH payments are PENDING until funded. This may take 2-10 business days to clear if valid.',
  FETCH_TRANSACTION_ERROR:
    'Something went wrong while trying to fetch transactions. Please contact techsupport@titaniumpayments.com or call 877-972-0700.',
  FAILURE_BUTTON_MESSAGE:
    'Do not reattempt this transaction. Please email techsupport@titaniumpayments.com or call 877-972-0700. Approval or decline response wasn’t received from the gateway.',
  INQUEUE_ERROR:
    'Payment cannot be made to this invoice because it is scheduled for automatic billing at a later date.',
};
