import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

export const CustomFields = (props) => {
  const [fieldError, setFieldError] = useState('');
  const [fields, addField] = useState(
    props.customFields?.length === 0 ? [{ label: 0, value: null, enabled: false }] : props.customFields
  );
  const [isCustomFieldsEnabled, enableCustomFields] = useState(props.customFieldsEnabled);

  useEffect(() => {
    addField(props.customFields?.length === 0 ? [{ label: 0, value: null, enabled: false }] : props.customFields);
  }, [props.customFields]);

  const setFieldValue = (_value, index) => {
    console.log('field', fieldError);
    const updatedFields = fields;
    if (_value.match(/[%<>\\$'",()|/@#$^&*+=!~`;:?.{}[\]]/)) {
      setFieldError('Special characters not allowed');
    } else {
      updatedFields[index] = { ...fields[index], value: _value, label: index };
      addField([...updatedFields]);
      props.setCustomFields([...updatedFields]);
      setFieldError(null);
    }
  };
  const handleSwitchChange = (e, index) => {
    e.persist();
    const fieldObj = fields[index];
    fieldObj.enabled = e.target.checked;
    console.log('e.target.checked', e.target.checked);
    fields[index] = fieldObj;
    addField([...fields]);
    props.setCustomFields([...fields]);
  };
  const renderFields = (fieldsProp) => {
    return (
      fieldsProp?.length > 0 &&
      fieldsProp.map((field, index) => (
        <Grid item xs={12} key={index}>
          <TextField
            id={`field${index}`}
            type="text"
            label={field.value ?? `Custom Field Name`}
            onChange={(e) => setFieldValue(e.target.value, index)}
            inputProps={{ maxLength: 25 }}
            value={field.value}
            helperText={fieldError}
          ></TextField>
          <Switch
            checked={field?.enabled ?? false}
            onChange={(e) => {
              handleSwitchChange(e, index);
            }}
            onClick={(e) => console.log('onClick', e.target.checked)}
          />
          {props.displayAutoGenerateCheckBox && (
            <React.Fragment>
              <label>
                <Checkbox
                  id={`field-auto-generate-$${index}`}
                  onChange={(_e) => {
                    const fieldObj = fieldsProp[index];
                    fieldObj.autoGenerate = _e.target.checked;
                    fieldsProp[index] = fieldObj;
                    addField([...fieldsProp]);
                    props.setCustomFields([...fieldsProp]);
                  }}
                  value={field.autoGenerate ?? false}
                  color="primary"
                  checked={field.autoGenerate ?? false}
                />
              </label>
              <span>
                <b>Auto Generate</b>
              </span>
            </React.Fragment>
          )}

          {props.displayRequiredCheckBox && (
            <React.Fragment>
              <label>
                <Checkbox
                  id={`field-required-$${index}`}
                  onChange={(_e) => {
                    const fieldObj = fieldsProp[index];
                    fieldObj.required = _e.target.checked;
                    fieldsProp[index] = fieldObj;
                    addField([...fieldsProp]);
                    props.setCustomFields([...fieldsProp]);
                  }}
                  value={field.required ?? false}
                  color="primary"
                  checked={field.required ?? false}
                />
              </label>
              <span>
                <b>Required in Terminal</b>
              </span>
            </React.Fragment>
          )}
        </Grid>
      ))
    );
  };
  return (
    <React.StrictMode>
      <Grid item xs={12}>
        <label>
          <Checkbox
            id="CUSTOMFIELDS"
            onChange={(_e) => {
              enableCustomFields(!isCustomFieldsEnabled);
              props.enableCustomFields(!isCustomFieldsEnabled);
            }}
            value={props.customFieldsEnabled}
            color="primary"
            checked={props.customFieldsEnabled}
          />
        </label>
        <span>
          <b>{props.title}</b>
        </span>
        {props.customFieldsEnabled && (
          <React.Fragment>
            {/* <Buttons text={<AddIcon fontSize="small" />} size="large" onClick={addCustomField} /> */}
            <Grid container spacing={{ xs: 2, md: 3 }} style={{ flexDirection: 'column', marginLeft: '30px' }}>
              {renderFields(fields)}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.StrictMode>
  );
};
CustomFields.propTypes = {
  customFieldsEnabled: PropTypes.any,
  customFields: PropTypes.any,
  enableCustomFields: PropTypes.any,
  setCustomFields: PropTypes.func,
  displayAutoGenerateCheckBox: PropTypes.any,
  displayRequiredCheckBox: PropTypes.any,
  title: PropTypes.any,
};
