import React from 'react';
import Link from '@material-ui/core/Link';
import { formatCurrency } from '../../../helpers/commonFunction';
import classes from '../Payment.module.scss';
import config from '../../../constants/config';
import { getPolicyPDF } from '../../../actions/TermConditionsAction';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const PrivacyStatement = (props) => {
  const dispatch = useDispatch();
  const handlePolicyPDF = (policyName) => {
    props.isLoading(true);
    const params = {
      companyId: props.locationId.company,
      locationId: props.locationId?.id,
      policyType: policyName,
    };
    dispatch(getPolicyPDF(params)).then((response) => {
      if (response) {
        const { status, policyName } = response.payload.data;
        if (status === 200) {
          const fileURL = config.BASEURL + `getPolicies/${policyName}`;
          setTimeout(() => {
            window.open(fileURL);
          }, 2500);
        }
      }
      props.isLoading(false);
    });
  };

  return (
    <p className="font-sm">
      By clicking 'Pay' I authorize {props.locationId?.locationName} to{' '}
      {props.activeTab === 'card' ? 'charge my card' : 'debit my bank account by ACH'}{' '}
      {props.total ? formatCurrency(props.total) : formatCurrency(props.amount)} on {new Date().toDateString()}.
      <Link className={`${classes.back} ${classes.curser}`} onClick={() => handlePolicyPDF('privacy_policy')}>
        {' '}
        Privacy Statement.
      </Link>
      <Link className={`${classes.back} ${classes.curser}`} onClick={() => handlePolicyPDF('refund_policy')}>
        {' '}
        Refund Policy.
      </Link>
      <br />
      {props.paymentTerms?.isPaymentTermConditionEnabled
        ? `${props.paymentTerms?.paymentTermCondition.replace(/&nbsp;/g, ' ')}`
        : ''}
    </p>
  );
};

PrivacyStatement.propTypes = {
  isLoading: PropTypes.func.isRequired,
  locationId: PropTypes.shape({
    company: PropTypes.string,
    id: PropTypes.string,
    locationName: PropTypes.string,
  }).isRequired,
  activeTab: PropTypes.string,
  total: PropTypes.number,
  amount: PropTypes.number,
  paymentTerms: PropTypes.string,
};

export default PrivacyStatement;
