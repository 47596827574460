import validator from '../checkValidations';

export function validateZipCode(state) {
  let misMatchError = [];
  if (state.billingAddress && state.billingAddress.zip !== '' && validator('zip', state.billingAddress?.zip)) {
    misMatchError.push(`Please enter a valid zip value`);
    window.scrollTo(0, 0, 0);
    return misMatchError;
  }

  if (state.shippingAddress && state.shippingAddress.zip !== '' && validator('zip', state.shippingAddress?.zip)) {
    misMatchError.push(`Please enter a valid zip value`);
    window.scrollTo(0, 0, 0);
    return misMatchError;
  }
  return misMatchError;
}
