import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Table } from '../../components/Table';
import { Topheader } from './../../components/Topheader';
import { locationList, locationDelete, locationStatus } from '../../actions/LocationAction';
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from 'prop-types';
import config from '../../constants/config';
import { DataGetApi } from '../../helpers/PostDataApi';

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideShow: true,
      reload: false,
    };
  }

  locationStatusToggle(e, data) {
    this.props.locationStatus(data).then((_res) => {
      // this.getData()
    });
  }
  getTableData(query) {
    // End Refresh data
    return new Promise((resolve, _reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let url = config.BASEURL;
      let orderBy = query.orderBy !== undefined ? query.orderBy.field : '';
      let orderDirection = query.orderDirection !== '' ? query.orderDirection : '';
      let param = {
        orderBy,
        orderDirection,
        companyId: user.company._id,
        per_page: query.pageSize,
        page: +(query.page + 1),
        searchAll: typeof query.search === 'undefined' || query.search === '' ? false : true,
      };
      DataGetApi(url + 'locationList', param, token).then((result) => {
        if (typeof query.search === 'undefined' || query.search === '') {
          resolve({
            data: result.data.response,
            page: result.data.pagination.page - 1,
            totalCount: result.data.pagination.totalCount,
          });
        } else {
          let filterData = result.data.response.filter((row) => {
            return (
              row.locationName.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 ||
              row.city.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1 ||
              String(row.contactName).toLowerCase().indexOf(String(query.search)) !== -1 ||
              String(row.address1).indexOf(query.search) !== -1
            );
          });
          if (filterData.length > 0) {
            query.filters.push(filterData);
          }
          resolve({
            data: filterData,
            page: result.data.pagination.page - 1,
            totalCount: filterData.length,
          });
        }
      });
    });
  }
  tableReload(status) {
    this.setState({ reload: status });
  }
  render() {
    const columns = [
      { title: 'Location Name', field: 'locationName' },
      { title: 'Contact Name', field: 'contactName' },
      { title: 'City', field: 'city' },
      { title: 'Address', field: 'address1' },
      { title: 'Creation Date', field: 'createdAt', render: (date) => new Date(date.createdAt).toLocaleString() },
    ];

    const title = 'Locations';
    return (
      <div className="rightPanel">
        <Topheader />
        <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
          <div className="contentPart">
            <Table
              tableReload={(e) => this.tableReload(e)}
              companyActiveAction={(e, data) => this.locationStatusToggle(e, data)}
              options={{
                exportButton: true,
              }}
              columns={columns}
              getExportData={this.getTableData}
              data={(query) => this.getTableData(query)}
              permissions={this.props.Userinfo.permissions}
              title={title}
              exports={this.props.Userinfo.permissions.exportRecord}
              search={true}
              paging={true}
            />
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

Locations.propTypes = {
  t: PropTypes.func,
  Userinfo: PropTypes.any,
  locationStatus: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    info: state.LocationReducer,
    Userinfo: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { locationStatus, locationList, locationDelete })(withTranslation()(Locations));
