import config from './../../constants/config';
import { checkCreditOrDebit } from '../PostDataApi';

const getCardDebitCredit = (cardNo = null) => {
  return new Promise((resolve, reject) => {
    let cardno = cardNo !== null ? cardNo.substring(0, 6) : this.state.card.cardNumber.replaceAll('_', '');

    if (cardno && cardno.length > 5) {
      checkCreditOrDebit(config.BIN_CHECKER_URL, config.BIN_API_KEY, cardno)
        .then((card) => {
          if (typeof card.data.type !== 'undefined') {
            resolve(!!(card.data.type && card.data.type === 'DEBIT'));
          } else {
            resolve(true);
          }
        })
        .catch((e) => {
          console.log('error', e);
          reject(e);
        });
    } else {
      resolve(false);
    }
  });
};

export const getCardInfoViaBinLookup = (cardNum = null) => {
  return new Promise((resolve, reject) => {
    let cardNo = cardNum !== null ? cardNum.substring(0, 6) : '';

    if (cardNo && cardNo.length > 5) {
      checkCreditOrDebit(config.BIN_CHECKER_URL, config.BIN_API_KEY, cardNo)
        .then((cardInfo) => {
          resolve(cardInfo);
        })
        .catch((e) => {
          console.log('error', e);
          reject(e);
        });
    } else {
      resolve(false);
    }
  });
};

export default getCardDebitCredit;
