export function CurrencyFormatterUS(amount = 0) {
  if (typeof amount === 'number') {
    const tempAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Math.abs(amount));
    return tempAmount;
  }
  return amount;
}
