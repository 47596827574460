import React, { useState, useEffect } from 'react';
import { Button, Container, Radio, Grid, FormControl, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import classes from './Forgot.module.scss';
import { DataApi } from './../../helpers/PostDataApi';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { config } from './../../constants';
import fetch from 'isomorphic-unfetch';
import { Link, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Forgot = ({ location, t }) => {
  const query = useQuery();
  const [rememberUsername, setRememberUsername] = useState('');
  const [showUserName, setShowUserName] = useState(false);
  const [username, setUsername] = useState('');
  const [usernamesAvailable, setUsernamesAvailable] = useState(false);
  const [usernames, setUsernames] = useState([]);
  const [message, setMessage] = useState({
    error: false,
    message: '',
    haveMessage: false,
  });

  useEffect(() => {
    const token = query.get('token');

    fetch(config.BASEURL + `fetchHashDetails?hash=${token}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true && data.statusCode === 200) {
          setUsernamesAvailable(data.response.isUserNames);
          setUsernames(data.response.getUserNames);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location]);

  const handleRadioChange = (event) => {
    setRememberUsername(event.target.value);
  };

  const getSubmitLink = () => {
    if (rememberUsername === 'yes') {
      setShowUserName(true);
    } else if (rememberUsername === 'no') {
      return '/ForgotPassword';
    } else {
      return '#';
    }
  };

  const sendChangePasswordLink = (user) => {
    DataApi({ username: user }, config.BASEURL + 'verifyUsername')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200 && response.data.message != '') {
          let { status } = response;
          if (status === 200) {
            setMessage({
              error: false,
              message: 'Reset password link sent to attached Email address!',
              haveMessage: true,
            });
          }
        } else {
          setMessage({
            error: true,
            message: 'Username does not exist!',
            haveMessage: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username.match(/^[\w.]{2,14}$/i)) {
      setMessage({
        error: true,
        message: 'Username is not valid!',
        haveMessage: true,
      });
    } else {
      DataApi({ username }, config.BASEURL + 'verifyUsername')
        .then((response) => {
          if (response.status === 200 && response.data.statusCode === 200 && response.data.message != '') {
            let { status } = response;
            if (status === 200) {
              setMessage({
                error: false,
                message: 'Reset password link sent to attached Email address!',
                haveMessage: true,
              });
            }
          } else {
            setMessage({
              error: true,
              message: 'Username does not exist!',
              haveMessage: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const haveMessage = (message) => {
    return message.haveMessage && !message.error;
  };

  const haveErrorMessage = (message) => {
    return message.error && message.haveMessage;
  };

  const isNotUserNameScreen = (showUserName, usernamesAvailable) => {
    return !showUserName && !usernamesAvailable;
  };

  const isNotUserNameAndShowScreen = (showUserName, usernamesAvailable) => {
    return showUserName && !usernamesAvailable;
  };

  return (
    <div className={classes.forgotPassword}>
      {usernamesAvailable ? (
        <Container component="main" className={classes.max_Width}>
          <div className={classes.paperWhite}>
            {haveMessage(message) ? <SuccessMessage successes={[message.message]} /> : ''}
            <span className={classes.mu}>Select your username to get the reset link.</span>

            <div className={classes.usernames}>
              {usernames.map((user) => (
                <span onClick={() => sendChangePasswordLink(user.userName)} key={user.id} tabIndex="0" role="button">
                  {user.userName}
                </span>
              ))}
            </div>

            <Grid container>
              <Grid item xs className={classes.text_right}>
                <Link to="/Login" variant="body2">
                  {'Back'}
                </Link>
              </Grid>
            </Grid>
            {haveErrorMessage(message) ? <ErrorMessage errors={[message.message]} /> : ''}
          </div>
        </Container>
      ) : (
        ''
      )}
      {isNotUserNameScreen(showUserName, usernamesAvailable) ? (
        <Container component="main" className={classes.max_Width}>
          <div className={classes.paperWhite}>
            <span>Do you remember your username?</span>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="username-reminder"
                name="username-reminder"
                value={rememberUsername}
                onChange={handleRadioChange}
              >
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </FormControl>
            <Link to={getSubmitLink()} style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={!rememberUsername} // Disable the button if no option is selected
              >
                Submit
              </Button>
            </Link>
            <Grid container>
              <Grid item xs className={classes.text_right}>
                <Link to="/Login" variant="body2">
                  {'Back'}
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      ) : (
        ''
      )}
      {isNotUserNameAndShowScreen(showUserName, usernamesAvailable) ? (
        <Container component="main" className={classes.max_Width}>
          <div className={classes.paperWhite}>
            {haveMessage(message) ? <SuccessMessage successes={[message.message]} /> : ''}
            <div>
              <form className={classes.form} noValidate>
                <Textfield
                  required={true}
                  // error={usernameError}
                  id="username"
                  type="username"
                  labels={'Username'}
                  value={username}
                  width={true}
                  onChange={(e) => setUsername(e.target.value)}
                  focus={true}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                  }}
                />
                <Buttons
                  id="checkEmail"
                  type="button"
                  width={true}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => handleSubmit(e)}
                  text={t('ResetPassword')}
                />
              </form>
            </div>
            {/* </Container> */}
            {haveErrorMessage(message) ? <ErrorMessage errors={[message.message]} /> : ''}
          </div>
        </Container>
      ) : (
        ''
      )}
    </div>
  );
};

Forgot.propTypes = {
  location: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(Forgot);
