import React from 'react';
import PropTypes from 'prop-types';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import './Error.css';

// TenMinsAfterExpiry.js

const formatSingleDigit = (val) => (val >= 0 && val <= 9 ? `0${val}` : val);

class TenMinsAfterExpiry extends React.Component {
  state = { timer: 0 };

  constructor(props) {
    super(props);
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this.setState({ timer: this.props.time * 60 });

    if (this.timer === 0 && this.props.time > 0) this.timer = setInterval(this.countDown, 1000);
  }

  countDown() {
    if (this.state.timer > 0) {
      this.setState((state) => ({ timer: state.timer - 1 }));
    } else {
      clearInterval(this.timer);
      window.location.reload();
    }
  }

  render() {
    const { data } = this.props;

    return (
      <div style={styles.parentStyle}>
        <div style={styles.counterContainerStyle}>
          <CountdownCircleTimer
            isPlaying
            size={190}
            durationSeconds={4}
            onComplete={() => [true, 4]}
            renderTime={(_rt) => (
              <span
                style={{
                  fontWeight: 'bold',
                  padding: '0.2rem',
                  textAlign: 'center',
                }}
              >
                <b> {`Time remaining:`} </b> <br />
                <span style={{ fontSize: '1.2rem', color: '#000' }}>
                  {`${parseInt(this.state.timer / 60)}:${formatSingleDigit(parseInt(this.state.timer % 60))}`}
                </span>
              </span>
            )}
            colors={[['#F93434']]}
          />
        </div>{' '}
        <br />
        <div className="errorPageGeneralStyle msg-container" style={styles.errorDataContainerStyle}>
          {this.props.data ? <p>{data.Error_Data}</p> : ''}
        </div>{' '}
        <br />
        <div className="errorPageGeneralStyle errorPageStyle" style={styles.phoneNumberContainerStyle}>
          {this.props.data ? (
            data.phoneNumber ? (
              <a href={`tel: ${data.phoneNumber}`} style={styles.phoneNumberLinkStyle}>
                {`Click to Call ${data.phoneNumber}`}
              </a>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>
        <h1 style={{ color: '#FFF' }}>OOPS..!</h1>
      </div>
    );
  }
}

const styles = {
  parentStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  counterContainerStyle: {
    borderRadius: '190px',
    backgroundColor: '#F3F918',
  },
  errorDataContainerStyle: {
    backgroundColor: '#F3F918',
    padding: '0 0.5rem',
    border: '1px solid #F3F918',
    borderRadius: '25px',
    fontWeight: 'bold',
  },
  phoneNumberContainerStyle: {
    backgroundColor: '#F3F918',
    padding: '.7rem 0.25rem',
    border: '1px solid #F3F918',
    borderRadius: '10px',
  },
  phoneNumberLinkStyle: {
    backgroundColor: '#F9C218',
    color: '#000',
    padding: '0.3rem 0',
    textDecoration: 'none',
    fontWeight: 'bold',
    textAlign: 'left',
  },
};
TenMinsAfterExpiry.propTypes = {
  time: PropTypes.number,
  data: PropTypes.object,
};
export default TenMinsAfterExpiry;
