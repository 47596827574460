import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import FormControl from '@material-ui/core/FormControl';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { regularUserAdd, fetchDetails } from '../../actions/AdminUserAction';
import { makePhoneNumberForDB } from '../../helpers/util';
import IntegrationDownshift from './../../helpers/multiSelect';
import config from './../../constants/config';
import validator from '../../helpers/checkValidations';
import InputAdornment from '@material-ui/core/InputAdornment';
import LoadingOverlay from 'react-loading-overlay';
import {
  changeHandler,
  getValidationStyle,
  handleClickMenu,
  handleChangeCheckbox,
  getFeeSettingPermissionTitle,
} from './CommonFunction';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: config.REGULAR_USER_ROLE,
      sideShow: true,
      isLoading: true,
      isLocation: false,
      company: '',
      createdBy: '',
      data: [],
      misMatchError: [],
      permissions: {
        refunds: false,
        refundsManual: false,
        allLocaton: false,
        creatNewUser: false,
        createInvoice: false,
        ach: false,
        virtualTerminal: false,
        textToInvoice: false,
        textInvoice: false,
        textNotifications: false,
        recurring: false,
        feeEdit: false,
        exportRecord: false,
        void: false,
        perTransactionLimit: '',
        dailyTransactionLimit: '',
        perRefundLimit: '',
        emailVisibilityTerminal: true,
        notesVisibilityTerminal: true,
        dailyRefundLimit: '',
        emailNotification: false,
        deleteInvoice: false,
        viewAllTransactions: false,
        displayProducts: true,
        displayDashboard: true,
        displaySettings: false,
        displayTickets: true,
        displayLocations: true,
        displayUsers: false,
        displayTransactions: true,
      },
      userData: { permissions: {} },
      isSubmit: false,
      locationSelected: [],
      error: {
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        UserNameError: false,
        dailyRefundLimitError: false,
        dailyTransactionLimitError: false,
        perRefundLimitError: false,
        perTransactionLimitError: false,
      },
      user: JSON.parse(localStorage.getItem('user')),
      marginBottom: '10',
    };
    this.containerRef = React.createRef();
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    let data = fetchDetails({ id: user._id });
    this.setState({
      createdBy: this.state.user._id,
      isLoading: false,
    });
    if (this.props.info.permissions && !this.props.info.permissions.creatNewUser) {
      alert('Access denied!');
      this.props.history.push('/Dashboard');
    }
    data.then((response) => {
      if (response.type === 'FETCH_SUCCESS') {
        this.setState(
          {
            dataLoc: { ...response.payload.userData.location },
            userData: { ...response.payload.userData },
            company: { ...response.payload.userData.company },
          },
          () => {
            Object.keys(this.state.dataLoc).forEach((k, _v) => {
              this.state.data.push(this.state.dataLoc[k]);
            });
            if (this.state.data.length > 0) {
              this.setState({
                locationSelected: Object.keys(this.state.data).map((key, _v) => this.state.data[key].id),
              });
            }
            if (this.state.userData.permissions && !this.state.userData.permissions.creatNewUser) {
              alert('Access denied!');
              this.props.history.push('/Dashboard');
            }
          }
        );
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.locationSelected !== this.state.locationSelected) {
      this.updateMarginBottom();
      getValidationStyle(!this.state.locationSelected && this.state.isSubmit, this.state.marginBottom);
    }
  }

  updateMarginBottom() {
    const container = this.containerRef.current;
    if (container.scrollHeight > container.clientHeight) {
      this.setState({ marginBottom: container.scrollHeight });
    }
  }

  handleClickMenu(_e) {
    const sideShow = handleClickMenu(this.state);
    this.setState({ sideShow });
  }

  handleChangeCheckboxes(event) {
    const permissions = handleChangeCheckbox(event, this.state);
    this.setState({ permissions });
  }

  handleChanges(event, _t) {
    const { id, value, misMatchError, error, validationMarkup, permissions, validatePhone } = changeHandler(
      event,
      this.state
    );

    if (!validationMarkup || !validatePhone) {
      return false;
    }
    this.setState({
      [id]: value,
      permissions,
      error,
      misMatchError,
      isSubmit: false,
      successMessages: false,
    });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });
    Object.keys(state).forEach((item) => {
      if (
        item === 'phone' &&
        !this.state.permissions.textToInvoice &&
        !this.state.permissions.textInvoice &&
        !this.state.permissions.textFlex
      ) {
        return false;
      }
      if (validator(item, state[item])) {
        return validator(item, state[item])
          ? ((error[item + 'Error'] = true), (errorFlag = true))
          : (error[item + 'Error'] = false);
      } else {
        return state[item] === '' || (typeof state[item] === 'string' && state[item].trim() === '')
          ? ((error[item + 'Error'] = true), (errorFlag = true))
          : '';
      }
    });

    Object.keys(this.state.permissions).forEach((keyValue, _value) => {
      if (keyValue === 'phoneNumber' || keyValue === 'amount') {
        return false;
      }
      const errorName = keyValue + 'Error';
      if (validator(keyValue, state.permissions[keyValue])) {
        return validator(keyValue, state.permissions[keyValue])
          ? ((error[errorName] = true), (errorFlag = true))
          : (error[errorName] = false);
      } else {
        return state.permissions[keyValue] === '' ||
          (typeof state.permissions[keyValue] === 'string' && state.permissions[keyValue].trim() === '')
          ? ((error[errorName] = true), (errorFlag = true))
          : (error[errorName] = false);
      }
    });

    if (state.locationSelected.length === 0) {
      misMatchError.push(t('LocationRequired'));
      errorFlag = true;
      this.setState({ misMatchError });
    }

    if (errorFlag) {
      window.scrollTo(0, 0, 0);
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      console.log(makePhoneNumberForDB(this.state.phone));
      this.setState({
        isLoading: true,
      });
      this.props
        .regularUserAdd({ ...this.state, phone: makePhoneNumberForDB(this.state.phone) })
        .then((resultValue) => {
          window.scrollTo(0, 0, 0);
          if (resultValue !== undefined && resultValue.type === 'USER_SUCCESS') {
            this.setState({ successMessages: true, misMatchError, isLoading: false }, () => {
              setTimeout(() => {
                this.setState({ redirect: true, successMessages: false });
              }, 2000);
            });
          }
          if (resultValue !== undefined && resultValue.type === 'USER_ERROR') {
            this.setState({
              misMatchError: this.props.info.userInfo.data.message,
              successMessages,
              isLoading: false,
            });
          }
        });
    }
  }

  addLocation = (arr) => {
    let misMatchError = [];
    if (arr) {
      this.setState({ locationSelected: arr, misMatchError });
    }
  };

  render() {
    const { t } = this.props;
    const { data, redirect } = this.state;
    const addUserCurrencyTextFieldProps = {
      currencySymbol: '$',
      outputFormat: 'string',
      decimalCharacter: '.',
      digitGroupSeparator: ',',
      textAlign: 'left',
    };
    return (
      <div className="rightPanel">
        {redirect ? <Redirect to="/Users" /> : ''}
        <Topheader />
        <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content..">
          <Container component="main" maxWidth="xl">
            {this.state.successMessages ? <SuccessMessage successes={[this.props.info.userInfo.data.message]} /> : ''}
            {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}

            <form className={classes.form} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required
                    error={this.state.error.userNameError}
                    id="userName"
                    type="text"
                    labels={t('Username')}
                    value={this.state.userName}
                    width
                    onChange={(event) => this.handleChanges(event, t)}
                    focus
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required
                    error={this.state.error.firstNameError}
                    id="firstName"
                    type="text"
                    labels={t('FirstName')}
                    value={this.state.firstName}
                    width
                    onChange={(event) => this.handleChanges(event, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required
                    error={this.state.error.lastNameError}
                    id="lastName"
                    type="text"
                    labels={t('LastName')}
                    value={this.state.lastName}
                    width
                    onChange={(event) => this.handleChanges(event, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={
                      this.state.permissions.textToInvoice ||
                      this.state.permissions.textInvoice ||
                      this.state.permissions.textFlex
                    }
                    error={
                      this.state.permissions.textToInvoice ||
                      this.state.permissions.textInvoice ||
                      this.state.error.phoneError ||
                      this.state.permissions.textFlex
                        ? this.state.error.phoneError
                        : false
                    }
                    id="phone"
                    type="text"
                    labels={t('Phone')}
                    value={this.state.phone}
                    width
                    onChange={(event) => this.handleChanges(event, t)}
                    focus
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required
                    error={this.state.error.emailError}
                    id="email"
                    type="email"
                    labels={t('Email')}
                    value={this.state.email}
                    width
                    onChange={(event) => this.handleChanges(event, t)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    style={getValidationStyle(
                      !this.state.locationSelected && this.state.isSubmit,
                      this.state.marginBottom
                    )}
                    ref={this.containerRef}
                  >
                    <IntegrationDownshift
                      isLocationExists={true}
                      selectedLocations={data}
                      locationData={
                        this.state.user.role === 1 || this.state.user.role === 2 ? data : this.state.user.location
                      }
                      addLocation={this.addLocation}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} />
                <Grid item xs={6} sm={3}>
                  <CurrencyTextField
                    error={this.state.error.perTransactionLimitError}
                    required
                    placeholder="0.00"
                    type="text"
                    id="perTransactionLimit"
                    value={this.state.permissions.perTransactionLimit}
                    width
                    style={{ width: '100%' }}
                    onChange={(event) => this.handleChanges(event, t)}
                    label={t('PerTransactionLimit')}
                    {...addUserCurrencyTextFieldProps}
                    onBlur={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          perTransactionLimit: value,
                        },
                        perTransactionLimit: value,
                      });
                    }}
                    onFocus={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          perTransactionLimit: value,
                        },
                        perTransactionLimit: value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <CurrencyTextField
                    {...addUserCurrencyTextFieldProps}
                    required
                    id="dailyTransactionLimit"
                    placeholder="0.00"
                    type="text"
                    value={this.state.permissions.dailyTransactionLimit}
                    label={t('DailyTransactionLimit')}
                    onChange={(event) => this.handleChanges(event, t)}
                    style={{ width: '100%' }}
                    onFocus={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          dailyTransactionLimit: value,
                        },
                        dailyTransactionLimit: value,
                      });
                    }}
                    error={this.state.error.dailyTransactionLimitError}
                    onBlur={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          dailyTransactionLimit: value,
                        },
                        dailyTransactionLimit: value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <CurrencyTextField
                    type="text"
                    id="perRefundLimit"
                    {...addUserCurrencyTextFieldProps}
                    required
                    value={this.state.permissions.perRefundLimit}
                    placeholder="0.00"
                    onChange={(event) => this.handleChanges(event, t)}
                    label={t('PerRefundLimit')}
                    style={{ width: '100%' }}
                    error={this.state.error.perRefundLimitError}
                    onBlur={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          perRefundLimit: value,
                        },
                        perRefundLimit: value,
                      });
                    }}
                    onFocus={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          perRefundLimit: value,
                        },
                        perRefundLimit: value,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <CurrencyTextField
                    required
                    error={this.state.error.dailyRefundLimitError}
                    value={this.state.permissions.dailyRefundLimit}
                    onChange={(event) => this.handleChanges(event, t)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    type="text"
                    {...addUserCurrencyTextFieldProps}
                    id="dailyRefundLimit"
                    placeholder="0.00"
                    width
                    label={t('DailyRefundLimit')}
                    style={{ width: '100%' }}
                    onFocus={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          dailyRefundLimit: value,
                        },
                        dailyRefundLimit: value,
                      });
                    }}
                    onBlur={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          dailyRefundLimit: value,
                        },
                        dailyRefundLimit: value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <h5>Permissions</h5>
                  <Grid container item xs={12}>
                    {this.state.role < 4 ? (
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            labels="creatNewUser"
                            id="creatNewUser"
                            onChange={(e) => {
                              this.handleChangeCheckboxes(e);
                            }}
                            value={this.state.permissions.creatNewUser}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Create New User</span>
                        </label>
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="refunds"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.refunds}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Refunds General</span>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="refundsManual"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.refundsManual}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Refunds Manual</span>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="allLocaton"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.allLocaton}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>All Location</span>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="createInvoice"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.createInvoice}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Invoicing</span>
                      </label>
                    </Grid>
                    {this.state.company.ACH && (
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="ach"
                            onChange={(e) => {
                              this.handleChangeCheckboxes(e);
                            }}
                            value={this.state.permissions.ach}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>ACH </span>
                        </label>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="virtualTerminal"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.virtualTerminal}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Virtual Terminal</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="recurring"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.recurring}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Recurring Billing</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="feeEdit"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.feeEdit}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>{getFeeSettingPermissionTitle(this.state.role)}</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="exportRecord"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.exportRecord}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Export Records</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="void"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.void}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Void</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="emailNotification"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.emailNotification}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>{t('emailNotifications')}</span>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          checked={this.state.permissions.deleteInvoice}
                          id="deleteInvoice"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.deleteInvoice}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>{t('DeleteInvoice')}</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="viewAllTransactions"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.viewAllTransactions}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>View All Transactions</span>
                      </label>
                    </Grid>
                    {this.state.role > 4 ? (
                      <Grid item xs={12} sm={6}>
                        <Checkbox
                          id="creatNewUser"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          value={this.state.permissions.creatNewUser}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <label>
                          <span>Create New User</span>
                        </label>
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            color="primary"
                            id="textToInvoice"
                            value={this.state.permissions.textToInvoice}
                            checked={this.state.permissions.textToInvoice}
                            onChange={(e) => {
                              this.handleChangeCheckboxes(e);
                            }}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Texting</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginLeft: 10 }}>
                        <label>
                          <Checkbox
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                            value={this.state.permissions.textNotifications}
                            checked={this.state.permissions.textNotifications}
                            id="textNotifications"
                            onChange={(e) => {
                              this.handleChangeCheckboxes(e);
                            }}
                            color="primary"
                          />
                          <span>Text Notifications</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginLeft: 10 }}>
                        <label>
                          <Checkbox
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                            value={this.state.permissions.textInvoice}
                            checked={this.state.permissions.textInvoice}
                            id="textInvoice"
                            onChange={(e) => {
                              this.handleChangeCheckboxes(e);
                            }}
                            color="primary"
                          />
                          <span>Text Invoice</span>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {' '}
                  <h4>Terminal Field Display</h4>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="emailVisibilityTerminal"
                          color="primary"
                          onChange={(event) => {
                            this.handleChangeCheckboxes(event);
                          }}
                          checked={this.state.permissions.emailVisibilityTerminal}
                          value={this.state.permissions.emailVisibilityTerminal}
                        />
                        <span>Email</span>
                      </label>
                      <label>
                        <Checkbox
                          id="notesVisibilityTerminal"
                          color="primary"
                          onChange={(event) => {
                            this.handleChangeCheckboxes(event);
                          }}
                          checked={this.state.permissions.notesVisibilityTerminal}
                          value={this.state.notesVisibilityTerminal}
                        />
                        <span>Notes</span>
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <h5>Main Menu Display</h5>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayDashboard"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          checked={this.state.permissions.displayDashboard}
                          value={this.state.permissions.displayDashboard}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Dashboard</span>
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayTickets"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          checked={this.state.permissions.displayTickets}
                          value={this.state.permissions.displayTickets}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Tickets</span>
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayLocations"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          checked={this.state.permissions.displayLocations}
                          value={this.state.permissions.displayLocations}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Locations</span>
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayTransactions"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          checked={this.state.permissions.displayTransactions}
                          value={this.state.permissions.displayTransactions}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Transactions</span>
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayProducts"
                          onChange={(e) => {
                            this.handleChangeCheckboxes(e);
                          }}
                          checked={this.state.permissions.displayProducts}
                          value={this.state.permissions.displayProducts}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Products</span>
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
                <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t('Save')}
                />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t('Back')}
                  onClick={this.props.history.goBack}
                />
              </Grid>
            </form>
          </Container>
        </LoadingOverlay>
      </div>
    );
  }
}
AddUser.propTypes = {
  t: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
  };
}

AddUser.propTypes = {
  regularUserAdd: PropTypes.func,
  info: PropTypes.object,
  history: PropTypes.object,
};

export default connect(mapStateToProps, { fetchDetails, regularUserAdd })(withTranslation()(AddUser));
