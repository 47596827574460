export const handleAddressSettingError = (state) => {
  let misMatchError = [];
  if (state.addressSettings.shippingAddress?.isShippingAddressRequired) {
    if (
      (state.addressSettings.shippingAddress.zip &&
        state.addressSettings.shippingAddress?.isZipRequired &&
        state.shippingAddress?.zip.trim() === '') ||
      (state.addressSettings.shippingAddress.city && state.shippingAddress?.city.trim() === '') ||
      (state.addressSettings.shippingAddress.addressOne && state.shippingAddress?.addressOne.trim()) === '' ||
      (state.addressSettings.shippingAddress.addressTwo && state.shippingAddress?.addressTwo.trim()) === '' ||
      (state.addressSettings.shippingAddress.state && state.shippingAddress?.state.trim() === '')
    ) {
      misMatchError.push('Shipping address details missing.');
      window.scrollTo(0, 0, 0);
      return misMatchError;
    }
  }

  if (state.addressSettings.billingAddress?.isBillingAddressRequired) {
    if (
      (state.addressSettings.billingAddress.zip &&
        state.addressSettings.billingAddress?.isZipRequired &&
        state.billingAddress?.zip.trim() === '') ||
      (state.addressSettings.billingAddress.city && state.billingAddress?.city.trim() === '') ||
      (state.addressSettings.billingAddress.addressOne && state.billingAddress?.addressOne.trim()) === '' ||
      (state.addressSettings.billingAddress.addressTwo && state.billingAddress?.addressTwo.trim()) === '' ||
      (state.addressSettings.billingAddress.state && state.billingAddress?.state.trim() === '')
    ) {
      misMatchError.push('Billing address details missing.');
      window.scrollTo(0, 0, 0);
      return misMatchError;
    }
  }

  return misMatchError;
};
