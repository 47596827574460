import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button } from '@mui/material';
import PropTypes from 'prop-types';

const imageUrl = require('../../../assets/images/warning.png');

const SubmittedToPaxModal = ({ open, handleClose, handleProceed, activeElement }) => {
  const [timer, setTimer] = useState(3);

  useEffect(() => {
    let interval;
    if (open) {
      interval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [open, timer]);

  useEffect(() => {
    if (timer === 0) {
      document.getElementById('proceedButton').disabled = false;
    }
  }, [timer]);

  const handleCloseModal = () => {
    handleClose();
    setTimeout(() => {
      setTimer(3);
    }, 500);
  };

  return (
    <div style={{ padding: '16px' }}>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={open}>
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '5px',
              maxWidth: '50%',
              textAlign: 'center',
            }}
          >
            <img src={imageUrl} alt="Popup" style={{ maxWidth: '100px', height: 'auto', marginBottom: '20px' }} />
            <p style={{ marginBottom: '20px' }}>
              A transaction for this invoice was sent to the Pax Terminal and awaiting approval or cancellation.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
              <Button variant="contained" color="primary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button
                id="proceedButton"
                variant="contained"
                color="warning"
                onClick={(e) => handleProceed(e, activeElement)}
                disabled={timer > 0}
              >
                Proceed anyway {timer > 0 ? `(${timer})` : ''}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

SubmittedToPaxModal.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  handleProceed: PropTypes.func,
  activeElement: PropTypes.string,
};

export default SubmittedToPaxModal;
