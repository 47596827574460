const initialState = () => ({
  receiptTermCondition: '',
  paymentTermCondition: '',
  invoiceTermCondition: '',
  isPaymentTermConditionEnabled: '',
  isReceiptTermConditionEnabled: '',
  isInvoiceTermConditionEnabled: '',
});
const TermsConditionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'TERMSCOND_SUCCESS':
      return { ...state, ...payload };
    default:
      return state;
  }
};

export { TermsConditionReducer };
