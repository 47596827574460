import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { Textfield } from './../../components/Textfield';
import TypableSelect from './TypableSelect';
import { setInputState } from './../../actions/RequestFormAction';

const mapToFormat = (values) => {
  const res = [];

  for (let i = 0; i < values.length; i++) {
    res.push({ value: values[i], label: values[i] });
  }

  return res;
};

const getValidationStyle = (cond) => {
  if (cond) {
    return { width: '100%', marginTop: '1.6rem', border: '2px solid red' };
  } else {
    return { width: '100%', marginTop: '2.80rem' };
  }
};

class VehicleInfo extends React.Component {
  OnchangeDropDown(val, property, key) {
    this.props.setInputState(property, val, key);
  }
  render() {
    const { t, onChange, error } = this.props;
    const customInvoicePageFields = this.props?.fields;
    const MAKE = [
      `AM General`,
      `AMC`,
      `Acura`,
      `Alfa Romeo`,
      `Aston Martin`,
      `Audi`,
      `BACKDRAFT`,
      `BMW`,
      `Bentley`,
      `Buick`,
      `Cadillac`,
      `Chevrolet`,
      `Chrysler`,
      `Daewoo`,
      `Daihatsu`,
      `Datsun`,
      `Dodge`,
      `EAGLE`,
      `EXCALIBUR`,
      `FIAT`,
      `Ferrari`,
      `Ford`,
      `GEO`,
      `GMC`,
      `GRUMMAN`,
      `Honda`,
      `Hummer`,
      `Hyundai`,
      `INTERNATIONAL`,
      `Infiniti`,
      `Isuzu`,
      `Jaguar`,
      `Jeep`,
      `Kia`,
      `Lamborghini`,
      `Land Rover`,
      `Lexus`,
      `Lincoln`,
      `Lotus`,
      `MARUTI`,
      `MG`,
      `Maserati`,
      `Maybach`,
      `Mazda`,
      `Mercedes Benz`,
      `Mercury`,
      `Merkur`,
      `Mini`,
      `Mitsubishi`,
      `Nissan`,
      `OTHER`,
      `Oldsmobile`,
      `Peugeot`,
      `Plymouth`,
      `Pontiac`,
      `Porsche`,
      `Rolls Royce`,
      `Saab`,
      `Saturn`,
      `Scion`,
      `Smart`,
      `Sterling`,
      `Subaru`,
      `Suzuki`,
      `Tesla`,
      `Toyota`,
      `Volkswagen`,
      `Volvo`,
      `WHEEGO`,
      `WILLYS`,
      `Yugo`,
    ];

    const COLOR = [
      `BEIGE`,
      `BLACK`,
      `BLUE`,
      `BROWN`,
      `BURGUNDY`,
      `CHAMPAGNE`,
      `GOLD`,
      `GRAY`,
      `GRAY-VIOLET`,
      `GREEN`,
      `LIGHT BLUE`,
      `LIGHT BROWN`,
      `LIGHT GRAY`,
      `LIGHT GREEN`,
      `MAROON`,
      `OLIVE-GREEN`,
      `ORANGE`,
      `PEARL-WHITE`,
      `PEWTER`,
      `PINK`,
      `PURPLE`,
      `RED`,
      `SAND`,
      `SILVER`,
      `TAN`,
      `TEAL`,
      `WHITE`,
      `YELLOW`,
    ];
    return (
      <div>
        <strong> Vehicle Info. </strong>
        <FormControl fullWidth>
          <Grid container align="center">
            <Grid item xs={1} sm={2} md={2} />
            <Grid item xs={10} sm={8} md={8}>
              <Grid container align="center">
                {customInvoicePageFields &&
                  customInvoicePageFields.slice(3, 4).map((field, index) =>
                    field.enabled ? (
                      <Grid item xs={12} sm={4} md={4}>
                        <FormControl>
                          <Textfield
                            id={`field${index + 4}`}
                            type="text"
                            error={error[`field${index + 4}Error`]}
                            required={field.required}
                            labels={t(field.value)}
                            value={this.props.input[`field${index + 4}`].value}
                            width={true}
                            onChange={(e) => onChange(e, field.value)}
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      ''
                    )
                  )}
                {customInvoicePageFields &&
                  customInvoicePageFields.slice(5, 7).map((field, index) =>
                    field.enabled ? (
                      <Grid className="custom-liststyle" item xs={12} sm={4} md={4}>
                        <FormControl
                          // style={getValidationStyle(!make && touched['make'])}
                          style={getValidationStyle(error[`field${index + 6}Error`])}
                          error={error[`field${index + 6}Error`]}
                          required={field.required}
                        >
                          <TypableSelect
                            id={`field${index + 6}`}
                            required={field.required}
                            value={this.props.input[`field${index + 6}`].value}
                            placeholder={field.value}
                            suggestions={field.value.toUpperCase() === 'MAKE' ? mapToFormat(MAKE) : mapToFormat(COLOR)}
                            onChange={(val) => this.OnchangeDropDown(val, `field${index + 6}`, field.value)} // isInvalid={!make && touched['make']}
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      ''
                    )
                  )}
                {customInvoicePageFields &&
                  customInvoicePageFields.slice(4, 5).map((field, index) =>
                    field.enabled ? (
                      <Grid item xs={12} sm={4} md={4}>
                        <FormControl>
                          <Textfield
                            id={`field${index + 5}`}
                            type="text"
                            labels={t(field.value)}
                            value={this.props.input[`field${index + 5}`].value}
                            error={error[`field${index + 5}Error`]}
                            required={field.required}
                            width={true}
                            onChange={(e) => onChange(e, field.value)}
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      ''
                    )
                  )}
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </div>
    );
  }
}

VehicleInfo.propTypes = {
  t: PropTypes.func,
  setInputState: PropTypes.func,
  input: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.any,
  fields: PropTypes.any,
};
//   const mapStateToProps = ({ input: { year, make, model, color, touched } }) => ({ year, make, model, color, touched });
function mapStateToProps(state) {
  return {
    input: state.InputReducer,
  };
}

export default connect(mapStateToProps, { setInputState })(withTranslation()(VehicleInfo));
