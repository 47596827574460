import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Textfield } from '../../../components/Textfield';
import InputAdornment from '@material-ui/core/InputAdornment';

Fee.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  valueError: PropTypes.bool,
  nameValue: PropTypes.string,
  typeValue: PropTypes.string,
  amountValue: PropTypes.number,
  nameLabel: PropTypes.string,
  typeLabel: PropTypes.string,
  valueLabel: PropTypes.string,
  valueID: PropTypes.string,
  onNameChange: PropTypes.func,
  onValueChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  disabled: PropTypes.bool,
  feeInputType: PropTypes.any,
  allowTextField: PropTypes.bool,
};

export default function Fee({
  id,
  error,
  valueError,
  nameValue,
  typeValue,
  amountValue,
  nameLabel,
  typeLabel,
  valueLabel,
  valueID,
  onNameChange,
  onTypeChange,
  onValueChange,
  disabled = false,
  feeInputType = 'text',
  allowTextField = true,
}) {
  return (
    // <Grid item xs={12} className="discount-label-field">
    //   {topLabel ? <label>{label}</label> : <></>}
    //   <FormControlLabel control={<IOSSwitch checked={checked} onChange={onChange} value={checked} />} />
    //   {!topLabel ? <label className="mr-2">{label}</label> : <></>}
    // </Grid>
    <>
      <Grid item sm={12} xs={10} md={10} lg={9} id={`feename-${id}`} className="tax-select-field taxSelect">
        <label className="fee-name-label-settings">{nameLabel}: </label>
        <Textfield
          required={true}
          error={error}
          id={id}
          type="text"
          value={nameValue}
          width={true}
          onChange={onNameChange}
          focus={true}
          disabled={disabled}
        />
      </Grid>

      {allowTextField && (
        <>
          <Grid item xs={12} sm={12} md={8} lg={4} id={`feetype-${id}`} className="tax-select-field taxSelect">
            <label>{typeLabel}</label>
            <select value={typeValue} onChange={onTypeChange}>
              <option value="%">%</option>
              <option value="$">$</option>
            </select>
          </Grid>

          <Grid item xs={10} sm={12} md={6} id={`feevalue-${id}`} className="invoice-svc-fee-field">
            <Textfield
              required={true}
              error={valueError}
              InputProps={{
                startAdornment: <InputAdornment position="start">{typeValue}</InputAdornment>,
              }}
              id={valueID}
              type={feeInputType}
              labels={valueLabel}
              value={amountValue}
              width={true}
              onChange={onValueChange}
            />
          </Grid>
        </>
      )}
    </>
  );
}
