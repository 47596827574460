exports.setEventListners = () => {
  document.addEventListener('input', function (event) {
    const saveCustomerButton = document.getElementById('saveCustomer');
    if (event.target && event.target.id === 'tsep-cardNum') {
      setMaxLength();
      setMaxLengthCvv();
      setMaxWidth();
    }

    if (event.target && event.target.id === 'tsep-cardNum' && saveCustomerButton !== null) {
      setCustomerSaveButton();
    }
  });
};

exports.unsetEventListners = () => {
  document.removeEventListener('input');
};

const setCustomerSaveButton = () => {
  const inputField = document.getElementById('tsep-cardNum');
  const saveCustomerButton = document.getElementById('saveCustomer');
  console.log(inputField.value, '---input field');
  if (inputField.value !== '' && saveCustomerButton !== null) {
    document.getElementById('saveCustomer').disabled = true;
  } else {
    document.getElementById('saveCustomer').disabled = false;
  }
};

const setMaxWidth = () => {
  const inputField = document.getElementById('tsep-cardNum');
  if (inputField) {
    const classList = inputField.classList;
    inputField.style.maxWidth = '175px';
    const visa = classList.contains('visa');
    const masterCard = classList.contains('mastercard');
    const discover = classList.contains('discover');
    const amex = classList.contains('amex');

    if (visa || discover || masterCard) {
      inputField.style.maxWidth = '164px';
    } else if (amex) {
      inputField.style.maxWidth = '153px';
    } else {
      inputField.style.maxWidth = '175px';
    }
  }
};

const setMaxLength = () => {
  const inputField = document.getElementById('tsep-cardNum');
  if (inputField) {
    let maxLength = 24;
    const classList = inputField.classList;
    const visa = classList.contains('visa');
    const masterCard = classList.contains('mastercard');
    const discover = classList.contains('discover');
    const amex = classList.contains('amex');

    if (visa || masterCard || discover) {
      maxLength = 16 + 3; // 16 maxLength and plus 3 space character
    } else if (amex) {
      maxLength = 15 + 2; // 15 maxLength and plus 2 space character
    }
    inputField.maxLength = maxLength; // Set maxlength property directly
  } else {
    console.log('Input field with ID "tsep-cardNum" not found.');
  }
};

const setMaxLengthCvv = () => {
  const inputField = document.getElementById('tsep-cardNum');
  const cvvField = document.getElementById('tsep-cvv2');
  if (inputField && inputField.value.trim() !== '' && cvvField) {
    let maxLength = 4;
    const classList = inputField.classList;
    const visaCard = classList.contains('visa');
    const masterCard = classList.contains('mastercard');
    const discoverCard = classList.contains('discover');
    if (visaCard || discoverCard || masterCard) {
      maxLength = 3; // 16 maxLength and plus 3 space character
    }
    cvvField.maxLength = maxLength; // Set maxlength property directly
  } else {
    console.log('Input field with ID "tsep-cardNum" not found.');
  }
};
