import { ActionType, config } from '../constants';
import { DataGetApi } from '../helpers/PostDataApi';

/**
 * Environment Variables Begin Action
 */
export const environmentVariablesStart = () => ({
  type: ActionType.ENV_VAR_START,
});

/**
 * Environment Variables Success Action
 */
export const environmentVariablesSuccess = (data) => {
  return {
    type: ActionType.ENV_VAR_SUCCESS,
    payload: { data },
  };
};

/**
 * Environment Variables Failure Action
 */
export const environmentVariablesError = (error) => ({
  type: ActionType.ENV_VAR_ERROR,
  payload: { error },
});

/**
 *
 * @param {*} data
 * @returns dispatched action that sets env vars, or error if failed
 */
export const getEnvironmentVariablesList = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user?.token;
  let apiUrl = 'listVariables';
  if (!user) {
    apiUrl = 'listVariablesWithoutToken';
  }
  return (dispatch) => {
    dispatch(environmentVariablesStart());
    return DataGetApi(config.BASEURL + apiUrl, {}, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(environmentVariablesSuccess(response.data));
        } else {
          return dispatch(environmentVariablesError(response));
        }
      })
      .catch((err) => {
        return dispatch(environmentVariablesError(err));
      });
  };
};
