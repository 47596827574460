import { ActionType } from './../constants';
import { DataApi } from './../helpers/PostDataApi';
import { config } from './../constants';
import cookie from 'react-cookies';
import getStartPage from './../helpers/getStartPage';
/**
 * User info Begin Action
 */
export const loginStart = () => ({
  type: ActionType.LOGIN_START,
});

/**
 * User info Success Action
 */
export const loginSuccess = (loginData) => {
  return {
    type: ActionType.LOGIN_FETCH_DATA,
    payload: { loginData },
  };
};

export const setLocationOnSuccess = (loginData) => {
  if (
    Object.prototype.hasOwnProperty.call(loginData, 'defaultLocation') &&
    Object.prototype.hasOwnProperty.call(loginData, 'location') &&
    loginData.defaultLocation !== null
  ) {
    let locationIds = loginData.location.filter((curr) => {
      return curr._id == loginData.defaultLocation;
    });
    return {
      type: ActionType.SET_LOCATION,
      payload: locationIds[0],
    };
  } else {
    return {
      type: ActionType.SET_All_LOCATION,
    };
  }
};

/**
 * User info Failure Action
 */
export const loginError = (error) => ({
  type: ActionType.LOGIN_ERROR,
  payload: { error },
});

/**
 * User driver info
 */
export const login = (data, props) => {
  return (dispatch) => {
    dispatch(loginStart());
    return DataApi(data, config.BASEURL + 'logIn')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          dispatch(loginSuccess(response.data.response));
          dispatch(setLocationOnSuccess(response.data.response));
          localStorage.setItem('user', JSON.stringify(response.data.response));
          if (response.data.response.location) {
            localStorage.setItem('locationPaydetails', JSON.stringify(response.data.response.location[0]));
          }
          localStorage.setItem('token', JSON.stringify(response.data.response.token));
          localStorage.setItem('refreshToken', JSON.stringify(response.data.response.refreshToken));
          cookie.save('user', response.data.response, { path: '/' });
          if (response?.data?.response?.role === config.CUSTOMER_USER_ROLE) {
            props.history.push(getStartPage(response?.data?.response?.permissions, true));
          } else {
            props.history.push(getStartPage(response?.data?.response?.permissions));
          }
        } else {
          return dispatch(loginError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
