const isValidKey = (key) => {
  let val = localStorage.getItem(key);
  if (val) {
    if (val !== 'undefined' && val !== undefined) {
      return true;
    }
  }
  return false;
};

export const whichLocationSelected = () => {
  if (isValidKey('locationPaydetails')) {
    return JSON.parse(localStorage.getItem('locationPaydetails'));
  } else {
    if (isValidKey('locationId') && isValidKey('user')) {
      const selectedLocationId = localStorage.getItem('locationId').replaceAll('"', '');
      const user = JSON.parse(localStorage.getItem('user'));
      if (user?.location.length) {
        let location = user.location.filter((loc) => loc.id === selectedLocationId);
        if (location.length) {
          return location[0];
        } else {
          return {};
        }
      }
    }
    return {};
  }
};
