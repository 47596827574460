import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { changeFocus } from '../../helpers/changeFocusCard';
import PropTypes from 'prop-types';
import classes from './Payment.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Buttons } from './../../components/Buttons';
import { Textfield } from './../../components/Textfield';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import config from './../../constants/config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaskedInput from 'react-maskedinput';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import LoadingOverlay from 'react-loading-overlay';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { formatCurrency } from '../../helpers/commonFunction';
import getCardType from '../../helpers/cardType';
import { rules } from '../../constants/IntakeFormConstants';
import { getOntimeCustomerDataForReccurringPay } from '../../actions/PaymentAction';
import { createRecurringByLink } from '../../actions/RequestFormAction';
import Stack from '@mui/material/Stack';
import { CloseableAlerts } from './../../components/Message/CloseableAlerts';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import validator from '../../helpers/checkValidations';
import { useTranslation } from 'react-i18next';
const OnTimeRecurringPayment = () => {
  const { t } = useTranslation();
  const paytraceAwsPath = useSelector((state) => state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH);
  const { id } = useParams();
  const date = new Date();
  const [isLoading, setIsLoading] = useState(false);
  const [payerInfo, setPayerInfo] = useState({
    zip: '',
    address: '',
    payerName: '',
  });
  const [tabValue, setTabValue] = useState(0);
  const [misMatchError, setMisMatchError] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const [errorCard, setErrorCard] = useState({
    cardNumber: false,
    cardHolderName: false,
    cardExpiryDate: false,
    cardCsc: false,
  });
  const [error, setError] = useState({
    amountError: false,
    payerNameError: false,
    zipError: false,
    addressError: false,
  });
  const [card, setCard] = useState({
    isOpen: false,
    cardHolderName: '',
    cardNumber: '_______________',
    cardExpiryDate: '__/____',
    cardCsc: '___',
    customerToken: false,
    customerTokenUpdate: false,
    encryptedNumber: '',
    encryptedCsc: '',
  });
  const dispatch = useDispatch();
  const [americanExpress, setAmericanExpress] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [recurringStartDate, setRrecurringStartDate] = useState(date);
  const [recurringEndDate, setRrecurringEndDate] = useState(date);
  const [amount, setAmount] = useState(0);
  // const [total, setTotal] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  const [frequency, setFrequency] = useState('');
  const [image, setImage] = useState('');
  const [paytraceCredentials, setPaytraceCredentials] = useState({
    paytraceKey: '',
    userName: '',
    password: '',
  });
  const [isRecurranceCreated, setIsRecurranceCreated] = useState(false);
  const [success, setSuccess] = useState(false);

  const expiryDateRef = useRef(null);
  const cscCardRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getOntimeCustomerDataForReccurringPay(id)).then((res) => {
      setFirstName(res.payload.paymentData.data.response.firstName);
      setLastName(res.payload.paymentData.data.response.lastName);
      setRrecurringStartDate(res.payload.paymentData.data.response.startDate);
      setRrecurringEndDate(res.payload.paymentData.data.response.endDate);
      setAmount(res.payload.paymentData.data.response.amount);
      setTotalCount(res.payload.paymentData.data.response.totalCount);
      setFrequency(res.payload.paymentData.data.response.frequency);
      setImage(res.payload.paymentData.data.response.locationData.image);
      setIsRecurranceCreated(res.payload.paymentData.data.response.isRecurranceCreated);
      setPaytraceCredentials({
        paytraceKey: res.payload.paymentData.data.response.locationData.paytraceKey,
        userName: res.payload.paymentData.data.response.locationData.paytraceUserName,
        password: res.payload.paymentData.data.response.locationData.paytracePassword,
      });
      setIsLoading(false);
    });
  }, [dispatch, id]);

  useEffect(() => {
    if (paytraceCredentials?.paytraceKey) {
      const localUrl = config.BASEURL + 'getPemFile?fileName=';
      window.paytrace.setKeyAjax(localUrl + paytraceCredentials?.paytraceKey);
    }
  }, [paytraceCredentials]);

  const a11yProps = (index) => {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
  };

  const handleChangeTab = (e, val) => {
    setTabValue({ tabValue: val });
  };
  const handleChange = (event) => {
    let error = {};
    let { id, value } = event.target;

    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    setError(error);
    if (id === 'address') {
      setPayerInfo((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }));
    }
    if (id === 'zip') {
      setPayerInfo((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }));
    }
    if (id === 'payerName' && value !== '' && typeof value === 'string') {
      setPayerInfo((prevInfo) => ({ ...prevInfo, payerName: value.charAt(0).toUpperCase() + value.slice(1) }));
      setCard((prevCard) => ({ ...prevCard, cardHolderName: value.charAt(0).toUpperCase() + value.slice(1) }));
    }
  };
  const handleChangeCard = (event, _t) => {
    let error = { ...errorCard };
    let misMatchError = [];
    let { id, value } = event.target;

    validator(id, value) ? (error[id] = true) : (error[id] = false);
    if (id === 'cardNumber' && value[0] === '3' && value.length === 16 && value[value.length - 1] === '_') {
      value = value.slice(0, -1);
    }

    setCard((prevCard) => ({
      ...prevCard,
      [id]: value,
      customerToken: true,
    }));
    setErrorCard(error);
    setError(error);
    setMisMatchError(misMatchError);
    setIsSubmit(false);

    let firstNumber = card.cardNumber;
    if (firstNumber[0] === '3') {
      setAmericanExpress(true);
    } else {
      setAmericanExpress(false);
    }

    changeFocus(id, value, expiryDateRef.current, cscCardRef.current);
  };
  const removeCloseableMessage = (index) => {
    setMisMatchError((prevMisMatchError) => {
      // Create a new array without the element at the specified index
      const updatedMisMatchError = prevMisMatchError.filter((_, i) => i !== index);
      return updatedMisMatchError;
    });
  };
  const checkCardValues = () => {
    if (tabValue === 0) {
      if (card.cardNumber.indexOf('_') > -1 || card.cardNumber === '') {
        setErrorCard((prevErrorCard) => ({ ...prevErrorCard, cardNumber: true }));
        return false;
      } else if (card.cardExpiryDate.indexOf('_') > -1 || card.cardExpiryDate === '') {
        setErrorCard((prevErrorCard) => ({ ...prevErrorCard, cardExpiryDate: true }));
        return false;
      } else if (card.cardCsc.indexOf('_') > -1 || card.cardCsc === '') {
        setErrorCard((prevErrorCard) => ({ ...prevErrorCard, cardCsc: true }));
        return false;
      } else {
        return true;
      }
    }
  };
  const checkFormValue = () => {
    if (payerInfo.payerName === '') {
      setError((prevError) => ({ ...prevError, payerNameError: true }));
      return false;
    } else if (payerInfo.zip === '') {
      setError((prevError) => ({ ...prevError, zipError: true }));
      return false;
    } else if (payerInfo.address === '') {
      setError((prevError) => ({ ...prevError, addressError: true }));
      return false;
    } else {
      return true;
    }
  };
  const collectAllMisMatchError = (res, misMatchError) => {
    if (res.payload.error.data.response) {
      let responseErrors = res.payload.error.data.response;
      if (responseErrors.response_code === 1) {
        for (let key in responseErrors.errors) {
          let i = 0;
          misMatchError.push(responseErrors.errors[key][i] + '\n');
        }
      }
      if (responseErrors.response_code === 1001) {
        misMatchError.push(responseErrors.status_message);
      }

      setMisMatchError(misMatchError);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkCardValues() && checkFormValue()) {
      setIsLoading(true);
      setIsSubmit(true);
      window.paytrace.submitEncrypted('#DemoForm');

      const encrptedNumber = localStorage.getItem('0');
      const encrptedCsc = localStorage.getItem('1');

      setCard((prevCard) => ({
        ...prevCard,
        cardType: getCardType(card.cardNumber.charAt(0)),
        cardHolderName: payerInfo.payerName,
      }));

      const data = {
        ...card,
        zip: payerInfo.zip,
        encryptedNumber: encrptedNumber,
        encryptedCsc: encrptedCsc,
        address: payerInfo.address,
        hash: id,
      };
      dispatch(createRecurringByLink(data))
        .then((res) => {
          if (res !== undefined && res?.type === 'INVOICE_SUCCESS') {
            setIsRecurranceCreated(res?.payload?.invoiceData?.data?.response?.isRecurranceCreated);
            setSuccess(true);
            setIsLoading(false);
            setSuccessMessage(res?.payload?.invoiceData?.data?.message);
          } else if (res !== undefined && res.type === 'INVOICE_ERROR') {
            if (res?.payload?.error.data) {
              const misMatchError = [];
              let message = res?.payload?.error?.data?.message;
              misMatchError.push(message);
              collectAllMisMatchError(res, misMatchError);
              setSuccess(false);
              setIsLoading(false);
              setIsSubmit(false);
              setSuccessMessage([]);
            }
          } else {
            setSuccess(false);
            setIsLoading(false);
            setIsSubmit(false);
            setSuccessMessage([]);
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setSuccess(false);
          setIsLoading(false);
          setSuccessMessage([]);
          setMisMatchError(err);
        });
    }
  };

  return (
    <Container component="main" maxWidth={false} className="terminal-container">
      <Stack sx={{ width: '100%' }} spacing={2}>
        {misMatchError.map((e, id) => (
          <CloseableAlerts key={id} index={id} message={e} severity="error" closer={removeCloseableMessage} />
        ))}
      </Stack>
      {success ? <SuccessMessage successes={[successMessage]} /> : ''}
      <div className="min-wrap pay-page">
        <LoadingOverlay className={classes.tpTableFullW} active={isLoading} spinner text="Fetching your response ...">
          {success ? (
            <>
              <h3 className="noteMsg">Thank you for your business.</h3>
            </>
          ) : (
            <>
              {isRecurranceCreated ? (
                <h3 className="errorMsg mb-3">Recurrence has been created already.</h3>
              ) : (
                <Grid container spacing={3}>
                  <Grid item md={8}>
                    <Card>
                      <CardContent>
                        <div className="terminal-inner">
                          <Grid container className="terminal-title-grid" spacing={3} alignItems="center">
                            <Grid item sm={3}>
                              <img
                                className="terminal-logo"
                                alt="logo"
                                src={image ? paytraceAwsPath + image : config.APPURL + '/assets/images/logo.png'}
                              />
                            </Grid>
                            <Grid item sm={9}>
                              <div className="borderd"> OnTime Service</div>
                            </Grid>
                          </Grid>
                          <form className={classes.form} id="DemoForm">
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid container>
                                  <Grid item md={12} className="mb-3">
                                    <label>Payment Amount</label>
                                    <div className="pay-amount">{formatCurrency(amount)}</div>
                                  </Grid>
                                  <AppBar position="static" color="default">
                                    <Tabs
                                      value={tabValue}
                                      onChange={(e, val) => {
                                        handleChangeTab(e, val);
                                      }}
                                      indicatorColor="primary"
                                      textColor="primary"
                                      variant="fullWidth"
                                      aria-label="action tabs example"
                                    >
                                      <Tab label="Credit Card" {...a11yProps(0)} />
                                    </Tabs>
                                  </AppBar>
                                  {/* <TabPanel value={tabValue} index={0}> */}
                                  <Grid container spacing={3}>
                                    <Grid item md={12}>
                                      <Textfield
                                        style={{ marginTop: '16px' }}
                                        error={error.payerNameError}
                                        helperText={error.payerNameError ? rules.payerName.messages.characterlimit : ''}
                                        id="payerName"
                                        type="text"
                                        variant="outlined"
                                        labels={'Name'}
                                        value={payerInfo.payerName}
                                        width={true}
                                        autoFocus={true}
                                        onChange={handleChange}
                                      />
                                    </Grid>
                                    <Grid item md={12}>
                                      {errorCard.cardNumber ? (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            errorCard.cardNumber ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardNumberInvalid')}
                                        </label>
                                      ) : (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            errorCard.cardNumber ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardNumber')}
                                        </label>
                                      )}
                                      <MaskedInput
                                        type="text"
                                        value={card.cardNumber}
                                        className={classNames([classes.cardNum, 'pt-encrypt'])}
                                        mask={americanExpress ? '111111111111111' : '1111111111111111'}
                                        labels="Card Number"
                                        id="cardNumber"
                                        name="encryptedNumber"
                                        size="20"
                                        onChange={handleChangeCard}
                                      />
                                    </Grid>
                                    <Grid item md={4}>
                                      {errorCard.cardExpiryDate ? (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            errorCard.cardExpiryDate ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardExpiryDateInvalid')}
                                        </label>
                                      ) : (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            errorCard.cardExpiryDate ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardExpiryDate')}
                                        </label>
                                      )}
                                      <MaskedInput
                                        mask="11/1111"
                                        className={classes.expDate}
                                        value={card.cardExpiryDate}
                                        name="cardExpiryDate"
                                        id="cardExpiryDate"
                                        placeholder="mm/yyyy"
                                        ref={expiryDateRef}
                                        onChange={handleChangeCard}
                                      />
                                    </Grid>
                                    <Grid item md={2}>
                                      {errorCard.cardCsc ? (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            errorCard.cardCsc ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardCscInvalid')}
                                        </label>
                                      ) : (
                                        <label
                                          className={classNames([
                                            'MuiTypography-gutterBottom',
                                            errorCard.cardCsc ? 'errorCard' : '',
                                          ])}
                                        >
                                          {t('cardCsc')}
                                        </label>
                                      )}
                                      <MaskedInput
                                        type="text"
                                        className={classNames([classes.cardCvv, 'pt-encrypt'])}
                                        value={card.cardCsc}
                                        mask={americanExpress ? '1111' : '111'}
                                        id="cardCsc"
                                        name="encryptedCsc"
                                        ref={cscCardRef}
                                        onChange={handleChangeCard}
                                      />
                                    </Grid>
                                    {/* {isZip ? ( */}
                                    <Grid item md={6}>
                                      <label>{'Zip'}</label>
                                      <Textfield
                                        error={error.zipError}
                                        id="zip"
                                        type="text"
                                        variant="outlined"
                                        value={payerInfo.zip}
                                        width={true}
                                        onChange={(e) => {
                                          const numberRegex = /^[0-9\b]+$/;
                                          if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                            handleChange(e);
                                          }
                                        }}
                                      />
                                    </Grid>
                                    {/* ) : (
                                      ''
                                    )} */}
                                    {/* {isAddress ? ( */}
                                    <Grid item md={6}>
                                      <label>{'Address'}</label>
                                      <Textfield
                                        error={error.addressError}
                                        id="address"
                                        type="text"
                                        variant="outlined"
                                        //labels={'Address'}
                                        value={payerInfo.address}
                                        width={true}
                                        onChange={handleChange}
                                      />
                                    </Grid>
                                  </Grid>
                                  {/* </TabPanel> */}
                                </Grid>
                                <Grid item md={12}>
                                  <Buttons
                                    startIcon={<LockOutlinedIcon />}
                                    id="signUp"
                                    type="button"
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    className={classes.payBtn}
                                    onClick={handleSubmit}
                                    disabled={isSubmit}
                                    text={'Pay'}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </form>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item md={4}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Buttons
                              id="vInvoice"
                              type="button"
                              fullWidth={true}
                              className={classes.viewInvoice}
                              variant="outlined"
                              onClick={() => {}}
                              text={'Recurrence Details'}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.invoiceDetail}>
                            {/* <TableContainer component={Paper}> */}
                            <Table className={classes.table} size="small" aria-label="a dense table">
                              <TableBody>
                                <TableRow>
                                  <TableCell align="left">Customer Name</TableCell>
                                  <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                                    {firstName && lastName ? firstName + ' ' + lastName : ''}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left">Start Date</TableCell>
                                  <TableCell align="right">
                                    {new Date(recurringStartDate).toLocaleDateString()}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left">End Date</TableCell>
                                  <TableCell align="right">{new Date(recurringEndDate).toLocaleDateString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left">Amount</TableCell>
                                  <TableCell align="right">{formatCurrency(amount)}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left">Total Count</TableCell>
                                  <TableCell align="right">{totalCount}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left">Frequency</TableCell>
                                  <TableCell align="right">{frequency}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left">
                                    <b>Total</b>
                                  </TableCell>
                                  <TableCell align="right">
                                    <b>{amount ? formatCurrency(amount) : 0}</b>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                            {/* </TableContainer> */}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </LoadingOverlay>
      </div>
    </Container>
  );
};

export default OnTimeRecurringPayment;
