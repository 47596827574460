import { validateHtmlMarkUp } from '../../helpers/commonFunction';
import validator from '../../helpers/checkValidations';

export function handleClose(setState) {
  setState({ isOpen: false });
}

export function changeCustomerFieldsHandler(state, event, i) {
  let error = {};
  const values = [...state.customFields];
  values[i].value = event.target.value;
  return { values, error };
}

export function handleLabels(data, state) {
  const values = [...state.customFields];
  values.push({ value: null, label: data.LableName });
  return values;
}

export function handleRemove(state, i) {
  const values = [...state.customFields];
  values.splice(i, 1);
  return values;
}

export function changeHandler(event) {
  let error = {};
  let misMatchError = [];
  let validationMarkup = true;
  let { id, value } = event.target;
  const isHtmlMarkupValid = validateHtmlMarkUp(value);
  if (isHtmlMarkupValid) {
    validationMarkup = false;
  }
  validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
  return { id, value, misMatchError, error, validationMarkup };
}

export function setUser() {
  let userData = JSON.parse(localStorage.getItem('user'));
  return {
    createdBy: userData._id,
    companyId: userData.company._id,
    isLoading: false,
  };
}
