import { Box, Modal, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import classes from './Terminal.module.scss';
import { Close } from '@mui/icons-material';

const QRModal = ({ handleClose, open, GetPayLink }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    display: 'grid',
    p: 4,
  };
  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className={classes.modelWrapper}>
        {/* <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '95%' }}> */}
        <Typography id="modal-modal-title" variant="h6" component="h4" style={{ marginBottom: 40 }}>
          Invoice PayLink QR code
        </Typography>
        <Close style={{ cursor: 'pointer', position: 'absolute', top: 20, right: 20 }} onClick={handleClose} />
        {/* </Box> */}
        <QRCodeSVG value={window.location.origin + '/payment/' + GetPayLink} size={'100%'} />
      </Box>
    </Modal>
  );
};

export default QRModal;

QRModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  GetPayLink: PropTypes.string,
};
