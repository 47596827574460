import React, { Component } from 'react';
import ProptTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    marginTop: '1px',
    left: 220,
    right: 0,
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width:767px)': {
      width: '100%',
      left: '0',
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
    background: '#f9ecec1f',
    textAlign: 'center',
  },
}));

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideShow: true,
      isLoading: true,
    };
  }

  render() {
    const { classes } = this.props;
    const currentYear = new Date().getFullYear();

    return (
      <div className={classes.root}>
        <Paper elevation={0} className={classes.paper}>
          Copyright © {currentYear} All rights Reserved at Titanium Payments
        </Paper>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: ProptTypes.object,
};

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <Footer classes={classes} {...props} />;
};

export default withRouter(ApplyingStylesOnClasses);
