import * as React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';

function CloseableAlerts(props) {
  return (
    <Alert severity={props.severity} onClose={() => props.closer(props.index)}>
      {props.message}
    </Alert>
  );
}

CloseableAlerts.propTypes = {
  severity: PropTypes.any,
  message: PropTypes.any,
  closer: PropTypes.func,
  index: PropTypes.any,
};

CloseableAlerts.defaultProps = {
  severity: '',
  message: '',
};

export default CloseableAlerts;
