import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { addContent, contentDetailsId, editContent } from '../../actions/ContentAction';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Pages.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
import validator from '../../helpers/checkValidations';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import { changeHandler } from '../Customers/CommonFunction';

class AddPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideShow: true,
      isLoading: false,
      DATETIME: new Date(),
      misMatchError: [],
      customFields: [
        [
          { value: null, label: 'CategoryName' },
          { value: null, label: 'CategoryAmount' },
          { value: null, label: 'CategoryContents', rows: 4, sm: 8 },
        ],
      ],
      categoryTitle: '',
      pageTitle: '',
      pageName: '',
      createdBy: '',
      error: {
        categoryTitleError: false,
        pageTitleError: false,
        pageNameError: false,
      },
    };
  }

  componentDidMount() {
    let ticket = this.props.location.search.split('=');
    if (ticket.length === 2) {
      this.setState({ update: true });
      this.getContentData();
    }
    let userData = JSON.parse(localStorage.getItem('user'));
    this.setState({ createdBy: userData._id });
  }
  getContentData() {
    let ticket = this.props.location.search.split('=');
    const params = { id: ticket[1] };
    this.setState({ isLoading: true });
    this.props.contentDetailsId(params).then((res) => {
      this.setState({ ...res.payload.contentData.data.response, isLoading: false }, () => {});
    });
  }
  handleChangeFields(event, _t) {
    const { id, value, misMatchError, error, validationMarkup, validationPhone } = changeHandler(event);
    if (!validationMarkup || (id === 'phone' && !validationPhone)) {
      return false;
    }
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages: false });
  }

  handleSubmitUpdateFields(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let error = {};
    let successMessages = false;
    let errorFlag = false;
    this.setState({ isSubmit: true });

    Object.keys(this.state).forEach((key1, _value) => {
      if (validator(key1, this.state[key1])) {
        return validator(key1, this.state[key1])
          ? ((error[key1 + 'Error'] = true), (errorFlag = true))
          : (error[key1 + 'Error'] = false);
      } else {
        return this.state[key1] === '' || (typeof this.state[key1] === 'string' && this.state[key1].trim() === '')
          ? ((error[key1 + 'Error'] = true), (errorFlag = true))
          : (error[key1 + 'Error'] = false);
      }
    });

    if (errorFlag) {
      console.log('err', errorFlag, error);
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm('Are you sure you want to edit this page?')) {
        this.setState({ isLoading: true });
        this.props.editContent(this.state).then((result) => {
          if (result !== undefined && result.type === 'CONTENT_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              window.scrollTo(0, 0, 0);
              setTimeout(() => {
                this.props.history.goBack();
              }, 2000);
            });
          }

          if (result !== undefined && result.type === 'CONTENT_ERROR') {
            this.setState({ misMatchError: this.props.info.contentInfo.data.message, successMessages });
          }
        });
      }
    }
  }

  handleSubmitPages(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let error = {};
    let successMessages = false;
    let errorFlag = false;
    this.setState({ isSubmit: true });
    Object.keys(this.state).forEach((key, _value) => {
      if (validator(key, this.state[key])) {
        return validator(key, this.state[key])
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      } else {
        return this.state[key] === '' || (typeof this.state[key] === 'string' && this.state[key].trim() === '')
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      }
    });

    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm('Are you sure you want to add new page?')) {
        this.setState({ isLoading: true });
        this.props.addContent(this.state).then((result) => {
          if (result !== undefined && result.type === 'CONTENT_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 2000);
            });
          }

          if (result !== undefined && result.type === 'CONTENT_ERROR') {
            this.setState({ misMatchError: this.props.info.contentInfo.data.message, successMessages });
          }
        });
      }
    }
  }

  handleChangeCustom(event, _t, i, id) {
    let error = {};
    const values = [...this.state.customFields];
    values[i][id].value = event.target.value;
    this.setState({ customFields: values, error });
  }
  handleAdd = (_data) => {
    const values = [...this.state.customFields];
    let val = [
      { value: null, label: 'CategoryName' },
      { value: null, label: 'CategoryAmount' },
      { value: null, label: 'CategoryContents', rows: 4, sm: 8 },
    ];
    values.push(val);
    this.setState({ customFields: values });
  };
  handleRemove(i) {
    const values = [...this.state.customFields];
    values.splice(i, 1);
    this.setState({ customFields: values });
  }
  render() {
    const { t } = this.props;
    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
              {this.state.successMessages ? (
                <SuccessMessage successes={[this.props.info.contentInfo.data.message]} />
              ) : (
                ''
              )}

              <form className={classes.form} noValidate>
                <Grid container spacing={3} className="ViewTxnFormBox">
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      error={this.state.error.pageNameError}
                      id="pageName"
                      type="text"
                      labels={t('PageName')}
                      value={this.state.pageName}
                      onChange={(e) => this.handleChangeFields(e, t)}
                      focus={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      error={this.state.error.pageTitleError}
                      id="pageTitle"
                      type="text"
                      labels={t('PageTitle')}
                      value={this.state.pageTitle}
                      onChange={(e) => this.handleChangeFields(e, t)}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      error={this.state.error.categoryTitleError}
                      id="categoryTitle"
                      type="text"
                      labels={t('CategoryTitle')}
                      value={this.state.categoryTitle}
                      onChange={(e) => this.handleChangeFields(e, t)}
                      width={true}
                    />
                  </Grid>
                  <b>Add Multiple Category</b>
                  <Grid container item sm={12} xs={12} justify="flex-end">
                    <Buttons
                      // className={classesCus.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t('AddMore')}
                      onClick={(e) => this.handleAdd(e)}
                    />
                  </Grid>

                  {this.state.customFields.map((field, idx) => {
                    return (
                      <Grid item xs={6} sm={6} key={idx}>
                        <Tooltip title="Close" aria-label="add">
                          <CancelOutlinedIcon color="primary" onClick={() => this.handleRemove(idx)} />
                        </Tooltip>
                        <div key={`${field}-${idx}`}>
                          {field.map((data, id) => {
                            return (
                              <div key={id}>
                                <Textfield
                                  // required={true}
                                  error={this.state.error[data.label + 'Error']}
                                  id={data.label}
                                  type={data.label === 'CategoryAmount' ? 'number' : 'text'}
                                  labels={t(data.label)}
                                  value={data.value || ''}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {data.label === 'CategoryAmount' ? '$' : ''}
                                      </InputAdornment>
                                    ),
                                  }}
                                  width={true}
                                  rows={String(data.rows ? data.rows : 0)}
                                  rowsMax={'5'}
                                  multiline={data.label === 'CategoryContents' ? true : false}
                                  onChange={(e) => this.handleChangeCustom(e, t, idx, id)}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </Grid>
                    );
                  })}

                  <Grid item xs={12}>
                    {this.state.update && this.props.user.role === 1 ? (
                      <Grid container spacing={3}>
                        <Grid item xs={8} sm={12}>
                          <Buttons
                            variant="contained"
                            color="primary"
                            text={t('Update')}
                            onClick={(e) => this.handleSubmitUpdateFields(e, t)}
                          />
                          <Buttons
                            className={classes.ml_2}
                            variant="contained"
                            color="secondary"
                            text={t('Back')}
                            onClick={this.props.history.goBack}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        {this.state.update ? (
                          <Buttons
                            variant="contained"
                            color="primary"
                            text={t('Update')}
                            onClick={(e) => this.handleSubmitUpdateFields(e, t)}
                          />
                        ) : (
                          <Buttons
                            variant="contained"
                            color="primary"
                            text={t('Save')}
                            onClick={(e) => this.handleSubmitPages(e, t)}
                          />
                        )}
                        <Buttons
                          className={classes.ml_2}
                          variant="contained"
                          color="secondary"
                          text={t('Back')}
                          onClick={this.props.history.goBack}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

AddPages.propTypes = {
  t: PropTypes.func,
  addContent: PropTypes.func,
  location: PropTypes.func,
  info: PropTypes.any,
  history: PropTypes.any,
  contentDetailsId: PropTypes.func,
  editContent: PropTypes.func,
  user: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.ContentReducer,
    user: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { editContent, addContent, contentDetailsId })(withTranslation()(AddPages));
