import {
  checkName,
  isYearRange,
  checkEmail,
  isPhone,
  checkSecretKey,
  isTwoDecimalPlace,
  isTwoDecimalPlaceSvc,
  isPayerName,
  isPassword,
  isEmpty,
} from './util';
import config from '../constants/config';
import { rules } from '../constants/IntakeFormConstants';

const stringEmptySpaces = /(?:^\s+|\s+$)/g;

const validator = (key, value) => {
  const ImageSize = config.UPLOAD_IMAGE_SIZE;
  switch (key) {
    case 'email':
      if (!checkEmail(value)) {
        return true;
      } else {
        return false;
      }
    case 'secretKey1':
      if (!checkSecretKey(value)) {
        return true;
      } else {
        return false;
      }
    case 'firstname':
      if (
        value.length > rules.firstName.rule.characterlimit ||
        isEmpty(value) ||
        value.match(stringEmptySpaces) ||
        value.match(/^s+$/)
      ) {
        return true;
      } else {
        return false;
      }
    case 'lastname':
      if (
        isEmpty(value) ||
        (value && value.length > rules.lastName.rule.characterlimit) ||
        (value && value.match(stringEmptySpaces)) ||
        (value && value.match(/^s+$/))
      ) {
        return true;
      } else {
        return false;
      }
    case 'name':
      if (!checkName(value)) {
        return true;
      } else {
        return false;
      }
    case 'phone':
    case 'phonenumber':
      if (!isPhone(value)) {
        return true;
      } else {
        return false;
      }
    case 'password':
      if (!isPassword(value)) return true;
      return false;
    case 'imgeSize':
      if (value < ImageSize) {
        return true;
      } else {
        return false;
      }
    case 'amount':
    case 'perTransactionLimit':
    case 'dailyTransactionLimit':
    case 'InvoiceSalesTaxFee':
    case 'InvoiceSVCFee':
      if (!isTwoDecimalPlace(value)) {
        return true;
      } else {
        return false;
      }
    case 'perRefundLimit':
    case 'dailyRefundLimit':
    case 'svcFee':
      if (!isTwoDecimalPlaceSvc(value)) {
        return true;
      } else {
        return false;
      }
    case 'payerName':
      if (!isPayerName(value) || isEmpty(value)) {
        return true;
      }
      if (value.length > rules.payerName.rule.characterlimit) {
        return true;
      } else {
        return false;
      }

    case 'year':
      if (!isYearRange(value)) {
        return true;
      } else {
        return false;
      }
    case 'customer_id':
      return value.trim() === '' || !value.match(/^(?=\S+$)[a-zA-Z0-9/(),@.\-&'*’]+$/);
    default:
      break;
  }
};

export default validator;
