const initialState = () => ({
  signup_response: { status: '' },
  email: '',
  isUserNames: false,
  exp: '',
  iat: '',
  name: '',
  userId: '',
  invoices: {},
  invoice_detail: {},
  isLoading: false,
});
const UserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'Empty_state':
      return { ...state, [payload.property]: payload.value };
    case 'USERS_DETAILS':
      return { ...state, ...payload.value };
    case 'HASH':
      return { ...state, ...payload.value, isLoading: false };
    case 'HISTORY':
      return { ...state, ...payload.value };
    default:
      return state;
  }
};

export { UserReducer };
