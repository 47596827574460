import { ActionType, config } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
export const termsCondStart = () => ({
  type: ActionType.USER_START,
});
export const termsCondSuccess = (termsCondData) => {
  return {
    type: ActionType.TERMSCOND_SUCCESS,
    payload: termsCondData,
  };
};
export const termsCondError = (error) => ({
  type: ActionType.USER_ERROR,
  payload: { error },
});
export const companyTermsConditions = (companyId, locationId = '') => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(termsCondStart());
    return DataGetApi(
      config.BASEURL + `companyTermsConditions?id=${user.company.id}&locationId=${locationId}`,
      { company: companyId },
      token
    )
      .then((response) => {
        if (response.status === 200) {
          return dispatch(termsCondSuccess(response.data.response));
        } else {
          return dispatch(termsCondError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getLocationTermsAndConditions = (companyId, locationId = '') => {
  return (dispatch) => {
    dispatch(termsCondStart());
    return DataGetApi(config.BASEURL + `companyTermsConditions?id=${companyId}&locationId=${locationId}`, {
      company: companyId,
      location: locationId,
    })
      .then((response) => {
        if (response.status === 200) {
          return dispatch(termsCondSuccess(response.data.response));
        } else {
          return dispatch(termsCondError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export function getPolicyPDF(params) {
  return (dispatch) => {
    dispatch(termsCondStart());
    return DataApi(params, `${config.BASEURL}getPolicy`)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(termsCondSuccess(response));
        } else {
          return dispatch(termsCondError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
