import React, { memo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const CustomBuild = ({ handleChange, customTowingBuild, customDefaultBuild }) => {
  return (
    <Accordion
      style={{
        border: 0,
        boxShadow: 'none',
        marginLeft: 10,
        margin: 0,
        padding: 0,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>Select Custom Build</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0, margin: 0, boxShadow: 'none', border: 0, marginLeft: 10 }}>
        <Grid>
          <Typography color="textSecondary">Fields:</Typography>
          <Grid style={{ marginLeft: 10 }} item xs={12} sm={12}>
            <label>
              <Checkbox
                name="default"
                color="primary"
                checked={customDefaultBuild}
                onChange={(e) => {
                  handleChange(e);
                }}
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />
              <span>Default</span>
            </label>
          </Grid>
          <Grid style={{ marginLeft: 10 }} item xs={12} sm={12}>
            <label>
              <Checkbox
                color="primary"
                name="towing"
                checked={customTowingBuild}
                onChange={(e) => {
                  handleChange(e);
                }}
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />
              <span>Towing Build</span>
            </label>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(CustomBuild);

CustomBuild.propTypes = {
  handleChangeCheckbox: PropTypes.func,
  handleChange: PropTypes.func,
  customTowingBuild: PropTypes.any,
  customDefaultBuild: PropTypes.any,
};
