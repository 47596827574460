import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './SignUp.module.scss';
import { connect } from 'react-redux';
import { signIn } from './../../actions/SigninAction';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      misMatchError: [],
      successMessages: false,
      error: {
        nameError: false,
        emailError: false,
        passwordError: false,
        confirmPasswordError: false,
      },
    };
  }
  handleChange(event, t) {
    let error = {};
    let { id, value } = event.target;
    let { password, confirmPassword } = this.state;
    this.setState({ [id]: value, error });
    let misMatchError = [];
    let successMessages = false;
    if (
      (id === 'confirmPassword' && password !== event.target.value) ||
      (id === 'password' && confirmPassword !== value)
    ) {
      misMatchError[0] = t('MisMatchPassword');
      this.setState({ misMatchError, successMessages });
    } else {
      this.setState({ misMatchError, successMessages });
    }
  }
  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    Object.keys(state).map((key, _value) =>
      state[key] === '' ? ((error[key + 'Error'] = true), (errorFlag = true)) : ''
    );
    console.log(state.error.emailError);
    if (!state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      misMatchError[0] = t('EmailNotValid');
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      this.props.signIn(this.state).then((result) => {
        if (result !== undefined && result.type === 'SIGNIN_SUCCESS') {
          this.setState({ successMessages: true });
        }
        if (result !== undefined && result.type === 'SIGNIN_ERROR') {
          this.setState({ misMatchError: this.props.info.signInInfo.data.message, successMessages });
        }
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className={classes.signup}>
        <Container component="main" maxWidth="sm">
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <Textfield
                required={true}
                error={this.state.error.nameError}
                id="name"
                type="text"
                labels={t('Name')}
                value={this.state.name}
                width={true}
                onChange={(e) => this.handleChange(e, t)}
                focus={true}
              />
              <Textfield
                required={true}
                error={this.state.error.emailError}
                id="email"
                type="email"
                labels={t('Email')}
                value={this.state.email}
                width={true}
                onChange={(e) => this.handleChange(e, t)}
                focus={false}
              />
              <Textfield
                required={true}
                error={this.state.error.passwordError}
                id="password"
                type="password"
                labels={t('Password')}
                value={this.state.password}
                width={true}
                onChange={(e) => this.handleChange(e, t)}
                focus={false}
              />
              <Textfield
                required={true}
                error={this.state.error.confirmPasswordError}
                id="confirmPassword"
                type="password"
                labels={t('ConfirmPassword')}
                value={this.state.confirmPassword}
                width={true}
                onChange={(e) => this.handleChange(e, t)}
                focus={false}
              />

              <Buttons
                id="signUp"
                type="button"
                width={true}
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => this.handleSubmit(e, t)}
                text={t('SignUp')}
              />
            </form>
          </div>
        </Container>
        {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
        {this.state.successMessages ? <SuccessMessage successes={[this.props.info.signInInfo.data.message]} /> : ''}
      </div>
    );
  }
}

SignUp.propTypes = {
  t: PropTypes.func,
  info: PropTypes.any,
  signIn: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.SigninReducer,
  };
}

export default connect(mapStateToProps, { signIn })(withTranslation()(SignUp));
