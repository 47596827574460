import React from 'react';
import { formatCurrency, getBaseAmount } from './commonFunction';
import Tooltip from '@material-ui/core/Tooltip';
import message from '../constants/message';
import _ from 'lodash';

const getMaskedCardNumber = (result) => {
  if (!result?.CASH) {
    if (result.FRONT_END === 'Recurring' && result.MASKCARDNUMBER) {
      return `************${result.MASKCARDNUMBER.substr(-4)}`;
    } else if (result.RESPONSECODE === '120') {
      return 'N/A';
    } else if (result.MASKCARDNUMBER) {
      return `************${result.MASKCARDNUMBER.substr(-4)}`;
    } else if (result?.CUSTOMERDATA?.masked_card_number) {
      return `************${result.CUSTOMERDATA.masked_card_number.substr(-4)}`;
    } else {
      return 'N/A';
    }
  } else {
    return 'N/A';
  }
};

const getPaymentType = (result) => {
  let type = 'Credit';
  if (result.CARDTYPE === 'ACH' || result.RESPONSECODE === '120' || result?.achDetails?.isOpen) {
    return 'ACH';
  } else {
    if (result.creditOrDebit !== null && typeof result.creditOrDebit === 'string') {
      type = _.capitalize(result.creditOrDebit);
    }
  }

  if (result?.CASH) {
    return 'Cash';
  } else {
    return type;
  }
};

const getCardType = (result) => {
  if (!result?.CASH) {
    return !result.CARDTYPE || result.RESPONSECODE === '120'
      ? 'N/A'
      : result.CARDTYPE
      ? result.CARDTYPE
      : result.CUSTOMERDATA.cardType
      ? result.CUSTOMERDATA.cardType
      : 'N/A';
  } else {
    return 'N/A';
  }
};

const extractCompletedByUsername = (result) => {
  if (result?.createdBy?.userName) {
    return result?.createdBy.userName;
  } else {
    return 'N/A';
  }
};

const getCompletedBy = (result) => {
  // Specific condition for 48forty
  if (
    result &&
    result.FRONT_END !== 'Self Payment Link' &&
    (result.companyId === '65ca2952137ba518cb9565f0' || result.companyId === '650b7f1660368040608cdec7')
  ) {
    return extractCompletedByUsername(result);
  } else if (
    result &&
    result.FRONT_END === 'Self Payment Link' &&
    (result.companyId === '65ca2952137ba518cb9565f0' || result.companyId === '650b7f1660368040608cdec7')
  ) {
    return 'Self Pay';
  } else if (result && result.isMultiInvoicePay) {
    return `${result?.CUSTOMERDATA?.firstName} ${result?.CUSTOMERDATA?.lastName}`;
  } else {
    return result.inventedBy ? result.inventedBy : 'N/A';
  }
};

export const generateDataSource = (payload, className, handleChangeId, invoiceLabel, _params = null) => {
  let options = { day: '2-digit', year: '2-digit', month: '2-digit' };
  let settings = null;
  let isCustomer = false;
  const svcFeeName = payload[0]?.svcFeeName !== '' ? payload[0]?.svcFeeName : 'SVC Fee';
  const data = payload.map((result) => {
    console.log(result, '---resul');
    const paymentStatus = result.FINAL_STATUS;
    let invoiceLabelValue = result.ORDERID
      ? result.ORDERID
      : result.invoices !== null
      ? invoiceLabel === 'Invoice'
        ? result.invoices.invoice
          ? result.invoices.invoice
          : 'N/A'
        : result.invoices.jobId
        ? result.invoices.jobId
        : 'N/A'
      : result.jobId
      ? result.jobId
      : 'N/A';
    if (result.isOneTimeCustomer) {
      invoiceLabelValue = result.oneTimeCustomerInvoice;
    }
    return {
      Posted: result.posted,
      'Front End': result.FRONT_END ? result.FRONT_END : 'N/A',
      // 'Transaction ID': (
      //   <Tooltip title="View Transaction">
      //     <span className={classes.Underline} onClick={(_e) => this.handleChangeId(result.TXNID)}>
      //       {result.TXNID}
      //     </span>
      //   </Tooltip>
      // ),
      'Transaction ID': result.TXNID,
      'Customer Name': result.CUSTOMERDATA
        ? `${
            result.CUSTOMERDATA.name
              ? result.CUSTOMERDATA.name
              : result.CUSTOMERDATA.firstName
              ? result.CUSTOMERDATA.firstName + ' ' + result.CUSTOMERDATA.lastName
              : result.CUSTOMERDATA.customer_id
              ? result.CUSTOMERDATA.customer_id
              : 'N/A'
          } `
        : 'N/A',
      'Payment Type': getPaymentType(result),
      'Payment Status': paymentStatus,
      'Completed By': getCompletedBy(result),
      Location: result.location ? result.location : 'N/A',
      [invoiceLabel]: invoiceLabelValue,
      'Base Amount': result ? getBaseAmount(result) : '$0.00',
      [svcFeeName]:
        result.SVC_FEE || result.SVC_FEE === 0
          ? formatCurrency(result.SVC_FEE)
          : result.invoices !== null
          ? result.invoices.svcFee
            ? formatCurrency(result.invoices.svcFee)
            : '$0.00'
          : '$0.00',
      Tax:
        result.TAX || result.TAX === 0
          ? formatCurrency(parseFloat(result.TAX))
          : result.invoices !== null
          ? result.invoices.tax
            ? formatCurrency(parseFloat(result.invoices.tax))
            : '$0.00'
          : '$0.00',
      Amount: result.amount ? `${formatCurrency(parseFloat(result.AMOUNT))}` : '$0.00',
      Surcharge: result.surcharge && !result.CASH ? `${formatCurrency(parseFloat(result.surcharge))}` : '$0.00',
      'Card Number': getMaskedCardNumber(result),
      'Card Type': getCardType(result),
      Date: new Date(result.createdAt).toLocaleString('en-US', options),
      Notes: result.notes
        ? result.notes?.substring(0, 32) ?? 'N/A'
        : result.invoices
        ? result.invoices.publicNotes
          ? result.invoices.publicNotes?.substring(0, 32)
          : 'N/A'
        : 'N/A',
    };
  });
  if (_params?.isCustomer) {
    isCustomer = true;
    settings = _params?.settings;
  }
  const displayColumnName = (name, row, _index, _column, _id) => {
    return <div> {row[name]} </div>;
  };

  const columns = [
    {
      name: 'Front End',
      selector: (row) => row['Front End'],
      sortable: false,
      wrap: true,
      allowOverflow: true,
      cell: (row, _index, _column, _id) => displayColumnName('Front End', row, _index, _column, _id),
      width: '15%',
    },
    {
      name: 'Transaction ID',
      allowOverflow: true,
      wrap: true,
      sortable: false,
      selector: (row) => row['Transaction ID'],
      width: '10%',
      cell: (row, _index, _column, _id) => {
        return (
          <button
            style={{
              cursor: 'pointer',
              color: 'blue',
              textDecoration: 'underline',
              background: 'transparent',
              border: 'none',
            }}
            tabIndex="0"
            onClick={() => {
              const transactionId = row['Transaction ID']; // or the correct property
              if (isCustomer && transactionId) {
                window.open(`${window.location.origin}/Customer/PaymentDetails/${transactionId}`, '_blank');
              } else if (transactionId) {
                window.open(`${window.location.origin}/Viewtransaction/${transactionId}`, '_blank');
              } else {
                console.error('Transaction ID is undefined');
              }
            }}
          >
            {displayColumnName('Transaction ID', row, _index, _column, _id)}
          </button>
        );
      },
    },
    {
      name: 'Customer Name',
      wrap: true,
      selector: (row) => row['Customer Name'],
      width: '15%',
      sortable: false,
      cell: (row, _index, _column, _id) => displayColumnName('Customer Name', row, _index, _column, _id),
    },
    {
      name: 'Payment Type',
      width: '10%',
      sortable: false,
      wrap: true,
      selector: (row) => row['Payment Type'],
      // omit: true
      cell: (row, _index, _column, _id) => displayColumnName('Payment Type', row, _index, _column, _id),
    },
    {
      name: 'Payment Status',
      selector: (row) => row['Payment Status'],
      width: '10%',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: (d) => {
        const payStatus = d['Payment Status'];
        let className = null;
        if (payStatus === 'Paid' || payStatus == 'PAID') {
          className = 'btn paidBtn';
        }
        if (payStatus == 'Refunded' || payStatus == 'REFUNDED') {
          className = 'btn RefundedBtn';
        }
        if (payStatus == 'Voided' || payStatus == 'VOIDED') {
          className = 'btn PenddingBtn';
        }
        if (payStatus == 'Declined' || payStatus == 'DECLINED') {
          className = 'btn DeclinedBtn';
        }
        if (payStatus == 'Submitted' || payStatus == 'SUBMITTED') {
          className = 'btn SubmittedBtn';
        }
        if (payStatus == 'Failure' || payStatus == 'FAILURE') {
          className = 'btn DeclinedBtn';
        }

        return (
          <Tooltip title={payStatus === 'Failure' || payStatus === 'FAILURE' ? message.FAILURE_BUTTON_MESSAGE : ''}>
            <button type="button" className={className}>
              {payStatus ?? 'N/A'}
            </button>
          </Tooltip>
        );
      },
    },
    {
      name: 'Completed By',
      sortable: false,
      selector: (row) => row['Completed By'],
      wrap: true,
      cell: (row, _index, _column, _id) => displayColumnName('Completed By', row, _index, _column, _id),
      width: '10%',
    },
    {
      name: 'Location',
      sortable: false,
      wrap: true,
      selector: (row) => row['Location'],
      class: 'myrow',
      cell: (row, _index, _column, _id) => displayColumnName('Location', row, _index, _column, _id),
      width: '10%',
    },
    {
      name: invoiceLabel,
      selector: (row) => row[invoiceLabel],
      width: '10%',
      wrap: true,
      sortable: false,
      cell: (row, _index, _column, _id) => displayColumnName(invoiceLabel, row, _index, _column, _id),
    },
    {
      name: 'Base Amount',
      sortable: false,
      selector: (row) => row['Base Amount'],
      cell: (row, _index, _column, _id) => displayColumnName('Base Amount', row, _index, _column, _id),
      wrap: true,
      width: '10%',
    },

    {
      name: 'Surcharge',
      sortable: false,
      selector: (row) => row['Surcharge'],
      cell: (row, _index, _column, _id) => displayColumnName('Surcharge', row, _index, _column, _id),
      wrap: true,
      width: '10%',
      omit: isCustomer ? !settings?.virtualTerminalSettings?.InvoiceSurcharge : false,
    },

    {
      name: svcFeeName,
      selector: (row) => row[svcFeeName],
      wrap: true,
      cell: (row, _index, _column, _id) => displayColumnName(svcFeeName, row, _index, _column, _id),
      sortable: false,
      width: '10%',
      omit: isCustomer ? !settings?.virtualTerminalSettings?.InvoiceDiscount : false,
    },
    {
      name: 'Tax',
      width: '10%',
      selector: (row) => row['Tax'],
      wrap: true,
      sortable: false,
      cell: (row, _index, _column, _id) => displayColumnName('Tax', row, _index, _column, _id),
      omit: isCustomer ? !settings?.virtualTerminalSettings?.InvoiceTax : false,
    },
    {
      name: 'Amount',
      sortable: false,
      selector: (row) => row['Amount'],
      cell: (row, _index, _column, _id) => displayColumnName('Amount', row, _index, _column, _id),
      width: '10%',
      wrap: true,
    },
    {
      name: 'Card Number',
      wrap: true,
      sortable: false,
      selector: (row) => row['Card Number'],
      allowOverflow: true,
      cell: (row, _index, _column, _id) => displayColumnName('Card Number', row, _index, _column, _id),
      width: '10%',
    },
    {
      name: 'Card Type',
      width: '10%',
      sortable: false,
      selector: (row) => row['Card Type'],
      wrap: true,
      cell: (row, _index, _column, _id) => displayColumnName('Card Type', row, _index, _column, _id),
    },
    {
      name: 'Date',
      wrap: true,
      selector: (row) => row['Date'],
      cell: (row, _index, _column, _id) => displayColumnName('Date', row, _index, _column, _id),
      width: '10%',
      sortable: true,
    },
    {
      name: 'Notes',
      sortable: false,
      wrap: true,
      selector: (row) => row['Notes'],
      cell: (row, _index, _column, _id) => displayColumnName('Notes', row, _index, _column, _id),
      allowOverflow: true,
      width: '20%',
    },
  ];
  return { data, columns };
};
