export const checkAddressEnabled = (fields) => {
  let isEnabled = false;
  const entries = Object.entries(fields ?? {});
  for (let [key, value] of entries) {
    if (key !== 'isShippingAddressRequired' || key !== 'isBillingAddressRequired') {
      if (value) {
        isEnabled = true;
      }
    }
  }
  return isEnabled;
};
