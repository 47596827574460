import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';

/**
 * Customer info Begin Action
 */
export const productStart = () => ({
  type: ActionType.PRODUCT_START,
});

/**
 * product info Success Action
 */
export const productSuccess = (productData) => {
  return {
    type: ActionType.PRODUCT_SUCCESS,
    payload: { productData },
  };
};

/**
 * product info Failure Action
 */
export const productError = (error) => ({
  type: ActionType.PRODUCT_ERROR,
  payload: { error },
});

/**
 * product driver info
 */
export const addProduct = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(productStart());
    return DataApi(data, config.BASEURL + 'addProduct', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(productSuccess(response));
        } else {
          return dispatch(productError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const editProduct = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(productStart());
    return DataApi(data, config.BASEURL + 'editProduct', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(productSuccess(response));
        } else {
          return dispatch(productError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const productList = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(productStart());
    return DataGetApi(config.BASEURL + 'productList', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(productSuccess(response));
        } else {
          return dispatch(productError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const productDetailsId = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(productStart());
    return DataGetApi(config.BASEURL + 'productDetailsId', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(productSuccess(response));
        } else {
          return dispatch(productError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const productStatus = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(productStart());
    return DataApi(data, config.BASEURL + 'productStatus', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(productSuccess(response));
        } else {
          return dispatch(productError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
