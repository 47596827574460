export const initialAddressSettings = {
  addressSettings: {
    billingAddress: {
      addressOne: false,
      addressTwo: false,
      city: false,
      state: false,
      zip: false,
      isBillingAddressRequired: false,
    },
    shippingAddress: {
      addressOne: false,
      addressTwo: false,
      city: false,
      state: false,
      zip: false,
      isShippingAddressRequired: false,
    },
  },
};

export const initialAddressState = {
  billingAddress: {
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    zip: '',
  },
  shippingAddress: {
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    zip: '',
  },
};

export const initAddressSettingsForCustomer = {
  addressSettings: {
    billingAddress: {
      addressOne: true,
      addressTwo: true,
      city: true,
      state: true,
      zip: true,
      isBillingAddressRequired: false,
    },
    shippingAddress: {
      addressOne: true,
      addressTwo: true,
      city: true,
      state: true,
      zip: true,
      isShippingAddressRequired: false,
    },
  },
};
