const initialState = () => ({
  invoice: '',
  field1: { key: '', value: '' },
  field2: { key: '', value: '' },
  field3: { key: '', value: '' },
  field4: { key: '', value: '' },
  field5: { key: '', value: '' },
  field6: { key: '', value: '' },
  field7: { key: '', value: '' },
  serviceType: '',
  problemType: '',
  willAnyoneBeWithTheVehicle: '',
  doYouHaveKeysForVehicle: '',
  regularGasOrDiesel: '',
  neutral: '',
  doAllWheelsTurn: '',
  doesFrontWheelsTurn: '',
  doesBackWheelsTurn: '',
  pickUpLocation: '',
  pickUpNotes: '',
  startAddress: '',
  endAddress: '',
  email: '',
  amount: '',
  basePrice: '',
  notes: '',
  sendTo: 'phone',
  zipForCalc: '',
  system: '',
  success: '',
  distanceDetails: {},
  tax: '',
  svcFee: '',

  open: false,
  userAccount: '',

  touched: {
    field1: false,
    field2: false,
    field3: false,
    field4: false,
    field5: false,
    field6: false,
    field7: false,
    serviceType: false,
    problemType: false,
    willAnyoneBeWithTheVehicle: false,
    doYouHaveKeysForVehicle: false,
    regularGasOrDiesel: false,
    neutral: false,
    doAllWheelsTurn: false,
    doesFrontWheelsTurn: false,
    doesBackWheelsTurn: false,
    pickUpLocation: false,
    email: false,
    amount: false,
  },
  error: {
    field1Error: false,
    field2Error: false,
    field3Error: false,
    field4Error: false,
    field5Error: false,
    field6Error: false,
    field7Error: false,
    serviceType: false,
    problemType: false,
    willAnyoneBeWithTheVehicle: false,
    doYouHaveKeysForVehicle: false,
    regularGasOrDiesel: false,
    neutral: false,
    doAllWheelsTurn: false,
    doesFrontWheelsTurn: false,
    doesBackWheelsTurn: false,
    pickUpLocation: false,
    email: false,
    amount: false,
  },
});

const InputReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case 'SET_STATE':
      if (Object.keys(state.touched).indexOf(payload.property) >= 0) {
        return {
          ...state,
          ...(payload.key
            ? { [payload.property]: { ...state[payload.property], value: payload.value, key: payload.key } }
            : { [payload.property]: payload.value }),
          touched: { ...state.touched, [payload.property]: true },
          error: { ...state.error, [payload.property]: false },
        };
      }
      return { ...state, [payload.property]: payload.value };
    case 'SET_STATE_ERROR':
      if (Object.keys(state.error).indexOf(payload.property) >= 0) {
        return { ...state, [payload.property]: payload.value, error: { ...state.error, [payload.property]: true } };
      }
      break;
    case 'INVOICE_DETAILS':
      return { ...state, ...payload.value };
    case 'APPEND_STATE':
      return { ...state, notes: state.notes + '\n' + payload.value };
    case 'RESET':
      return { ...initialState(), invoice: Math.floor(Math.random() * 1000000000).toString() };
    case 'SUCCESS':
      return { ...state, success: payload, open: payload };
    case 'ERROR':
      alert(payload);
      return { ...state, error: payload };
    default:
      return state;
  }
};

export { InputReducer };
