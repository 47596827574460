import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@mui/material/TextField';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

const CustomFieldRow = (props) => {
  const handleChange = (e, index) => {
    const newInputs = [...props.inputs];
    if (e.target.name === 'value') {
      newInputs[index][e.target.name] = e.target.value;
    } else {
      newInputs[index][e.target.name] = e.target.checked;
    }
    props.setCustomFields(newInputs);
  };

  return (
    <Grid container direction="row">
      <Grid item xs={4}>
        <TextField
          id="standard-basic"
          variant="standard"
          type="text"
          name="value"
          label={props.field.label ?? `Custom Field Name`}
          onChange={(e) => handleChange(e, props.index)}
          inputProps={{ maxLength: 25 }}
          value={props.field.value}
        />
      </Grid>
      <Grid item xs={4}>
        <Switch
          name="enabled"
          checked={props.field.enabled}
          onChange={(e) => {
            handleChange(e, props.index);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Checkbox
          name="required"
          color="primary"
          onChange={(e) => {
            handleChange(e, props.index);
          }}
          value={props.field.required}
          checked={props.field.required}
        />
      </Grid>
    </Grid>
  );
};

const CustomBuildFields = (props) => {
  const [inputs, setInputs] = useState(
    props.fields && props.fields.length > 0
      ? props.fields
      : [
          { key: 'FieldOneText', enabled: false, required: false, value: '', label: 'Field One Text' },
          { key: 'FieldTwoText', enabled: false, required: false, value: '', label: 'Field Two Text' },
          { key: 'FieldThreeText', enabled: false, required: false, value: '', label: 'Field Three Text' },
          { key: 'FieldFourText', enabled: false, required: false, value: '', label: 'Field Four Text' },
          { key: 'FieldFiveText', enabled: false, required: false, value: '', label: 'Field Five Text' },
          { key: 'FieldSixText', enabled: false, required: false, value: '', label: 'Field Six Text' },
          { key: 'FieldSevenText', enabled: false, required: false, value: '', label: 'Field Seven Text' },
        ]
  );

  const setCustomFields = (values) => {
    props.handleChange(values);
  };

  const getTitle = (key) => {
    if (key === 3) {
      return <h3>Vehicle Info</h3>;
    } else if (key === 0) {
      return <h3>Caller Info</h3>;
    }
  };

  return (
    <React.StrictMode>
      <Grid container spacing={{ xs: 2, md: 3 }} style={{ flexDirection: 'column', marginLeft: '30px' }}>
        {inputs &&
          inputs.map((field, index) => {
            return (
              <Grid item key={index}>
                {getTitle(index)}
                <CustomFieldRow
                  index={index}
                  setInputs={setInputs}
                  setCustomFields={setCustomFields}
                  inputs={inputs}
                  field={field}
                />
              </Grid>
            );
          })}
      </Grid>
    </React.StrictMode>
  );
};
CustomBuildFields.propTypes = {
  fields: PropTypes.any,
  handleChange: PropTypes.func,
  inputs: PropTypes.any,
  index: PropTypes.any,
};

CustomFieldRow.propTypes = {
  setCustomFields: PropTypes.func,
  field: PropTypes.any,
  index: PropTypes.any,
  inputs: PropTypes.any,
};

export default CustomBuildFields;
