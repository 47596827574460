import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table';
import classes from './Customers.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config';
import { DataGetApi } from '../../helpers/PostDataApi';
import { customerStatus } from '../../actions/CustomerAction';
import { createCustomerSyncJob } from '../../actions/JobAction';
import { getCsrfToken } from '../../actions/PaymentAction';
import { fetchDetails } from '../../actions/AdminUserAction';
import { ErrorMessage } from '../../components/Message/ErrorMessage';
import { whichLocationSelected } from '../../helpers/getLocationFromLocalStorage';
import JobType from '../../constants/JobType';
import Alert from '@mui/material/Alert';

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

class Customers extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      isMounted: false,
      isLoading: false,
      reload: false,
      misMatchError: [],
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
      user: JSON.parse(localStorage.getItem('user')),
      locationId: '',
      customerSelfSignUpLink: '',
      showCustomerSelfSignUpLink: true,
      customerData: [],
      customerPortal: false,
    };
    this.getTableData = this.getTableData.bind(this);
  }
  componentDidMount() {
    this.setState({ isMounted: true });
    let user = JSON.parse(localStorage.getItem('user'));
    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (locationPaydetails.customerPortal) {
      this.setState({ customerPortal: locationPaydetails.customerPortal });
    }
    let data = fetchDetails({ id: user._id });
    data.then((res) => {
      if (res.type === 'FETCH_SUCCESS') {
        this.setState({ userData: { ...res.payload.userData } }, () => {});
      }
    });
    this.getTableData().then((result) => {
      console.log(result, '---data');
      this.setState({ customerData: result?.data });
    });

    this.getCsrfToken();
  }
  static getDerivedStateFromProps(props, _state) {
    console.log('props', props);
    if (props.jobInfo?.isLoading) {
      return { isLoading: true };
    }
    if (props.jobInfo?.jobCreationResponseReceived) {
      return {
        jobCreationMessage: props.jobInfo.jobInfo.message,
        jobCreationResponseReceived: true,
        isLoading: false,
        jobCreationSuccess: props.jobInfo?.jobInfo.success,
      };
    }
    return null;
  }
  componentWillUnmount() {
    this.setState({ isMounted: false });
  }
  handleChangeId(id) {
    this.props.history.push('/CustomerDetails/' + id);
  }
  tableReload(status) {
    this.setState({ reload: status });
  }
  toDateFilter(date) {
    this.setState({ toDate: date });
  }
  fromDateFilter(date) {
    this.setState({ fromDate: date });
  }
  getTableData() {
    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState({ locationId: locationPaydetails.id });
    return new Promise((resolve, _reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let locationID = localStorage.getItem('locationArrIds');
      let token = user.token;
      let url = config.BASEURL;
      let param = {
        role: user.role,
        userId: user._id,
        companyId: user.company?._id,
        locationIds: locationID,
        // 'toDate': (user.role !== config.ADMIN_ROLE  ) ?  this.state.toDate : null ,
        // 'fromDate':(user.role !== config.ADMIN_ROLE ) ?  this.state.fromDate : null,
        toDate: null,
        fromDate: null,
        locationId: locationPaydetails.id,
      };
      DataGetApi(url + 'customerList', param, token).then((result) => {
        resolve({
          /**data:
            typeof query.search === 'undefined' || query.search === ''
              ? result.data.response
              : result.data.response.filter((row) => {
                  const name = row.firstName + ' ' + row.lastName;
                  return (
                    (row.phone !== null && String(row.phone).indexOf(String(query.search)) !== -1) ||
                    (row.createdAt !== null &&
                      String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !== -1) ||
                    (name !== null && name.toLowerCase().indexOf(String(query.search).toLowerCase()) !== -1)
                    // || row.email.indexOf(String(query.search)) !== -1
                  );
                }),**/
          data: result.data.response,
        });
      });
    });
  }
  generateCustomerSelfSignUpLink = () => {
    const customerSelfSignUpLink = `${window.location.origin}/customerSelfSignUp/${this.state.locationId}`;
    this.setState({
      customerSelfSignUpLink,
      showCustomerSelfSignUpLink: false,
    });
  };

  companyStatusToggle(e, data) {
    this.props.customerStatus(data).then((_res) => {
      window.location.reload(false);
    });
  }
  async handleSyncCustomers() {
    try {
      const currentLocation = whichLocationSelected();
      const user = JSON.parse(localStorage.getItem('user'));
      const data = {
        locationId: currentLocation?._id,
        userId: user?._id,
        jobType: JobType.CUSTOMER_SYNC,
      };

      const paytraceCredentials = {
        paytraceKey: currentLocation?.paytraceKey,
        userName: currentLocation?.paytraceUserName,
        password: currentLocation?.paytracePassword,
        integratorId: currentLocation?.paytraceIntegratorId,
      };
      this.props.createCustomerSyncJob(data, this.state.csrf, paytraceCredentials);
    } catch (err) {
      console.error(`Failed to request customer sync`, err);
    }
  }
  async getCsrfToken() {
    try {
      const csrfTokenResponse = await this.props.getCsrfToken();
      this.setState({ csrf: { ...csrfTokenResponse.payload.paymentData.data } });
    } catch (err) {
      console.error(`Failed to get csrf token`, err);
    }
  }
  render() {
    const locationPaydetails = localStorage.getItem('locationPaydetails');
    let locationDetails = null;
    if (isJsonString(locationPaydetails)) {
      locationDetails = JSON.parse(locationPaydetails);
    }
    let customInvoicePageFields = locationDetails?.customInvoicePageFields;
    let isHidden = false;
    if (locationDetails?.customTowingBuild) {
      isHidden = true;
    } else {
      customInvoicePageFields = [];
    }
    const columns = [
      {
        title: 'Name',
        field: 'firstName',
        hidden: isHidden,
        render: (rowData) => (
          <Tooltip title="View Details">
            <span className={classes.Underline} onClick={(_e) => this.handleChangeId(rowData._id)}>
              {`${rowData?.firstName ? rowData?.firstName : ''} ${rowData?.lastName ? rowData?.lastName : ''}`}
            </span>
          </Tooltip>
        ),
        customFilterAndSearch: (term, rowData) => {
          let { firstName = '', lastName = '' } = rowData;
          const searchTerm = term.toLowerCase();

          firstName = firstName.toLowerCase();
          lastName = lastName.toLowerCase();
          const fullName = `${firstName} ${lastName}`;

          return [firstName, lastName, fullName].some((field) => field.includes(searchTerm));
        },
      },
      { title: 'firstName', field: 'firstName', hidden: true },
      { title: 'lastName', field: 'lastName', hidden: true },
      { title: 'Email', field: 'email' },
      { title: 'Phone', field: 'phone' },
      { title: 'Customer ID', field: 'customer_id' },
      {
        title: 'Billing Address One',
        field: 'billingAddressOne',
        hidden: true,
        render: (rowData) => {
          if (rowData?.billingAddress?.addressOne) {
            return rowData?.billingAddress?.addressOne;
          }
          return '';
        },
      },
      {
        title: 'Billing Address Two',
        field: 'billingAddressTwo',
        hidden: true,
        render: (rowData) => {
          if (rowData?.billingAddress?.addressTwo) {
            return rowData?.billingAddress?.addressTwo;
          }
          return '';
        },
      },
      {
        title: 'Billing Address City',
        field: 'billingCity',
        hidden: true,
        render: (rowData) => {
          if (rowData?.billingAddress?.city) {
            return rowData?.billingAddress?.city;
          }
          return '';
        },
      },
      {
        title: 'Billing Address State',
        field: 'billingState',
        hidden: true,
        render: (rowData) => {
          if (rowData?.billingAddress?.state) {
            return rowData?.billingAddress?.state;
          }
          return '';
        },
      },
      {
        title: 'Billing Address Zip',
        field: 'billingZip',
        hidden: true,
        render: (rowData) => {
          if (rowData?.billingAddress?.zip) {
            return rowData?.billingAddress?.zip;
          }
          return '';
        },
      },
      // {
      //   title: 'Billing Address',
      //   field: 'billingAddress',
      //   hidden: true,
      //   render: (rowData) => {
      //     if (rowData?.billingAddress) {
      //       const fullAddress = [
      //         rowData?.billingAddress?.addressOne,
      //         rowData?.billingAddress?.addressTwo,
      //         rowData?.billingAddress?.city,
      //         rowData?.billingAddress?.state,
      //         rowData?.billingAddress?.zip,
      //       ];
      //       return fullAddress;
      //     }
      //     return '';
      //   },
      // },
      {
        title: 'Shipping Address One',
        field: 'shippingAddressOne',
        hidden: true,
        render: (rowData) => {
          if (rowData?.shippingAddress?.addressOne) {
            return rowData?.shippingAddress?.addressOne;
          }
          return '';
        },
      },
      {
        title: 'Shipping Address Two',
        field: 'shippingAddressTwo',
        hidden: true,
        render: (rowData) => {
          if (rowData?.shippingAddress?.addressTwo) {
            return rowData?.shippingAddress?.addressTwo;
          }
          return '';
        },
      },
      {
        title: 'Shipping Address City',
        field: 'shippingCity',
        hidden: true,
        render: (rowData) => {
          if (rowData?.shippingAddress?.city) {
            return rowData?.shippingAddress?.city;
          }
          return '';
        },
      },
      {
        title: 'Shipping Address State',
        field: 'shippingState',
        hidden: true,
        render: (rowData) => {
          if (rowData?.shippingAddress?.state) {
            return rowData?.shippingAddress?.state;
          }
          return '';
        },
      },
      {
        title: 'Shipping Address Zip',
        field: 'shippingZip',
        hidden: true,
        render: (rowData) => {
          if (rowData?.shippingAddress?.zip) {
            return rowData?.shippingAddress?.zip;
          }
          return '';
        },
      },
      // {
      //   title: 'Shipping Address',
      //   field: 'shippingAddress',
      //   hidden: true,
      //   render: (rowData) => {
      //     if (rowData?.shippingAddress) {
      //       const fullAddress = [
      //         rowData?.shippingAddress?.addressOne,
      //         rowData?.shippingAddress?.addressTwo,
      //         rowData?.shippingAddress?.city,
      //         rowData?.shippingAddress?.state,
      //         rowData?.shippingAddress?.zip,
      //       ];
      //       return fullAddress;
      //     }
      //     return '';
      //   },
      // },
      { title: 'Masked Card Number', field: 'masked_card_number' },
      { title: 'Date', field: 'createdDate', render: (rowData) => new Date(rowData.createdDate).toLocaleString() },
    ];

    if (locationDetails?.customTowingBuild) {
      const customColumns = [
        {
          title: customInvoicePageFields.length > 0 && customInvoicePageFields[0]?.value,
          hidden:
            locationDetails?.customTowingBuild && customInvoicePageFields.length > 0
              ? !customInvoicePageFields[0]?.enabled
              : true,
          field:
            customInvoicePageFields.length > 0 && customInvoicePageFields[0]?.value.replace(/\s/g, '').toLowerCase(),
          render: (rowData) => (rowData?.firstName ? rowData?.firstName : ''),
        },
        {
          title: customInvoicePageFields[1]?.value,
          hidden:
            locationDetails?.customTowingBuild && customInvoicePageFields.length > 0
              ? !customInvoicePageFields[1]?.enabled
              : true,
          field:
            customInvoicePageFields.length > 0 && customInvoicePageFields[1]?.value.replace(/\s/g, '').toLowerCase(),
          render: (rowData) => (rowData?.lastName ? rowData?.lastName : ''),
        },
      ];
      columns.splice(0, 0, ...customColumns);
    }
    const checkLocation = () => {
      /**check location is selected or not */
      let location = JSON.parse(localStorage.getItem('selected'));
      if (location === 0 && this.state.userData.permissions.allLocaton) {
        this.setState({ misMatchError: ['Please select a location'] });
        return false;
      } else {
        this.props.history.push('/AddCustomers');
      }
    };

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          {this.state.jobCreationResponseReceived && (
            <Alert severity={this.state.jobCreationSuccess ? 'success' : 'error'} onClose={() => console.log('close')}>
              {this.state.jobCreationMessage}
            </Alert>
          )}
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            {this.state.customerPortal ? (
              <div>
                {this.state.showCustomerSelfSignUpLink ? (
                  <button
                    className="btn batchBtn"
                    onClick={this.generateCustomerSelfSignUpLink}
                    style={{ display: 'block', marginLeft: '140px' }}
                  >
                    Create Customer Link
                  </button>
                ) : (
                  <input
                    className="textField btn customPlacement"
                    type="text"
                    value={this.state.customerSelfSignUpLink}
                    readOnly
                    style={{ display: 'block', marginLeft: '150px', width: '30%' }}
                  />
                )}
              </div>
            ) : (
              ''
            )}
            <Table
              tableReload={(e) => this.tableReload(e)}
              options={{
                exportButton: true,
              }}
              columns={columns}
              data={this.state.customerData}
              getExportData={this.getTableData}
              companyActiveAction={(e, data) => this.companyStatusToggle(e, data)}
              title={'Customers'}
              search={true}
              checkLocation={checkLocation}
              permissions={this.props.info.permissions}
              exports={this.props.info.permissions.exportRecord}
              paging={true}
              // toDateFilter={(e) => this.toDateFilter(e)}
              // fromDateFilter={(e) => this.fromDateFilter(e)}
              // datefilter={this.state.user.role == config.ADMIN_ROLE ? false :true}
              tablesName="customers"
              paymentGateway={locationDetails?.gateway}
              handleSyncCustomers={this.handleSyncCustomers.bind(this)}
            />
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

Customers.propTypes = {
  customerStatus: PropTypes.func,
  info: PropTypes.any,
  history: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    jobInfo: state.JobReducer,
  };
}

export default connect(mapStateToProps, { customerStatus, getCsrfToken, createCustomerSyncJob })(
  withTranslation()(Customers)
);
