import React from 'react';
import PropTypes from 'prop-types';
export const CustomTransactionColumn = (props) => {
  const changeCheck = (e) => {
    props.markTransaction(props.row, e.target.checked);
  };
  return (
    <React.Fragment>
      <input type="checkbox" checked={props.row.Posted} onChange={(e) => changeCheck(e)} />
    </React.Fragment>
  );
};

CustomTransactionColumn.propTypes = {
  row: PropTypes.any,
  markTransaction: PropTypes.any,
};
