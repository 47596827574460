export function handleCompanyCheckboxChange(event, state) {
  let textFlexPermissionFields = ['textFlex', 'customId', 'customerName', 'phoneNumber', 'amount'];
  let companyStates = {};

  if (textFlexPermissionFields.includes(event.target.id)) {
    companyStates = {
      ...state,
      textFlexPermissions: { ...state.textFlexPermissions, [event.target.id]: event.target.checked },
    };
  } else {
    companyStates = { ...state, [event.target.id]: event.target.checked };
  }
  if (event.target.id === 'customId') {
    companyStates.textFlexPermissions.customerName = !event.target.checked;
  }
  if (event.target.id === 'customerName') {
    if (companyStates?.customFields[0]?.enabled && !companyStates?.customFields[0]?.autoGenerate) {
      companyStates.textFlexPermissions.customId = !event.target.checked;
    }
  }

  return companyStates;
}
