const getStartPage = (permissions, isCustomer = false) => {
  if (permissions) {
    if (permissions.displayDashboard) {
      return '/Dashboard';
    } else if (permissions.virtualTerminal) {
      return '/Terminal';
    } else {
      return isCustomer ? '/Customer/Invoices' : '/Invoices';
    }
  } else {
    return '/Dashboard';
  }
};

export default getStartPage;
