export function getInvoiceState(state) {
  const invoiceData = {
    image: state?.userData?.company?.image,
    dbaName: state?.userData?.company?.dbaName,
    state: state?.userData?.company?.state,
    city: state?.userData?.company?.city,
    zip: state?.userData?.company?.zip,
    address1: state?.userData?.company?.address1,
    address2: state?.userData?.company?.address2,
    products: state?.Products,
    invoice: state?.invoice,
    invoiceDate: state?.invoiceDate,
    total: state?.total,
    tax: state?.tax,
    amount: state?.amount,
    termNotes: state?.termNotes,
    publicNotes: state?.publicNotes,
    privateNotes: state?.privateNotes,
    customFile: state?.invoiceFile,
    linkSendDate: state?.linkSendDate,
    svcFee: state?.svcFee,
  };
  return invoiceData;
}
