/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  root: { flexGrow: 1 },
  input: { display: 'flex', paddingBottom: 8 },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    zIndex: 9999,
  },
  chip: { margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px` },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: { padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px` },
  singleValue: { fontSize: 16, marginTop: -0.5 + 'rem' },
  placeholder: { position: 'absolute', left: 4, fontSize: 16 },
  paper: { position: 'absolute', zIndex: 1, marginTop: theme.spacing.unit, left: 0, right: 0 },
  divider: { height: theme.spacing.unit * 2 },

  '@media (max-width: 1024px)': {
    placeholder: {
      fontSize: '10px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    },
    singleValue: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
      fontSize: '10px',
    },
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        fontSize: '12px',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={() => props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class TypableSelect extends React.Component {
  state = { single: null, multi: null };

  handleChange = (name) => (value) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      isDisabled,
      classes,
      theme,
      suggestions,
      placeholder,
      value,
      onChange,
      isSelected,
      MultiValue,
      isClearable,
      clearValue,
      onReset,
      onInputChange,
    } = this.props;
    const selectStyles = {
      input: (base) => ({ ...base, color: theme.palette.text.primary, '& input': { font: 'inherit' } }),
      dropdownIndicator: (baseObj) => ({ ...baseObj, display: isDisabled ? 'none' : 'block' }),
    };

    return (
      <div className={classes.root}>
        {isSelected ? (
          <NoSsr>
            {isSelected ? (
              <Select
                isDisabled={isDisabled}
                defaultValue={{ label: `${isSelected ? isSelected : ''}`, value: `${isSelected ? isSelected : ''}` }}
                classes={classes}
                styles={selectStyles}
                options={suggestions}
                components={components}
                MultiValue={MultiValue}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                isClearable={isClearable}
                clearValue={clearValue}
                onReset={onReset}
                onInputChange={onInputChange}
              />
            ) : (
              'Loading your content...'
            )}
          </NoSsr>
        ) : (
          ''
        )}

        {!isSelected ? (
          <NoSsr>
            <Select
              isDisabled={isDisabled}
              classes={classes}
              styles={selectStyles}
              options={suggestions}
              components={components}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              isClearable={isClearable}
              clearValue={clearValue}
              onReset={onReset}
              onInputChange={onInputChange}
            />
          </NoSsr>
        ) : (
          ''
        )}
      </div>
    );
  }
}

TypableSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  suggestions: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  isSelected: PropTypes.any,
  MultiValue: PropTypes.any,
  isClearable: PropTypes.any,
  clearValue: PropTypes.any,
  onReset: PropTypes.any,
  onInputChange: PropTypes.any,
  selectProps: PropTypes.any,
  children: PropTypes.any,
  removeProps: PropTypes.any,
  innerProps: PropTypes.any,
};

export default withStyles(styles, { withTheme: true })(TypableSelect);
