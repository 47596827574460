import { formatCurrency, getRoundedExacts } from './commonFunction';

const SVCHiddenOrVisible = (isCustomFeeEnabled, isDualFeeEnabled) => {
  return isCustomFeeEnabled && (isDualFeeEnabled || !isDualFeeEnabled);
};

const InvoicePrint = (data) => {
  const date = new Date(data.DATETIME).toISOString();
  const formattedDate = new Date(date).toLocaleString();
  const invoiceNumber = data?.invoiceNBR;
  const paymentType = data?.paymentType;
  const cardType = data?.CARDTYPE;
  const cardNumber = data?.MASKCARDNUMBER;
  const isCustomFeeEnabled = data?.settings?.virtualTerminalSettings?.InvoiceDiscount;
  const isDualFeeEnabled = data?.DualFeeEnabled;
  const svcFee = data?.SVC_FEE && data?.SVC_FEE > 0 ? data?.SVC_FEE : 0;
  const invoiceLabel = data?.invoiceLabel || 'Invoice #';

  let my_window = window.open('mywindow', 'status=1,width=650,height=450');
  my_window.document.write(
    `<html>
      <head>
        <title>
        Print Invoice
        </title>
      </head>`
  );
  my_window.document.write(`<body  
    style=" -webkit-print-color-adjust: exact !important;   
    color-adjust: exact !important;                
    print-color-adjust: exact !important;"  
    onafterprint="self.close()">`);
  my_window.document.write(
    `<p style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 18px;">Hi <strong style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 18px;">${
      data.CUSTOMERDATA.firstName.charAt(0).toUpperCase() +
      data.CUSTOMERDATA.firstName.slice(1) +
      ' ' +
      data.CUSTOMERDATA.lastName.charAt(0).toUpperCase() +
      data.CUSTOMERDATA.lastName.slice(1)
    }</strong>,</p>`
  );
  my_window.document.write(
    `<h3 style="padding: 0; box-sizing: border-box; font-family: sans-serif; margin: 25px 0; text-align: center; font-size: 25px; font-weight: 700; color: rgba(53, 94, 170, 1);">INVOICE</h3>`
  );
  my_window.document
    .write(`<table style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; width: 100%; border-collapse: collapse;" width="100%">
       
${
  invoiceNumber
    ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${invoiceLabel}</strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${invoiceNumber}</td>
         </tr>`
    : ``
}
${
  data?.TXNID
    ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Transaction Number</strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data?.TXNID}</td>
         </tr>`
    : ''
}
    ${
      data.CUSTOMERDATA && data.CUSTOMERDATA.firstName
        ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
             <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Customer Name </strong></td>
             <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${
               data.CUSTOMERDATA.firstName.charAt(0).toUpperCase() +
               data.CUSTOMERDATA.firstName.slice(1) +
               ' ' +
               data.CUSTOMERDATA.lastName.charAt(0).toUpperCase() +
               data.CUSTOMERDATA.lastName.slice(1)
             }</td>
          </td>
         </tr>`
        : ``
    }
${
  cardType
    ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Card Type</strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${cardType}</td>
         </tr>`
    : ``
}

${
  cardNumber
    ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Card Number</strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${cardNumber}</td>
         </tr>`
    : ``
}
${
  paymentType
    ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Payment Type</strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${paymentType}</td>
         </tr>`
    : ``
}
    ${
      formattedDate
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Invoice Date</strong></td>
          <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formattedDate}</td>
         </tr>`
        : ``
    }

    <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Subtotal</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
        parseFloat(data.AMOUNT) -
          (parseFloat(data.SVC_FEE) + parseFloat(data.TAX) + getRoundedExacts(parseFloat(data.surcharge)))
      )}</td>
    </tr>

    ${
      SVCHiddenOrVisible(isCustomFeeEnabled, isDualFeeEnabled)
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">SVC Fee Amount</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
        svcFee
      )}</td>
    </tr>`
        : ``
    }

    ${
      data.surcharge
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
              <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Surcharge</strong></td>
              <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
                data.surcharge
              )}</td>
          </tr>`
        : ``
    }
    
    ${
      data.TAX
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Sales Tax Amount</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
          data.TAX
        )}</td>
    </tr>`
        : ``
    }

    ${
      data.isLateFeeApplicable
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
            <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Late Fee</strong></td>
            <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
              data.lateFee
            )}</td>
        </tr>`
        : ``
    }
</table>  

<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
  <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Total amount</td>
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${formatCurrency(
        data.AMOUNT
      )}</td>
    </td>
  </tr> 
</table>
<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
  <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Payment Status</td>
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${
        data.FINAL_STATUS
      }</td>
  </tr>
  </table> <p style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 16px; font-weight: 600; margin-bottom: 10px; text-align: center;">Thank you for your business. </p>
      ${
        data?.digitalSignatureBase64
          ? `<div style="display: flex; flex-direction: row; align-items: flex-start;">
            <p style="
                padding: 0;
                margin: 0;
                box-sizing: border-box;
                font-family: sans-serif;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;
                text-align: left;"
            >
            Signature</p>
            <div 
            style=" 
            min-height: 150px;
            max-height: 150px;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;height:300px; 
            background-Image: url('${data?.digitalSignatureBase64}');"></div>
          </div>`
          : ''
      }
      
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; font-weight: 600; margin-bottom: 5px; margin-top: 30px;">Regards,</p>
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; font-weight: 600;">
  ${data.locationDetails?.locationName}
  </p>  
  `);

  my_window.document.write(`</body></html>`);
  //  my_window.document.write.close();
  //  my_window.focus();
  my_window.print();
  //  my_window.close();
};

export default InvoicePrint;
