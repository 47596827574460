import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});
export const initialState = {
  sideShow: true,
  isLoading: false,
  isOpen: false,
  name: '',
  createdBy: '',
  companyId: '',
  amount: '',
  notes: '',
  description: '',
  customFields: [],
  isSubmit: false,
  isClose: false,
  misMatchError: [],
  successMessages: false,
  error: {
    nameError: false,
    notesError: false,
    descriptionError: false,
    amountError: false,
  },
};
