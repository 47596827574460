export function getUserData() {
  const {
    match: { params },
  } = this.props;
  this.setState({ isLoading: true });
  this.props.UserDetailById(params).then((res) => {
    this.setState({ ...res.payload.userData.data, isLoading: false });
  });
}

export function getUserLocations() {
  const locations = JSON.parse(localStorage.getItem('locationArrIds'));
  if (Array.isArray(locations)) {
    return locations;
  } else {
    return [locations];
  }
}
