import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';

/**
 * Customer info Begin Action
 */
export const customerStart = () => ({
  type: ActionType.CUSTOMER_START,
});

/**
 * Customer info Success Action
 */
export const customerSuccess = (customerData) => {
  return {
    type: ActionType.CUSTOMER_SUCCESS,
    payload: { customerData },
  };
};

/**
 * Customer info Failure Action
 */
export const customerError = (error) => ({
  type: ActionType.CUSTOMER_ERROR,
  payload: { error },
});

/**
 * customer driver info
 */
export const addCustomer = (data, csrf = null, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(customerStart());
    return DataApi(data, config.BASEURL + 'addCustomer', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(customerSuccess(response));
        } else {
          return dispatch(customerError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const editCustomer = (data, csrf = null, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(customerStart());
    return DataApi(data, config.BASEURL + 'editCustomer', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(customerSuccess(response));
        } else {
          return dispatch(customerError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const removeCustomerCard = (data, csrf = null, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(customerStart());
    return DataApi(data, config.BASEURL + 'customer/card/remove', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(customerSuccess(response));
        } else {
          return dispatch(customerError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const customerList = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(customerStart());
    return DataGetApi(config.BASEURL + 'customerList', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(customerSuccess(response));
        } else {
          return dispatch(customerError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const customerDetailsByCompany = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(customerStart());
    return DataGetApi(config.BASEURL + 'customerDetailsByCompany', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(customerSuccess(response));
        } else {
          return dispatch(customerError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const customerDetailsByCompanyCustom = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(customerStart());
    return DataGetApi(config.BASEURL + 'customerDetailsByCompanyCustom', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(customerSuccess(response));
        } else {
          return dispatch(customerError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const CustomerDetailsId = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(customerStart());
    return DataGetApi(config.BASEURL + 'CustomerDetailsId', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(customerSuccess(response));
        } else {
          return dispatch(customerError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const customerStatus = (data) => {
  return (dispatch) => {
    dispatch(customerStart());
    return DataApi(data, config.BASEURL + 'customerStatus')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(customerSuccess(response));
        } else {
          return dispatch(customerError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const profile = (data, csrf = null, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(customerStart());
    return DataApi(data, config.BASEURL + 'customer/update', token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(customerSuccess(response));
        } else {
          return dispatch(customerError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
