export default {
  LOGIN_START: 'LOGIN_START',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_FETCH_DATA: 'LOGIN_FETCH_DATA',
  SIGNIN_START: 'SIGNIN_START',
  SIGNIN_ERROR: 'SIGNIN_ERROR',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  SET_INPUT_STATE: 'SET_INPUT_STATE',
  COMPANY_START: 'COMPANY_START',
  COMPANY_ERROR: 'COMPANY_ERROR',
  COMPANY_SUCCESS: 'COMPANY_SUCCESS',
  LOCATION_START: 'LOCATION_START',
  LOCATION_ERROR: 'LOCATION_ERROR',
  SET_LOCATION: 'SET_LOCATION',
  SET_TSEP_AUTH: 'SET_TSEP_AUTH',
  SET_All_LOCATION: 'SET_All_LOCATION',
  LOCATION_SUCCESS: 'LOCATION_SUCCESS',
  USER_START: 'USER_START',
  USER_ERROR: 'USER_ERROR',
  USER_SUCCESS: 'USER_SUCCESS',
  INVOICE_START: 'INVOICE_START',
  INVOICE_ERROR: 'INVOICE_ERROR',
  INVOICE_SUCCESS: 'INVOICE_SUCCESS',
  PAYMENT_START: 'PAYMENT_START',
  RECURRANCE_START: 'RECURRANCE_START',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  RECURRANCE_ERROR: 'RECURRANCE_ERROR',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  RECURRANCE_SUCCESS: 'RECURRANCE_SUCCESS',
  USER_EDIT_DATA: 'USER_EDIT_DATA',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  CUSTOMER_START: 'CUSTOMER_START',
  CUSTOMER_ERROR: 'CUSTOMER_ERROR',
  CUSTOMER_SUCCESS: 'CUSTOMER_SUCCESS',
  PRODUCT_START: 'PRODUCT_START',
  PRODUCT_ERROR: 'PRODUCT_ERROR',
  PRODUCT_SUCCESS: 'PRODUCT_SUCCESS',
  TICKET_START: 'TICKET_START',
  TICKET_ERROR: 'TICKET_ERROR',
  TICKET_SUCCESS: 'TICKET_SUCCESS',
  CONTENT_START: 'CONTENT_START',
  CONTENT_ERROR: 'CONTENT_ERROR',
  CONTENT_SUCCESS: 'CONTENT_SUCCESS',

  BATCH_START: 'BATCH_START',
  BATCH_SUCCESS: 'BATCH_SUCCESS',
  BATCH_ERROR: 'BATCH_ERROR',
  BATCH_FETCH_DATA: 'BATCH_FETCH_DATA',
  BATCH_LAST_UPDATED_DATE: 'BATCH_LAST_UPDATED_DATE',
  PAYLINK_START: 'PAYLINK_START',
  SELF_PAY_BY_LINK_START: 'SELF_PAY_BY_LINK_START',
  PAYLINK_SUCCESS: 'PAYLINK_SUCCESS',
  PAYLINK_ERROR: 'PAYLINK_ERROR',
  ENV_VAR_START: 'ENV_VAR_START',
  ENV_VAR_SUCCESS: 'ENV_VAR_SUCCESS',
  ENV_VAR_ERROR: 'ENV_VAR_ERROR',
  TERMSCOND_SUCCESS: 'TERMSCOND_SUCCESS',
  SAVE_PREFERENCES_SUCCESS: 'SAVE_PREFERENCES_SUCCESS',
  FETCH_USER_PREFERENCES_SUCCESS: 'FETCH_USER_PREFERENCES_SUCCESS',
  TRANSACTIOIN_MARK_SUCCESS: 'TRANSACTIOIN_MARK_SUCCESS',
  CUSTOMER_SYNC_JOB_CREATION_SUCCESS: 'CUSTOMER_SYNC_JOB_CREATION_SUCCESS',
  CUSTOMR_SYNC_JOB_CREATION_ERROR: 'CUSTOMR_SYNC_JOB_CREATION_ERROR',
  CUSTOMER_SYNC_JOB_CREATION_START: 'CUSTOMER_SYNC_JOB_CREATION_START',
};
