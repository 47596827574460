import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function LinkTabs(props) {
  const { id } = props;
  const route = [`/CustomerDetails/${id}`, `/CustomerHistory/${id}`];
  const location = useLocation();
  return (
    <Tabs value={location.pathname}>
      <Tab label="Recurring" value={route[0]} component={Link} to={route[0]} />
      <Tab label="Transactions" value={route[1]} component={Link} to={route[1]} />
    </Tabs>
  );
}

LinkTabs.propTypes = {
  id: PropTypes.any,
};

export default LinkTabs;
