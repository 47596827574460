import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  getTransactionList,
  getTransactionOverview,
  getTransactionDailyHours,
  getTransactionMonthlyOverview,
} from '../../actions/PaymentAction';
import { Topheader } from './../../components/Topheader';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import classes from './Dashboard.module.scss';
import { Table } from '../../components/Table';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LoadingOverlay from 'react-loading-overlay';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../../constants/config';
import { DataGetApi } from '../../helpers/PostDataApi';
import { formatCurrency, getBaseAmount } from '../../helpers/commonFunction';
import TableByDate from '../../components/Table/TableByDate';
import DatePickerInput from '../../components/DatePicker/DatePicker';
import './Dashboard.scss';
const options = {
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Amount($)',
        },
        ticks: {
          callback: function (value) {
            return formatCurrency(value);
          },
          autoSkipPadding: 30,
        },
        padding: 10,
      },
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Month',
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
    mode: 'single',
    callbacks: {
      label: function (tooltipItems, _data) {
        return formatCurrency(tooltipItems.yLabel);
      },
    },
  },
};

class Dashboard extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      complete: false,
      tableDataByDate: [],
      selectedDate: new Date(),
      sideShow: true,
      reload: false,
      isLoading: true,
      transactionOverviewDate: new Date(),
      monthlyRevenueDate: new Date(),
      TransactionData: [],
      TransactionHourData: [],
      TransactionMonthlyData: [],
      TransactionOverViewData: [],
      dateWiseData: [],
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
    };

    this.handleChange = this.handleChange.bind(this);
    this.getPreviousDate = this.getPreviousDate.bind(this);
    this.getNextDate = this.getNextDate.bind(this);
    this.setTransactionOverviewYear = this.setTransactionOverviewYear.bind(this);
    this.setMonthlyRevenueDate = this.setMonthlyRevenueDate.bind(this);
  }

  componentDidMount() {
    this.getTransactionOverViewData();
    this.getTransactionOverViewMonthlyData();
    this.getTransactionDailyHoursData();
    this.setDate();
    this.onCheckCall(this.state.selectedDate);
  }

  setDate = (newDate) => {
    let date = newDate ? new Date(newDate.selectedDate) : new Date();
    this.setState({
      selectedDate: date,
    });
    this.onCheckCall(date);
  };
  getPreviousDate = () => {
    const selectedDate = this.state.selectedDate;
    const currentDayInMilli = new Date(selectedDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const previousDayInMilli = currentDayInMilli - oneDay;
    const previousDate = new Date(previousDayInMilli);
    this.setDate({ selectedDate: previousDate });
    this.onCheckCall(this.state.selectedDate);
  };

  getNextDate = () => {
    const selectedDate = this.state.selectedDate;
    const currentDayInMilli = new Date(selectedDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const nextDayInMilli = currentDayInMilli + oneDay;
    const nextDate = new Date(nextDayInMilli);
    // this.setDate({selectedDate:nextDate},this.onCheckCall({page:1}))
    //load data from database
    const d1 = new Date().getDate();
    const d2 = new Date(selectedDate).getDate();
    if (d1 == d2) {
      alert("You can't see the future transaction");
    } else {
      // this.setDate({selectedDate:nextDate},this.onCheckCall({page:1}))
      this.setDate({ selectedDate: nextDate });
      this.onCheckCall(this.state.selectedDate);
    }
  };

  handleChange = (_e) => {
    this.onCheckCall(this.state.selectedDate);
    this.setState({
      complete: !this.state.complete,
    });
  };

  getTransactionDailyHoursData() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    let data = {
      date: new Date(),
      companyId: userData.company ? userData.company._id : '',
      role: userData.role,
      userId: userData._id,
      permissions: userData.permissions,
    };
    this.props.getTransactionDailyHours(data).then((res) => {
      if (res) {
        this.setState({ TransactionHourData: res.payload.paymentData.data.response, isLoading: false });
      }
    });
  }
  getTransactionOverViewMonthlyData(date = null) {
    this.setState({ isLoading: true });
    const filterDate = date ? date : new Date();
    let userData = JSON.parse(localStorage.getItem('user'));
    const locationData =
      userData.role !== 1 && userData.role !== 0 ? JSON.parse(localStorage.getItem('locationPaydetails')) : null;
    let data = {
      locationId: locationData?.id,
      companyId: userData.company ? userData.company._id : '',
      role: userData.role,
      userId: userData._id,
      filterDate,
    };
    this.props.getTransactionMonthlyOverview(data).then((res) => {
      if (res) {
        this.setState({ TransactionMonthlyData: res.payload.paymentData.data.response, isLoading: false });
      }
    });
  }

  getTransactionOverViewData(date = null) {
    const filterDate = date ? date : new Date();
    this.setState({ isLoading: true });
    const userData = JSON.parse(localStorage.getItem('user'));
    const locationData =
      userData.role !== 1 && userData.role !== 0 ? JSON.parse(localStorage.getItem('locationPaydetails')) : null;
    let data = {
      locationId: locationData?.id,
      companyId: userData.company ? userData.company._id : '',
      role: userData.role,
      userId: userData._id,
      filterDate,
    };
    this.props.getTransactionOverview(data).then((res) => {
      if (res.type === 'PAYMENT_SUCCESS') {
        this.setState({
          TransactionOverViewData: res.payload.paymentData.data ? res.payload.paymentData.data.response : [],
          isLoading: false,
        });
      }
    });
  }
  getLinearData() {
    let hourArr = [];
    let amountArr = [];
    if (this.state.TransactionHourData.length > 0) {
      this.state.TransactionHourData.map((item) => {
        if (item.hour) {
          hourArr.push(new Date(item.date).getHours());
        }
        if (item.amount) {
          amountArr.push(item.amount);
        }
        return item;
      });
    }
    return {
      labels: hourArr,
      datasets: [
        {
          label: 'Daily Transaction Activity',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: amountArr,
        },
      ],
    };
  }

  getBlockData() {
    let monthArr = [];
    let amountArr = [];
    const monthNames = ['Jan', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (this.state.TransactionMonthlyData.length > 0) {
      this.state.TransactionMonthlyData.map((item) => {
        if (item._id) {
          monthArr.push(monthNames[item._id]);
        }
        if (item.total || item.total === 0) {
          amountArr.push(item.total);
        }
        return item;
      });
    }
    return {
      labels: monthArr,

      datasets: [
        {
          label: 'Monthly Revenue',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: amountArr,
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  return (
                    data.labels[tooltipItem.index] +
                    ': ' +
                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +
                    'jjhj€'
                  );
                },
              },
            },
          },
        },
      ],
    };
  }

  handleChangeId(id) {
    this.props.history.push('/Viewtransaction/' + id);
  }
  onCheckCall(query) {
    if (this.state.reload) {
      query.search = '';
      this.setState({ reload: false });
    }

    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        let selectedDate = this.state.selectedDate;
        let user = JSON.parse(localStorage.getItem('user'));
        let locationId = localStorage.getItem('locationArrIds');
        let token = user.token;
        let url = config.BASEURL;
        let param = {
          locationIds: locationId,
          role: user.role,
          userId: user._id,
          companyId: user.role > 1 ? user.company._id : '',
          selectedDate: selectedDate,
          permissions: user.permissions,
        };
        DataGetApi(url + 'getAllTransactionsOnDate', param, token).then((result) => {
          if (result?.data?.response) {
            const arrayData = result.data.response
              ? result.data.response.filter((item) => item.FINAL_STATUS === 'Paid').map((e) => parseFloat(e.AMOUNT))
              : [];
            const reducer = (previousValue, currentValue) => {
              let value = previousValue ? previousValue : 0;
              if (typeof currentValue === 'number') {
                value += +currentValue;
              }
              return value;
            };
            this.setState({
              tableDataByDate: this.state.complete
                ? result.data.response
                : result.data.response !== undefined
                ? result.data.response.filter((e) => e.FINAL_STATUS == 'Paid')
                : [],
              dateWiseData: arrayData.length > 0 ? Number(arrayData.reduce(reducer)).toFixed(2) : [],
            });
          }
        });
      }, 1000);
    });
  }
  getTableData(query) {
    if (this.state.reload) {
      query.search = '';
      this.setState({ reload: false });
    }

    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        let locationId = localStorage.getItem('locationArrIds');
        let token = user.token;
        let url = config.BASEURL;
        let param = {
          locationIds: locationId,
          role: user.role,
          userId: user._id,
          companyId: user.role > 1 ? user.company._id : '',
          per_page: query.pageSize,
          page: +(query.page + 1),
          searchAll: typeof query.search === 'undefined' || query.search === '' ? false : true,
          toDate: this.state.toDate,
          fromDate: this.state.fromDate,
          permissions: user.permissions,
        };
        DataGetApi(url + 'getTransactionList', param, token).then((result) => {
          resolve({
            data:
              typeof query.search === 'undefined' || query.search === ''
                ? result.data.response
                : result.data.response.filter((row) => {
                    return (
                      String(new Date(row.createdAt).toLocaleString()).indexOf(String(query.search)) !== -1 ||
                      String(row.ORDERID).indexOf(query.search) !== -1 ||
                      String(row.TXNID).indexOf(query.search) !== -1
                    );
                  }),
            page: result?.data?.pagination?.page - 1,
            totalCount: result?.data?.pagination?.totalCount,
          });
        });
      }, 3000);
    });
  }
  tableReload(status) {
    this.setState({ reload: status });
  }

  setTransactionOverviewYear(date) {
    this.setState({ transactionOverviewDate: new Date(date) });
    this.getTransactionOverViewData(new Date(date));
  }

  setMonthlyRevenueDate(date) {
    this.setState({ monthlyRevenueDate: new Date(date) });
    this.getTransactionOverViewMonthlyData(new Date(date));
  }

  getPaymentType(data) {
    if (data.CARDTYPE === 'ACH' || data.responseCode === '120') {
      return 'ACH';
    } else if (data.CASH) {
      return 'Cash';
    } else if (data.creditOrDebit) {
      return data.creditOrDebit;
    } else {
      return 'Credit';
    }
  }

  render() {
    const { TransactionOverViewData } = this.state;
    const tnxOverViewPaid = TransactionOverViewData.filter((item) => item._id === 'Paid');
    const tnxOverViewReund = TransactionOverViewData.filter((item) => item._id === 'Refunded');
    const tnxOverVoid = TransactionOverViewData.filter((item) => item._id === 'Voided');
    let userData = JSON.parse(localStorage.getItem('user'));

    const columns = [
      {
        title: 'Payment Status',
        field: 'FINAL_STATUS',
        render: (rowData) =>
          rowData.FINAL_STATUS === 'Paid' ? (
            <button type="button" className="btn paidBtn">
              Paid
            </button>
          ) : rowData.FINAL_STATUS === 'Refunded' ? (
            <button type="button" className="btn RefundedBtn">
              Refunded
            </button>
          ) : rowData.FINAL_STATUS === 'Voided' ? (
            <button type="button" className="btn PenddingBtn">
              Voided
            </button>
          ) : rowData.FINAL_STATUS === 'Declined' ? (
            <button type="button" className="btn DeclinedBtn">
              Declined
            </button>
          ) : rowData.FINAL_STATUS === 'Submitted' ? (
            <button type="button" className="btn SubmittedBtn">
              Submitted
            </button>
          ) : (
            ''
          ),
      },
      {
        title: 'Payment Type',
        field: 'RESPONSECODE',
        render: (rowData) => this.getPaymentType(rowData),
      },
      {
        title: 'Transaction ID',
        field: 'TXNID',
        render: (rowData) =>
          userData.role === config.SUPER_ADMIN_ROLE ? (
            <span>{rowData.TXNID}</span>
          ) : (
            <Tooltip title="View Details">
              <span className={classes.Underline} onClick={(_e) => this.handleChangeId(rowData.TXNID)}>
                {rowData.TXNID}
              </span>
            </Tooltip>
          ),
      },
      { title: 'Base Amount', field: 'invoice', render: (rowData) => getBaseAmount(rowData) },
      {
        title: 'Amount',
        field: 'amount',
        render: (rowData) =>
          rowData.AMOUNT ? <span className={classes.rightAlign}> {formatCurrency(rowData.AMOUNT)} </span> : '',
      },
      // { title: 'Customer Name', field: 'CustomerName' },
    ];
    return (
      <div className="rightPanel">
        <Topheader />
        <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
          <div style={{ padding: 20 }}>
            <Grid container spacing={3} className="dashboard-cards-container">
              <Grid container className={classes.dashboard_panel1} item xs={12} sm={12} md={6}>
                <Card className={classes.customCard}>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h4">
                      Transaction Overview
                    </Typography>
                    <Grid item sm={12} xs={12} style={{ marginTop: '10px', marginLeft: '15px' }}>
                      <DatePickerInput
                        label="Year"
                        value={this.state.transactionOverviewDate}
                        views={['year']}
                        format="yyyy"
                        handler={this.setTransactionOverviewYear}
                      />
                    </Grid>
                    <div className={classes.cardContentBox}>
                      <Typography variant="body2" color="textSecondary" component="p" className={classes.totalGreen}>
                        <span className={classes.total_span}>Total Revenue</span>
                        <span className={classes.rightAlign}>
                          {' '}
                          {tnxOverViewPaid[0] ? formatCurrency(tnxOverViewPaid[0].total) : '$0.00'}
                        </span>
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p" className={classes.totalYellow}>
                        <span style={{ marginRight: 40 }} className={classes.total_span}>
                          Refunded
                        </span>
                        <span className={classes.rightAlign}>
                          {' '}
                          {tnxOverViewReund[0] ? formatCurrency(tnxOverViewReund[0].total) : '$0.00'}{' '}
                        </span>
                      </Typography>

                      <Typography variant="body2" color="textSecondary" component="p" className={classes.totalGray}>
                        <span style={{ marginRight: 70 }} className={classes.total_span}>
                          Voided
                        </span>
                        <span className={classes.rightAlign}>
                          {' '}
                          {tnxOverVoid[0] ? formatCurrency(tnxOverVoid[0].total) : '$0.00'}{' '}
                        </span>
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Card className={classes.customCard}>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h4">
                      Monthly Revenue
                    </Typography>
                    <Grid item sm={12} xs={12} style={{ marginTop: '10px', marginLeft: '15px' }}>
                      <DatePickerInput
                        label="Year"
                        value={this.state.monthlyRevenueDate}
                        views={['year']}
                        format="yyyy"
                        handler={this.setMonthlyRevenueDate}
                      />
                    </Grid>
                    <div className={classes.cardContentBox}>
                      <Bar data={this.getBlockData()} options={options} />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                direction="row"
                justify="center"
                alignItems="flex-start"
                className="tableRightTransaction"
              >
                <Card className={classes.customCard}>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h4">
                      Transaction History
                    </Typography>
                    <div className="Dashboard_cardContentBoxTnx">
                      <Table
                        tableReload={(e) => this.tableReload(e)}
                        title={'Transactions'}
                        createNew={false}
                        data={(query) => this.getTableData(query)}
                        disabledAction={true}
                        paging={false}
                        columns={columns}
                        search={false}
                        exports={false}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                direction="row"
                justify="center"
                alignItems="flex-start"
                className="tableRightTransaction"
              >
                <Card className={classes.customCard}>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h4">
                      Daily Transaction Report
                    </Typography>
                    <div className="Dashboard_cardContentBoxTnx">
                      <TableByDate
                        data={this.state.tableDataByDate ? this.state.tableDataByDate : []}
                        selectedDate={this.state.selectedDate}
                        complete={this.state.complete}
                        getPreviousDate={this.getPreviousDate}
                        getNextDate={this.getNextDate}
                        setDate={this.setDate}
                        handleChange={this.handleChange}
                      />
                      <Typography gutterBottom variant="h5" component="h4">
                        Total amount for the day:{' '}
                        {this.state.dateWiseData.length > 0 ? formatCurrency(this.state.dateWiseData) : '$ 0.00'}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.func,
  getTransactionDailyHours: PropTypes.func,
  getTransactionMonthlyOverview: PropTypes.func,
  getTransactionOverview: PropTypes.func,
  history: PropTypes.any,
};
export default connect(null, {
  getTransactionDailyHours,
  getTransactionList,
  getTransactionOverview,
  getTransactionMonthlyOverview,
})(withTranslation()(Dashboard));
