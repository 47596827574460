import { passwordStrength } from 'check-password-strength';
import { whichLocationSelected } from './getLocationFromLocalStorage';
import config from '../constants/config';

const passwordOptions = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 3,
    minLength: 8,
  },
  {
    id: 3,
    value: 'Strong',
    minDiversity: 4,
    minLength: 8,
  },
];

export const doesFileExist = (urlToFile) => {
  let xhr = new XMLHttpRequest();
  xhr.open('HEAD', urlToFile, false);
  // xhr.setRequestHeader('Content-Type', 'application/xml','Access-Control-Allow-Origin','http://localhost:3006');
  // xhr.send();

  if (xhr.status == '404') {
    return false;
  } else {
    return true;
  }
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

/*  export const formatCurrency = (cents)=> {
    return cents?"$"+Number(cents).toFixed(2):'$0.00';
  } */

export const formatCurrency = (cents) => {
  return cents ? formatter.format(parseFloat(cents)) : '$0.00';
  // return cents?"$"+Number(cents).toFixed(2):'$0.00';
};
export const formatAmount = (cents) => {
  return cents ? Number(cents).toFixed(2) : '0.00';
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  let month = date.getMonth() + 1;
  let day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours || 12; // Handle midnight (0 hours)

  month = ('0' + month).slice(-2);
  day = ('0' + day).slice(-2);
  hours = ('0' + hours).slice(-2);
  minutes = ('0' + minutes).slice(-2);
  seconds = ('0' + seconds).slice(-2);

  return month + '/' + day + '/' + year + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
};

export const userRole = (role) => {
  let userRole = new Map();
  userRole.set(1, 'Super Admin');
  userRole.set(2, 'Admin');
  userRole.set(3, 'Manager');
  userRole.set(4, 'User');
  return userRole.get(role);
};

/**
 * Get base amount from transaction collection
 * @param {Object} item
 * @returns
 */
//  export const getBaseAmount = (item) => {
//   if (typeof item.ORDERID !== "undefined" && item.ORDERID == "") {
//     let svc_fee = item.SVC_FEE ? typeof item.SVC_FEE ==='number'? item.SVC_FEE : item.SVC_FEE.toString().replace(/\$/g, "") : 0;
//     let tax = item.TAX ? item.TAX : 0;
//     return formatCurrency(item.AMOUNT - tax - svc_fee);
//   } else {
//     return item.invoices !== null && item.invoices.amount
//       ? formatCurrency(item.invoices.amount)
//       : "$0.00";
//   }
// };

export const getBaseAmount = (item) => {
  let svc_fee = item.SVC_FEE;
  let surcharge_fee =
    item.surcharge && !item.CASH
      ? item.surcharge
      : 0
      ? typeof item.SVC_FEE === 'number'
        ? item.SVC_FEE
        : item?.SVC_FEE?.toString().replace(/\$/g, '')
      : 0;

  let late_fee = 0;
  if (item.lateFee && item.isLateFeeApplicable) {
    if (typeof item.lateFee === 'number') {
      late_fee = item.lateFee;
    } else {
      item.lateFee.toString().replace(/\$/g, '');
    }
  }

  let tax = item.TAX ? item.TAX : 0;
  const roundSurcharge =
    Math.abs((surcharge_fee * 1000) % 10) >= 5
      ? (Math.ceil(surcharge_fee * 100) / 100).toFixed(2)
      : (Math.round(parseFloat(surcharge_fee) * 100) / 100).toFixed(2);
  const roundTotal =
    Math.abs((item.AMOUNT * 1000) % 10) >= 5
      ? (Math.ceil(item.AMOUNT * 100) / 100).toFixed(2)
      : (Math.round(parseFloat(item.AMOUNT) * 100) / 100).toFixed(2);

  if (typeof item.ORDERID !== 'undefined' && item.ORDERID == '') {
    // let svc_fee = item.SVC_FEE !== "" ? item.SVC_FEE.toString().replace(/\$/g, "") : 0;
    // let tax = item.TAX ? item.TAX : 0;
    if (item.baseAmount) {
      return formatCurrency(parseFloat(item.baseAmount));
    }
    if (item.AMOUNT) {
      return formatCurrency(parseFloat(roundTotal - (tax + svc_fee + late_fee + parseFloat(roundSurcharge))));
    }
  } else {
    if (item.baseAmount) {
      return formatCurrency(parseFloat(item.baseAmount));
    }
    if (item.AMOUNT) {
      return formatCurrency(parseFloat(roundTotal - (tax + svc_fee + late_fee + parseFloat(roundSurcharge))));
    } else if (item.invoices !== null && item.invoices.amount) {
      return formatCurrency(parseFloat(item.invoices.amount));
    } else {
      return '$0.00';
    }
  }
};

// Check HTML special symbols to prevent XSS( Cross site scripting attacks )

export const validateCustomerName = (str) => {
  const blackListedMarkup = /[+%,^*=[\]{};':"|<>!@$?\\]+/;
  return blackListedMarkup.test(str);
};

export const validateHtmlMarkUp = (str) => {
  const blackListedMarkup = /[+#%,^&*=[\]{};':"|<>]+/;
  return blackListedMarkup.test(str);
};

export const validateNotesMarkUp = (str) => {
  const blackListedMarkup = /[+#%^&*=[\]{};:|<>]+/;
  return blackListedMarkup.test(str);
};

export const validateSpecialCharacters = (str) => {
  const restrictedCharacters = /[+#%@?!,^&*=[\]{}()-;':"|<>]+/;
  return restrictedCharacters.test(str);
};

export const onlySpaces = (str) => {
  return /^\s*$/.test(str);
};

export const getCardType = (val) => {
  const number = Number(val);
  switch (number) {
    case 4:
      return 'Visa';
    case 5:
      return 'MasterCard';
    case 3:
      return 'American Express';
    case 6:
      return 'Discover Card';
    default:
      return 'CreditCard';
  }
};

export const checkPasswordStrength = (value) => {
  const checkPasswordStrength = passwordStrength(value, passwordOptions).value;
  if (checkPasswordStrength === 'Too weak' || checkPasswordStrength === 'Weak') {
    return { passwordStrength: false, strengthStatus: checkPasswordStrength, passwordStrengthSeverity: 'error' };
  } else if (checkPasswordStrength === 'Medium') {
    return { passwordStrength: true, strengthStatus: checkPasswordStrength, passwordStrengthSeverity: 'info' };
  } else if (checkPasswordStrength === 'Strong') {
    return { passwordStrength: true, strengthStatus: checkPasswordStrength, passwordStrengthSeverity: 'success' };
  }
};

export const validateCardValues = (allState, state) => {
  let cardNumberValidation = true;
  let cardExpiryValidation = true;
  let cardCscValidation = true;
  let cardNumberEmpty = false;
  const month = state.cardExpiryDate.split('/')[0];
  if (allState.card.cardNumber === '_______________') {
    cardNumberEmpty = true;
    return { cardCscValidation, cardExpiryValidation, cardNumberValidation, cardNumberEmpty };
  }
  if (state?.cardNumber?.indexOf('_') > -1) {
    // setState({ errorCard: { ...state.errorCard, cardNumber: true } });
    window.scrollTo(0, 0);
    cardNumberValidation = false;
    return { errorCard: { ...state.errorCard, cardNumber: true }, cardNumberValidation };
  } else if (state?.cardExpiryDate?.indexOf('_') > -1) {
    // setState({ errorCard: { ...state.errorCard, cardExpiryDate: true } });
    window.scrollTo(0, 0);
    cardExpiryValidation = false;
    return { errorCard: { ...state.errorCard, cardExpiryDate: true }, cardExpiryValidation };
  } else if (month && month.length == 2 && (parseInt(month) <= 0 || parseInt(month) > 12)) {
    window.scrollTo(0, 0);
    cardExpiryValidation = false;
    return { errorCard: { ...state.errorCard, cardExpiryDate: true }, cardExpiryValidation };
  } else if (state?.cardCsc?.indexOf('_') > -1) {
    // setState({ errorCard: { ...state.errorCard, cardCsc: true } });
    window.scrollTo(0, 0);
    cardCscValidation = false;
    return { errorCard: { ...state.errorCard, cardCsc: true }, cardCscValidation };
  } else {
    return { cardCscValidation, cardExpiryValidation, cardNumberValidation };
  }
};

export const convertDateToTimeZoneFormat = (dateString, localTimeZone) => {
  if (dateString && localTimeZone) {
    const date = new Date(dateString);
    if (!date || date == 'Invalid Date') {
      return '';
    }
    const convertedDate = date.toLocaleString('en-US', { timeZone: localTimeZone });
    if (!convertedDate || convertedDate === 'Invalid Date') {
      return '';
    }
    return convertedDate;
  }
  return dateString;
};

export const getLocationPaymentGateway = () => {
  let locationPayDetails = whichLocationSelected();
  if (
    locationPayDetails.gateway &&
    locationPayDetails.gateway === 'multipass' &&
    locationPayDetails.multipassCredentials &&
    Object.entries(locationPayDetails.multipassCredentials).length > 0
  ) {
    return 'multipass';
  } else if (locationPayDetails?.paytraceKey && locationPayDetails?.paytraceUserName) {
    return 'paytrace';
  } else {
    return 'paytrace';
  }
};
export const isUUID = (str) => {
  const uuidRegex = /^[0-9a-fA-F]{32}$/;
  return uuidRegex.test(str);
};

export const isNumber = (value) => {
  if (!value) {
    return false;
  }
  const numberRegex = /^[0-9\b]+$/;
  return numberRegex.test(value);
};

export const getPaytraceCreds = () => {
  let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
  return {
    locationMid: locationPaydetails ? locationPaydetails.mId : '',
    paytraceKey: locationPaydetails ? locationPaydetails.paytraceKey : '',
    userName: locationPaydetails ? locationPaydetails.paytraceUserName : '',
    password: locationPaydetails ? locationPaydetails.paytracePassword : '',
  };
};

export const getMultipassData = () => {
  let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
  return {
    locationMid: locationPaydetails ? locationPaydetails.mId : '',
    multipassdeviceid: locationPaydetails ? locationPaydetails.multipassCredentials?.multipassDeviceId : '',
    transactionKey: locationPaydetails ? locationPaydetails.multipassCredentials?.transactionKey : '',
    gateway: locationPaydetails ? locationPaydetails.multipassCredentials?.gateway : '',
  };
};

export const maskCardNumber = (cardNumber) => {
  const lastFourDigits = cardNumber.slice(-4);
  const maskedDigits = 'x'.repeat(cardNumber.length - 4);
  return maskedDigits + lastFourDigits;
};

export const getRoundedExacts = (amount) => {
  if (typeof amount === 'number') {
    amount = amount.toString();
  }
  const values = amount.split('.');
  let decimals = values[1];
  if (values.length < 2) {
    return parseFloat(amount);
  } else {
    if (decimals.length > 3) {
      decimals = decimals.slice(0, 3);
    }
    if (decimals.length === 3) {
      let lastInt = parseInt(decimals[2]);
      let otherValues = parseInt(decimals.slice(0, 2));
      if (lastInt >= 5) {
        if (decimals[0] === '0') {
          let otherValue = parseInt(decimals[1]);
          otherValue = otherValue + 1;
          if (otherValue < 10) {
            otherValues = '0' + otherValue;
          } else {
            otherValues = otherValue;
          }
        } else {
          otherValues = otherValues + 1;
        }
      } else {
        if (decimals[0] === '0') {
          otherValues = decimals.slice(0, 2);
        }
      }
      return parseFloat(values[0] + '.' + otherValues);
    }
    return parseFloat(amount);
  }
};

export const getCustomerNamePlaceholderValue = (locationId) => {
  if (config.CUSTOMER_PLACE_HOLDER_LOCATIONS_IDS?.includes(locationId)) {
    return 'CustomerNamePlaceholder';
  }
  return 'CustomerName';
};
export const addJobToPollingList = (jobId) => {
  let createdJobs = localStorage.getItem('createdJobs') && JSON.parse(localStorage.getItem('createdJobs'));
  if (createdJobs && createdJobs.length > 0) {
    createdJobs = createdJobs.concat([jobId]);
    localStorage.setItem('createdJobs', JSON.stringify(createdJobs));
  } else {
    createdJobs = JSON.stringify([jobId]);
    localStorage.setItem('createdJobs', createdJobs);
  }
};

export const removeCompletedJobsFromPollingList = (completedJobs) => {
  let createdJobs = localStorage.getItem('createdJobs') && JSON.parse(localStorage.getItem('createdJobs'));
  if (createdJobs && createdJobs.length > 0) {
    // remove entries from createdjobs that are completed.
    createdJobs = createdJobs.filter((job) => !completedJobs.includes(job));
    localStorage.setItem('createdJobs', JSON.stringify(createdJobs));
  }
};
