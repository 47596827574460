import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import PersonAddDisabledOutlinedIcon from '@material-ui/icons/PersonAddDisabledOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Title = ({ text, variant }) => (
  <Typography
    variant={variant}
    style={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
    }}
  >
    {text}
  </Typography>
);

Title.propTypes = {
  text: PropTypes.any,
  variant: PropTypes.any,
};

class Table extends React.Component {
  constructor(props) {
    let date = new Date();
    super(props);
    this.state = {
      searchText: '',
      reload: false,
      isTaxData: true,
      toDate: new Date(),
      date: new Date(),
      refreshedCount: 0,
      fromDate:
        this.props.location.pathname === '/AdminPortal'
          ? date.setDate(date.getDate() - 3)
          : this.props.location.pathname === '/Invoices'
          ? date.setDate(date.getDate() - 45)
          : date.setDate(date.getDate() - 30),
      data: [],
      user: JSON.parse(localStorage.getItem('user')),
    };
    this.tableRef = React.createRef();
  }
  componentDidMount() {
    window.addEventListener('reloadData', this.refreshData.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('reloadData', this.refreshData);
  }
  refreshData() {
    if (this.tableRef.current && this.state.refreshedCount < 1) {
      this.setState({ refreshedCount: this.state.refreshedCount + 1 });
      this.tableRef.current.onQueryChange();
    }
  }
  checkAddPermission = (title, permissions) => {
    switch (title) {
      case 'Company':
        if (permissions.addCompany) {
          return true;
        } else {
          return false;
        }
      case 'User':
        if (permissions.creatNewUser) {
          return true;
        } else {
          return false;
        }
      case 'Locations':
        if (permissions.addLocation) {
          return true;
        } else {
          return false;
        }
      case 'Invoices':
        if (permissions.createInvoice) {
          return true;
        } else {
          return false;
        }
      case 'SubAdmin':
        if (permissions.addSubAdmin) {
          return true;
        } else {
          return false;
        }
      case 'Admin':
        if (permissions.addAdmin || permissions.addUser) {
          return true;
        } else {
          return false;
        }
      case 'Tickets':
        return this.state.user.role > 1 ? true : false;
      case 'Customers':
      case 'Products':
      case 'Pages':
        return true;

      default:
        return false;
    }
  };
  checkPermission = (title, permissions) => {
    switch (title) {
      case 'Company':
        if (permissions.isActiveCompany && permissions.editCompany) {
          return this.returnEditAndActiveAction();
        } else if (permissions.isActiveCompany) {
          return this.returnActiveAction();
        } else if (permissions.editCompany) {
          return this.returnEditAction();
        } else {
          return '';
        }
      case 'Locations':
        if (permissions.isActiveLocation && permissions.editLocation) {
          return this.returnEditAndActiveAction();
        } else if (permissions.isActiveLocation) {
          return this.returnActiveAction();
        } else if (permissions.editLocation) {
          return this.returnEditAction();
        } else {
          return '';
        }
      case 'Users':
        if (permissions.isActiveUser && permissions.editUser) {
          return this.returnEditAndActiveAction();
        } else if (permissions.isActiveUser) {
          return this.returnActiveAction();
        } else if (permissions.editUser) {
          return this.returnEditAction();
        } else {
          return '';
        }
      case 'Customers':
        if (permissions.editCustomer) {
          return this.returnEditAndActiveAction();
        } else {
          return '';
        }
      case 'Invoices':
        if (permissions.deleteInvoice) {
          return this.returnDeleteAction();
        }
        break;
      default:
        return this.returnReloadAction();
    }
  };

  deleteHandler(event, data) {
    if (window.confirm('Are you sure want to delete the item ?')) {
      this.props.deleteHandler(event, data);
      if (this.props.title && this.props.title !== 'Invoices') {
        // since we're now updating the status of deleted item right with in the state therefor no need to reload the whole table
        this.tableReload();
      }
    }
  }

  editBtnRedirect(e, data) {
    if (this.props.title === 'Company') {
      this.props.history.push(this.props.title + 'Details/' + data._id);
    } else if (this.props.title === 'Recurring Billing') {
      this.props.history.push(`/RecurrenceDetails/${data.RECURRID}`);
    } else {
      this.props.history.push('/Edit' + this.props.title + '/' + data._id);
    }
  }
  onClickEditDetails(event, row) {
    this.props.companyDetailsAction(event, this.props.EditTab, row._id);
  }
  onClickAddNew(event, _tab) {
    this.props.companyDetailsAction(event, this.props.AddTab);
  }
  DeleteBtn(event, data) {
    this.props.companyDeleteAction(event, data);
  }
  ActiveBtn(event, data) {
    this.props.companyActiveAction(event, data);
  }

  tableReload(_event, _data) {
    // this.props.tableReload(true);
    setTimeout(() => {
      this.tableRef.current.onQueryChange(null, () => {
        if (this.tableRef.current.state.searchText !== '') {
          const element = document.querySelector('[aria-label="Clear Search"]');
          element.click();
        }
      });
    }, 1000);
  }

  returnDeleteAction = (_data) => {
    return [
      {
        icon: 'delete',
        tooltip: `Delete ${this.props.title ? this.props.title : ''}`,
        onClick: (event, rowData) => {
          this.deleteHandler(event, rowData);
        },
      },
    ];
  };

  returnEditAndActiveAction = () => {
    return [
      {
        icon: 'edit',
        tooltip: `Edit ${this.props.title ? this.props.title : ''}`,
        onClick: (event, rowData) => {
          this.editBtnRedirect(event, rowData);
        },
      },
      {
        icon: 'active',
        tooltip: `Active ${this.props.title ? this.props.title : ''}`,
        onClick: (event, rowData) => {
          if (window.confirm('Are you sure want to change the item status?')) {
            this.ActiveBtn(event, rowData);
          }
        },
      },
    ];
  };
  returnActiveAction() {
    return [
      {
        icon: 'active',
        tooltip: `Active ${this.props.title ? this.props.title : ''}`,
        onClick: (event, rowData) => {
          if (window.confirm('Are you sure want to change the item status?')) {
            this.ActiveBtn(event, rowData);
          }
        },
      },
    ];
  }
  returnEditAction() {
    return [
      {
        icon: 'edit',
        tooltip: `Edit ${this.props.title ? this.props.title : ''}`,
        onClick: (event, rowData) => {
          this.editBtnRedirect(event, rowData);
        },
      },
    ];
  }
  returnReloadAction() {
    return [
      {
        icon: 'refresh',
        tooltip: 'Refresh Clients',
        isFreeAction: true,
        onClick: () => this.tableReload(),
      },
    ];
  }
  handleDateChange = (date, key) => {
    if (date == 'Invalid Date' || date == null) {
      return false;
    } else {
      this.setState({ [key]: date }, () => {
        key === 'toDate' ? this.props.toDateFilter(this.state.toDate) : this.props.fromDateFilter(this.state.fromDate);
      });
    }
  };
  mapBillingAddress = (billingAddress) => {
    return {
      billingAddressOne: billingAddress?.addressOne ? billingAddress.addressOne : '',
      billingAddressTwo: billingAddress?.addressTwo ? billingAddress.addressTwo : '',
      billingCity: billingAddress?.city ? billingAddress.city : '',
      billingState: billingAddress?.state ? billingAddress.state : '',
      billingZip: billingAddress.zip ? billingAddress.zip : '',
    };
  };

  mapShippingAddress = (shippingAddress) => {
    return {
      shippingAddressOne: shippingAddress?.addressOne ? shippingAddress.addressOne : '',
      shippingAddressTwo: shippingAddress?.addressTwo ? shippingAddress.addressTwo : '',
      shippingCity: shippingAddress?.city ? shippingAddress.city : '',
      shippingState: shippingAddress?.state ? shippingAddress.state : '',
      shippingZip: shippingAddress?.zip ? shippingAddress.zip : '',
    };
  };
  // Function to update the object with the address data
  updateAddress = (obj, column, address) => {
    Object.keys(address).forEach((key) => {
      if (column.field === key) {
        obj[column.field] = address[key];
      }
    });
  };
  processAddress = (p, columns, obj) => {
    columns.forEach((column) => {
      if (column.title.startsWith('Billing Address')) {
        this.updateAddress(obj, column, this.mapBillingAddress(p.billingAddress));
      } else if (column.title.startsWith('Shipping Address')) {
        this.updateAddress(obj, column, this.mapShippingAddress(p.shippingAddress));
      } else {
        obj[column.field] = p[column.field];
      }
    });
  };

  valuesReducer = (d, i) => {
    d += Object.values(i)
      .map((v) => v && v.toString().replace(/\r/g, '').replace(/,/g, ''))
      .join(',');
    d += '\n';
    return d;
  };

  render() {
    const pageSize = this.props.pageSize ? { pageSize: this.props.pageSize } : {};
    const companyDetailsAction = this.props.companyEditDetails;

    return (
      <MaterialTable
        tableRef={this.tableRef}
        components={
          companyDetailsAction
            ? {
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                    <div style={{ padding: '0px 10px' }}>
                      {this.checkAddPermission(this.props.title, this.props.permissions) ? (
                        <button
                          label="Users"
                          role="tab"
                          className="btn addBtn"
                          {...companyDetailsAction}
                          onClick={(e) => {
                            this.onClickAddNew(e, this.props.UserAddTab);
                          }}
                        >
                          {this.props.title && this.props.search ? 'Add ' + this.props.title : ''}
                        </button>
                      ) : (
                        <Button
                          label="Users"
                          role="tab"
                          {...companyDetailsAction}
                          color={'primary'}
                          onClick={(e) => {
                            this.onClickAddNew(e, this.props.UserAddTab);
                          }}
                        >
                          {this.checkAddPermission(this.props.title, this.props.permissions)
                            ? 'Add ' + this.props.title
                            : ''}
                        </Button>
                      )}
                    </div>
                  </div>
                ),
                Action: (props) => {
                  return (
                    <>
                      {' '}
                      &nbsp;
                      <Tooltip
                        title={props.data.status && props.action.icon === 'active' ? 'in-active' : props.action.icon}
                      >
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color={
                            props.action.icon === 'active' || props.action.icon === 'refresh'
                              ? 'default'
                              : props.action.icon === 'edit'
                              ? 'primary'
                              : 'secondary'
                          }
                          variant="contained"
                          style={{ textTransform: 'none' }}
                          size="small"
                        >
                          {props.action.icon === 'edit' ? (
                            <EditIcon />
                          ) : props.action.icon === 'refresh' ? (
                            <RefreshIcon />
                          ) : props.action.icon === 'delete' ? (
                            <DeleteOutlineOutlinedIcon />
                          ) : props.data.status ? (
                            <PermIdentityOutlinedIcon />
                          ) : (
                            <PersonAddDisabledOutlinedIcon />
                          )}
                        </Button>
                      </Tooltip>
                    </>
                  );
                },
              }
            : {
                Toolbar: (props) => (
                  <div>
                    <div className="left-control top-seciton">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MTableToolbar {...props} />
                        {/* TODO: we will enable this once https://bitbucket.org/titaniumpayments/flex-prod/pull-requests/2180 is merged */}
                        {/*  {this.props.title === 'Customers' && this.props.paymentGateway !== 'multipass' && (*/}
                        {/*    <button*/}
                        {/*      className="btn addBtn"*/}
                        {/*      style={{ marginTop: '15px', height: '35px' }}*/}
                        {/*      onClick={() => this.props.handleSyncCustomers()}*/}
                        {/*    >*/}
                        {/*      Sync*/}
                        {/*    </button>*/}
                        {/*  )}*/}
                      </div>
                      {this.props.datefilter ? (
                        <div className={'dateFilter-table'}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              variant="inline"
                              autoOk
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="date-picker-inline1"
                              maxDateMessage={'From date should not be greater than To date'}
                              maxDate={this.state.toDate}
                              // maxDate={this.state.toDate.setDate(this.state.toDate.getDate() + 30)}
                              label={'From Date'}
                              value={this.state.fromDate}
                              onChange={(date) => {
                                this.handleDateChange(date, 'fromDate');
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              variant="inline"
                              autoOk
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="date-picker-inline1"
                              // minDate={new Date()}
                              label={'To Date'}
                              value={this.state.toDate}
                              onChange={(date) => {
                                this.handleDateChange(date, 'toDate');
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div style={{ padding: '0px 10px', marginBottom: '5px' }}>
                      {this.props.location.pathname !== '/Users' &&
                      this.state.user.role !== 3 &&
                      this.checkAddPermission(this.props.title, this.props.permissions) ? (
                        <button
                          className="btn addBtn"
                          onClick={() => {
                            this.props.title === 'Invoices' || this.props.title === 'Customers'
                              ? this.props.checkLocation()
                              : this.props.history.push('/Add' + this.props.title);
                          }}
                        >
                          {this.props.title === 'Invoices' ? 'Add Invoice' : 'Add ' + this.props.title}
                        </button>
                      ) : (
                        ''
                      )}

                      {this.state.user.role === 3 &&
                      this.checkAddPermission(this.props.title, this.props.permissions) ? (
                        <button
                          className="btn addBtn"
                          onClick={() => {
                            this.props.history.push('/Add' + this.props.title);
                          }}
                        >
                          {this.props.title === 'User' && !this.props.createNew ? '' : 'Add ' + this.props.title}
                        </button>
                      ) : (
                        ''
                      )}

                      {this.props.location.pathname === '/Users' &&
                      this.state.user.role === 2 &&
                      this.checkAddPermission(this.props.title, this.props.permissions) ? (
                        <>
                          <button
                            className="btn addBtn"
                            onClick={() => {
                              this.props.history.push('/Add' + this.props.title);
                            }}
                          >
                            {this.props.title === 'User' && !this.props.createNew ? '' : 'Add ' + this.props.title}
                          </button>

                          <button
                            className="btn addBtnml2"
                            onClick={() => {
                              this.props.history.push('/AddManager');
                            }}
                          >
                            {this.props.title && this.props.search ? 'Add Manager' : ''}
                          </button>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ),
                Action: (props) => {
                  return (
                    <>
                      &nbsp;
                      <Tooltip
                        title={
                          (props.data.status || props.data.STATUS) && props.action.icon === 'active'
                            ? 'in-active'
                            : props.action.icon
                        }
                      >
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          color={
                            props.action.icon === 'active' || props.action.icon === 'refresh'
                              ? 'default'
                              : props.action.icon === 'edit'
                              ? 'primary'
                              : 'secondary'
                          }
                          variant="contained"
                          style={{ textTransform: 'none' }}
                          size="small"
                        >
                          {props.action.icon === 'edit' ? (
                            <EditIcon />
                          ) : props.action.icon === 'refresh' ? (
                            <RefreshIcon />
                          ) : props.action.icon === 'delete' ? (
                            <DeleteOutlineOutlinedIcon />
                          ) : props.data.status || props.data.STATUS ? (
                            <PermIdentityOutlinedIcon />
                          ) : (
                            <PersonAddDisabledOutlinedIcon />
                          )}
                        </Button>
                      </Tooltip>
                    </>
                  );
                },
              }
        }
        onRowClick={this.props.onRowClick}
        options={{
          ...pageSize,
          ...this.props.options,
          search: this.props.search,
          // exportButton: this.props.exports,
          debounceInterval: 1000,
          exportButton: this.props.exports ? { csv: this.props.exports, pdf: false } : false,
          exportCsv: (columns, _data) => {
            const date = new Date();
            let query = {
              search: '',
              orderBy: '',
              orderDirection: '',
              export: true,
              page: 1,
              searchAll: 'true',
              toDate: new Date(),
              fromDate: new Date(date.setDate(date.getDate() - 45)),
              props: this.props,
            };
            this.props.getExportData(query).then((res) => {
              const filteredColumns = columns.filter(
                (d) => !(this.props.tablesName === 'customers' && d.title === 'Name')
              );
              const label = filteredColumns.map((d, _i) => {
                return d.title;
              });
              const body = res.data
                .map((p) => {
                  const obj = {};
                  for (let x = 0; x < label.length; x++) {
                    if (this.props.tablesName === 'tickets') {
                      obj[columns[x].field] =
                        columns[x].title === 'TicketStatus'
                          ? p[columns[x].field]
                            ? 'Closed'
                            : 'Open'
                          : p[columns[x].field];
                    } else if (this.props.tablesName == 'customers') {
                      if (
                        filteredColumns[x].title.startsWith('Billing Address') ||
                        filteredColumns[x].title.startsWith('Shipping Address')
                      ) {
                        this.processAddress(p, filteredColumns, obj);
                      } else {
                        obj[filteredColumns[x].field] = p[filteredColumns[x].field];
                      }
                    } else if (this.props.tablesName === 'users') {
                      if (columns[x].title === 'Role') {
                        obj[columns[x].field] = p.role === 4 ? 'Regular User' : p.role === 3 ? 'Manager' : 'Admin';
                      } else if (columns[x].title === 'Active Status') {
                        obj[columns[x].field] = p.status ? 'Active' : 'In Active';
                      } else if (columns[x].title === 'Company Name') {
                        obj[columns[x].field] = p.company.dbaName;
                      } else if (columns[x].title === 'Locations') {
                        let locationName = p.location.map((i) => i.locationName).join(' | ');
                        obj[columns[x].field] = locationName;
                      } else {
                        obj[columns[x].field] = p[columns[x].field];
                      }
                    } else if (this.props.tablesName === 'transactions') {
                      obj[columns[x].field] =
                        columns[x].title === 'Payment Type'
                          ? p[columns[x].field] === 'ACH' || p[columns[x].field] === '120'
                            ? 'ACH'
                            : 'Credit'
                          : ['Amount charged', 'Amount'].indexOf(columns[x].title.trim()) !== -1
                          ? p[columns[x].field].replace(/,/g, '')
                          : columns[x].title === 'Base Amount'
                          ? p.invoices
                            ? p.invoices.amount
                            : p[columns[x].field]
                          : p[columns[x].field];
                    } else {
                      obj[columns[x].field] = p[columns[x].field];
                    }
                  }
                  return obj;
                })
                .reduce(this.valuesReducer, '');

              let hiddenElement = document.createElement('a');
              hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(label + '\n' + body);
              hiddenElement.target = '_blank';
              hiddenElement.download = this.props.title + '.csv';
              hiddenElement.click();
            });
          },
          paging: this.props.paging ? this.props.paging : false,
          actionsColumnIndex: -1,
        }}
        pageSize={10}
        columns={this.props.columns}
        data={this.props.data ? this.props.data : []}
        title={<Title text={this.props.title ? this.props.title : ''} variant="p" />}
        actions={
          this.props.noAction === true
            ? ''
            : (this.state.user.role === 1 || this.state.user.role === 0) &&
              this.props.title !== 'Pages' &&
              this.props.title !== 'Transactions' &&
              this.props.title !== 'Tickets'
            ? [
                {
                  icon: 'edit',
                  tooltip: `Edit ${this.props.title ? this.props.title : ''}`,
                  onClick: (event, rowData) => {
                    companyDetailsAction
                      ? this.onClickEditDetails(event, rowData)
                      : this.editBtnRedirect(event, rowData);
                  },
                },
                {
                  icon: 'active',
                  tooltip: `Active ${this.props.title ? this.props.title : ''}`,
                  onClick: (event, rowData) => {
                    if (window.confirm('Are you sure want to change the item status?')) {
                      this.ActiveBtn(event, rowData);
                    }
                  },
                },
                {
                  icon: 'delete',
                  tooltip: `Delete ${this.props.title ? this.props.title : ''}`,
                  onClick: (event, rowData) => {
                    if (window.confirm('Are you sure want to delete the item?')) {
                      this.DeleteBtn(event, rowData);
                    }
                  },
                },
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Clients',
                  isFreeAction: true,
                  onClick: () => this.tableReload(),
                },
              ]
            : (this.state.user.role === 2 || this.state.user.role === 3) && this.props.title === 'Recurring Billing'
            ? [
                {
                  icon: 'edit',
                  tooltip: `Edit ${this.props.title ? this.props.title : ''}`,
                  onClick: (event, rowData) => {
                    this.editBtnRedirect(event, rowData);
                  },
                },
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Clients',
                  isFreeAction: true,
                  onClick: () => this.tableReload(),
                },
              ]
            : (this.state.user.role === 2 || this.state.user.role === 3) &&
              this.props.title !== 'Transactions' &&
              this.props.title !== 'Invoices' &&
              this.props.title !== 'Tickets' &&
              this.props.title !== 'Recurrence'
            ? [
                {
                  icon: 'edit',
                  tooltip: `Edit ${this.props.title ? this.props.title : ''}`,
                  onClick: (event, rowData) => {
                    this.editBtnRedirect(event, rowData);
                  },
                },
                {
                  icon: 'active',
                  tooltip: `Active ${this.props.title ? this.props.title : ''}`,
                  onClick: (event, rowData) => {
                    if (window.confirm('Are you sure want to change the item status?')) {
                      this.ActiveBtn(event, rowData);
                    }
                  },
                },
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Clients',
                  isFreeAction: true,
                  onClick: () => this.tableReload(),
                },
              ]
            : this.checkPermission(this.props.title, this.props.permissions)
            ? this.checkPermission(this.props.title, this.props.permissions)
            : []
        }
      />
    );
  }
}

Table.propTypes = {
  location: PropTypes.any,
  checkLocation: PropTypes.func,
  deleteHandler: PropTypes.func,
  history: PropTypes.any,
  title: PropTypes.any,
  companyDetailsAction: PropTypes.func,
  AddTab: PropTypes.any,
  EditTab: PropTypes.any,
  UserAddTab: PropTypes.any,
  getExportData: PropTypes.func,
  permissions: PropTypes.any,
  createNew: PropTypes.any,
  onRowClick: PropTypes.any,
  exports: PropTypes.any,
  tablesName: PropTypes.any,
  paging: PropTypes.any,
  columns: PropTypes.any,
  data: PropTypes.any,
  companyDeleteAction: PropTypes.func,
  companyActiveAction: PropTypes.func,
  tableReload: PropTypes.func,
  toDateFilter: PropTypes.func,
  fromDateFilter: PropTypes.func,
  companyEditDetails: PropTypes.any,
  search: PropTypes.any,
  datefilter: PropTypes.any,
  options: PropTypes.any,
  componentState: PropTypes.any,
  noAction: PropTypes.any,
  pageSize: PropTypes.any,
};

export default withRouter(Table);
