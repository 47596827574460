import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Textfield } from '../../components/Textfield';
import { RenderMenuItem } from './../../components/RenderMenuItem';

class PickupDropLocation extends React.Component {
  render() {
    const { pickUpNotes, pickUpLocation } = this.props.input;
    const PICKUP_LOCATION = [`House`, `Business`, `Highway`, `Apartment`];
    const { t, onChangeDropDown, onChangeTextHandler } = this.props;

    return (
      <div>
        <strong> Pickup-Drop Location </strong>
        <FormControl fullWidth>
          <Grid container align="center">
            <Grid item xs={1} sm={2} md={2} />

            <Grid item xs={10} sm={8} md={8}>
              <Grid container align="center">
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl style={{ width: '100%', marginTop: '1rem' }}>
                    <InputLabel htmlFor="pickUpLocation-required">{'Pickup Location'}</InputLabel>
                    <Select
                      value={pickUpLocation}
                      name="pickUpLocation"
                      inputProps={{ id: 'pickUpLocation-required' }}
                      style={{ marginRight: '1rem' }}
                      onChange={onChangeDropDown}
                    >
                      <MenuItem value="">
                        {' '}
                        <em>{''}</em>{' '}
                      </MenuItem>
                      {RenderMenuItem(PICKUP_LOCATION)}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <FormControl style={{ width: '100%' }}>
                    <Textfield
                      id="pickUpNotes"
                      type="text"
                      labels={t('PickupNotes')}
                      value={pickUpNotes}
                      onChange={onChangeTextHandler}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={2} md={2} />
          </Grid>
        </FormControl>
      </div>
    );
  }
}
PickupDropLocation.propTypes = {
  t: PropTypes.func,
  input: PropTypes.object,
  onChangeDropDown: PropTypes.func,
  onChangeTextHandler: PropTypes.func,
};
// const mapStateToProps = ({ input: { pickUpNotes, pickUpLocation, touched } }) => ({ pickUpNotes, pickUpLocation, touched });

function mapStateToProps(state) {
  return {
    input: state.InputReducer,
  };
}
export default connect(mapStateToProps)(withTranslation()(PickupDropLocation));
