const autoFillMultipassFields = () => {
  const cardHolderNameField = document.getElementById('tsep-cardHolderName');
  const zipCodeField = document.getElementById('tsep-zipCode');
  if (cardHolderNameField) {
    cardHolderNameField.value = 'John Doe';
    cardHolderNameField.style.display = 'none';
  }
  if (zipCodeField) {
    zipCodeField.value = '75500';
    zipCodeField.style.display = 'none';
  }
};

export default autoFillMultipassFields;
