import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';
import { IOSSwitch } from '../../../components/common/IOSSwitch';

Switch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  topLabel: PropTypes.bool,
};

export default function Switch({ label, checked, onChange, topLabel = false }) {
  // console.log(label, checked)
  return (
    <Grid item xs={12} label={label} className="discount-label-field">
      {topLabel ? <label>{label}</label> : <></>}
      <FormControlLabel control={<IOSSwitch checked={checked} onChange={onChange} value={checked} />} />
      {!topLabel ? <label className="mr-2">{label}</label> : <></>}
    </Grid>
  );
}
